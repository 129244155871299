import React, { Component } from 'react';
// import { secureStorage } from '../../../../locStore';
import { connect } from 'react-redux';
import {Link } from 'react-router-dom';
import * as actionTypes from '../../../../store/actions/actions';
import cloneDeep from 'lodash/cloneDeep';

class Cart extends Component {
    

    constructor(props) {
        super(props);
    }



    
    decreaseQtyOfProduct = (product) => {
        console.log(product);
        
        let quantity = parseInt(document.getElementById(`cartItem-${product.id}`).value);
        console.log(quantity);
        if(quantity !== undefined && quantity !== null) {
            if(quantity == 0) {
                quantity = 0;
            } else {
                quantity = isNaN(quantity) ? 0 : quantity - 1
            }
        } else {
            quantity = 0
        }
       
        let currentProduct = cloneDeep(product);
        currentProduct["addons"] = [];
        // let qty = this.state.qty - 1;
        currentProduct["qty"] = parseInt(quantity);
        currentProduct["calculated_price"] = parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price);
        currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
        if(quantity > 0) {
            this.props.addItemToCart(currentProduct);

        } else {
            this.props.removeItemFromCart(currentProduct);
        }

        
    }

    increaseQtyOfProduct = (product) => {
        
        
        console.log(product);
        
        let quantity = parseInt(document.getElementById(`cartItem-${product.id}`).value);
        console.log(quantity);
        if(quantity !== undefined && quantity !== null) {
            if(quantity == 0) {
                quantity = 0;
            } else {
                quantity = isNaN(quantity) ? 0 : quantity + 1
            }
        } else {
            quantity = 0
        }
       
        let currentProduct = cloneDeep(product);
        currentProduct["addons"] = [];
        // let qty = this.state.qty - 1;
        currentProduct["qty"] = parseInt(quantity);
        currentProduct["calculated_price"] = parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price);
        currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
        if(quantity > 0) {
            this.props.addItemToCart(currentProduct);

        } else {
            this.props.removeItemFromCart(currentProduct);
        }



    }

    onChangeQtyOfProduct = (qua) => {
        console.log(qua);
        
        let quantity = parseInt(qua);

       let qunty = 0;
       if(quantity !== undefined && quantity !== null) {
            if(quantity <= 0) {
                qunty = 0;

            } else {
                qunty = parseInt(quantity);
                if(isNaN(qunty)) {
                    qunty = 0;
                }
            }
       } else {
           qunty = 0;
       }
        
        this.setState(prevState => {
            return {
                ...prevState,
                qty: qunty
            }
        }, () => {

            let currentProduct = cloneDeep(this.props.product);
            
            // this.props.addItemToCart(currentProduct);
            if(this.state.qty > 0) {
                currentProduct["addons"] = [];
                // let qty = this.state.qty - 1;
                currentProduct["qty"] = parseInt(this.state.qty);
                // currentProduct["calculated_price"] = this.props.product.price > this.props.product.discount && this.props.product.discount != 0 && this.props.product.discount !== undefined && this.props.product.discount !== null ? this.props.product.discount : this.props.product.price;
                // currentProduct["calculated_total"] = currentProduct.qty * currentProduct.calculated_price;
                currentProduct["calculated_price"] = parseFloat(this.props.product.price) > parseFloat(this.props.product.discount) && parseFloat(this.props.product.discount) != 0 && parseFloat(this.props.product.discount) !== undefined && parseFloat(this.props.product.discount) !== null ? parseFloat(this.props.product.discount) : parseFloat(this.props.product.price);
                currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
                this.props.addItemToCart(currentProduct);

            } else {
                this.props.removeItemFromCart(currentProduct);
            }

        })
        
    }

    decreaseQty = (product, index) => {
        console.log(product);
        let quantity = parseInt(product.qty);
        // let quantity = parseInt(document.getElementById(`cartItem-${product.id}`).value);
        console.log(quantity);
        if(quantity !== undefined && quantity !== null) {
            if(quantity == 0) {
                quantity = 0;
            } else {
                quantity = isNaN(quantity) ? 0 : quantity - 1
            }
        } else {
            quantity = 0
        }
       
        let currentProduct = cloneDeep(product);
        // currentProduct["addons"] = [];
        // let qty = this.state.qty - 1;
        currentProduct["qty"] = quantity;
        currentProduct["calculated_price"] = product.calculated_price
        currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
        if(quantity > 0) {
            this.props.addItemToCart(currentProduct, index);

        } else {
            this.props.removeItemFromCart(currentProduct, index);
        }

    }


    increaseQty = (product, index) => {
        console.log(product);
        let quantity = parseInt(product.qty);
        // let quantity = parseInt(document.getElementById(`cartItem-${product.id}`).value);
        console.log(quantity);
        if(quantity !== undefined && quantity !== null) {
            if(quantity == 0) {
                quantity = 0;
            } else {
                quantity = isNaN(quantity) ? 0 : quantity + 1
            }
        } else {
            quantity = 0
        }
       
        let currentProduct = cloneDeep(product);
        // currentProduct["addons"] = [];
        // let qty = this.state.qty - 1;
        currentProduct["qty"] = quantity;
        currentProduct["calculated_price"] = product.calculated_price
        currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
        if(quantity > 0) {
            this.props.addItemToCart(currentProduct, index);

        } else {
            this.props.removeItemFromCart(currentProduct, index);
        }

    }

    
    render() {

        let cartItems = this.props.cart.items.map((item, index)=> {
            console.log(item);
            return (
                <div className="bannerCartrow" key={index}>
                    <div className="row m-0">
                        <div className="col-3 p-0">
                            <div className="input-group">
                                <span className="input-group-btn" onClick={() => {this.decreaseQty(item, index)}}>
                                    <button type="button" className="btn btn-light" data-type="minus" data-field="qty" data-id="105">
                                        <svg className="bi bi-dash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"/>
                                        </svg>
                                    </button>
                                </span>
                                <input id={`cartItem-${item.id}`} onChange={(e) => {this.onChangeQty(e.currentTarget.value, index)}} autocomplete="off" type="text" className="form-control input-number text-center" value={item.qty} name="qty" readonly="readonly" min="1" max="100" />
                                <span className="input-group-btn" onClick={() => {this.increaseQty(item, index)}}>
                                    <button type="button" className="btn btn-light" data-type="plus" data-field="qty" data-id="105">
                                        <svg className="bi bi-plus" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
                                            <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
                                        </svg>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div className="col-9 pl-5 pr-5">
                            <h4>{this.props.lang === "en" ? item.title : item.title_ar} <span>KD. {item.calculated_price}</span></h4>
                            <p>6" (Size good for 6 to 8 people)</p>
                        </div>
                    </div>   
                </div>
            )

        })

        return (
            <>
                <div className="bannerCart">
                    <h1>Your Cart</h1>
                    <div className="bannerCartoverflow">
                        {cartItems}
                        {/* <div className="bannerCartrow">
                            <div className="row m-0">
                                <div className="col-3 p-0">
                                    <div className="input-group">
                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn-light" data-type="minus" data-field="qty" data-id="105">
                                            <svg className="bi bi-dash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"/>
                                            </svg>
                                        </button>
                                    </span>
                                    <input autocomplete="off" type="text" id="qty" className="form-control input-number text-center" value="1" name="qty" readonly="readonly" min="1" max="100" />
                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn-light" data-type="plus" data-field="qty" data-id="105">
                                            <svg className="bi bi-plus" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
                                                <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                                </div>
                                <div className="col-9 pl-5 pr-5">
                                    <h4>Passion Fruit Cake <span>KD. 10.000</span></h4>
                                    <p>6" (Size good for 6 to 8 people)</p>
                                </div>
                                </div>   
                        </div>
                        <div className="bannerCartrow">
                            <div className="row m-0">
                                <div className="col-3 p-0">
                                    <div className="input-group">
                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn-light" data-type="minus" data-field="qty" data-id="105">
                                            <svg className="bi bi-dash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"/>
                                            </svg>
                                        </button>
                                    </span>
                                    <input autocomplete="off" type="text" id="qty" className="form-control input-number text-center" value="1" name="qty" readonly="readonly" min="1" max="100" />
                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn-light" data-type="plus" data-field="qty" data-id="105">
                                            <svg className="bi bi-plus" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
                                                <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                                </div>
                                <div className="col-9 pl-5 pr-5">
                                    <h4>Passion Fruit Cake <span>KD. 10.000</span></h4>
                                    <p>6" (Size good for 6 to 8 people)</p>
                                </div>
                                </div>   
                        </div>
                     */}
                    
                    </div> 
                    <div className="bannerCartTotals">
                        <div className="bannerCartsubtotals">
                            <table className="bannerCartTable">
                                <tbody>
                                    <tr>
                                        <th>{this.props.lang == "en" ? this.props.translations.en["Sub-Total"] : this.props.translations.ar["Sub-Total"]}</th>
                                        <td>KD. {parseFloat(this.props.cart.calculated_total).toFixed(3)}</td>
                                    </tr>
                                    <tr>
                                        <th>Service Charge</th>
                                    <td>KD. {parseInt(0).toFixed(3)}</td>
                                    </tr>
                                    <tr><th>&nbsp;</th><td>&nbsp;</td></tr>
                                    <tr>
                                        <th>{this.props.lang == "en" ? this.props.translations.en["Total Amount"] : this.props.translations.ar["Total Amount"]}</th>
                                        <td>KD. {parseFloat(this.props.cart.calculated_total).toFixed(3)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Link to={`/checkout`} href="#" className="btn bannerCartbtn">{this.props.lang == "en" ? this.props.translations.en["Proceed to Checkout"] : this.props.translations.ar["Proceed to Checkout"]}</Link>
                    </div>   
                </div>
            
            </>
        );
    }


}



const mapStateToProps = (state) => {
    return {
        translations: state.translations,
        lang: state.lang.lang,
        qtyIndicator: state.qtyIndicator,
        cart : state.cart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setQtyIndicator : (productId) => dispatch({type: actionTypes.SET_QUANTITY_INDICATOR_ID, payload: {productId: productId}}),
        addItemToCart : (product, index) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product, index: index}}),
        updateCart : (product) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product}}),
        removeItemFromCart : (product, index) => dispatch({type: actionTypes.REMOVE_CART_ITEM, payload: {item: product, index: index}})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

// export default Cart;