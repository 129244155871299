import React from "react";
import { Modal } from "react-bootstrap";
import "./PopupSkeleton.css";
const PopupSkeleton = () => {
  return (
    <Modal
      id="PopupSkeleton"
      show={true}
      onHide={false}
      backdrop="static"
      keyboard={false}
    >
      {/* <Modal.Header
        closeButton
        className="small-dialog-header skelPopupHeader animate"
      ></Modal.Header> */}

      <Modal.Body className="skelPopupContent">
        <div
          style={{
            background: "rgba(0, 0, 0, 0.2)",
            padding: "5px",
            boxSizing: "border-box",
            height: "auto",
          }}
          className="animate"
        >
          <div className="skelPopupBody animate" style={{ width: "90%" }}></div>
          <div className="skelPopupBody animate" style={{ width: "60%" }}></div>
          <div className="skelPopupBody animate" style={{ width: "50%" }}></div>
          {/* <div className="skelPopupBody animate" style={{ width: "70%" }}></div>
          <div className="skelPopupBody animate" style={{ width: "70%" }}></div> */}
        </div>

        <div className="skelPopupBody animate" style={{ width: "40%" }}></div>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
        </div>
        <div className="skelPopupBody animate" style={{ width: "40%" }}></div>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
        </div>
        <div className="skelPopupBody animate" style={{ width: "40%" }}></div>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <div
              className="skelPopupBody animate"
              style={{ width: "25px", height: "25px" }}
            ></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// const PopupSkeleton = () => {
//   return (
//     <Modal
//       id="PopupSkeleton"
//       show={true}
//       onHide={false}
//       // backdrop="static"
//       keyboard={false}
//     >
//       {/* <Modal.Header
//         closeButton
//         className="small-dialog-header skelPopupHeader animate"
//       ></Modal.Header> */}
//       <Modal.Body style={{ height: "100vh", display: "flex" }}>
//         <div
//           style={{
//             display: "flex",
//             flex: 1,
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <h5 className="shimmer">Loading</h5>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

export default PopupSkeleton;
