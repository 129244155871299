import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL, SID } from "../../config";
import { secureStorage } from "../../locStore";
import { cloneDeep } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import DateFnsUtils from "@date-io/date-fns";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

class OrderPage extends Component {
  constructor(props) {
    super(props);

    console.log(this.props);

    let c = new Date();
    console.log(
      `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}T${c.getHours()}:${c.getMinutes()}`
    );
    console.log(c.getMinutes());
    let mins = c.getMinutes();
    if (c.getMinutes() <= 9) {
      mins = "0" + c.getMinutes();
    }
    console.log(mins);
    this.state = {
      show_picker: false,
      // selectedDate: `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}T${c.getHours()}:${mins}`,
      selectedDate: null,
      available_slots: [],
      selected_slot: {
        label: null,
        value: null,
      },
      fname: null,
      lname: null,
      email: null,
      phone: null,
      mobile: null,
      country: null,
      area: {
        label: null,
        value: null,
      },
      block: null,
      street: null,
      avenue: null,
      housetype: "house",
      house_number: null,
      apartment_name: null,
      apartment_floor: null,
      apartment_flat: null,
      office_name: null,
      office_number: null,
      office_floor: null,
      additional_information: null,
      delivery_type: "deliver_now",
      deliver_schedule: null,
      deliver_date: null,
      deliver_time: null,
      delivery_charges: 0,
      coupon: "",
      coupon_err: null,
      discount: 0,
      payment_method: null,
      error_state: {
        fname: false,
        lname: false,
        phone: false,
        mobile: false,
        email: false,
        country: false,
        area: false,
        governate: false,
        block: false,
        street: false,
        avenue: false,
        housetype: false,
        payment_method: false,
        address_line_1: false,
        city: false,
        state: false,
        house_number: false,
        office_number: false,
        office_name: false,
        office_floor: false,
        apartment_name: false,
        apartment_flat: false,
        apartment_floor: false,
        zipcode: false,
      },
      // error_state: {
      //     first_name: true,
      //     last_name: true,
      //     phone: true,
      //     email: true,
      //     country: true,
      //     area: true,
      //     governate: true,
      //     block: true,
      //     street: true,
      //     avenue: true,
      //     housetype: true,
      //     payment_method: true,
      //     address_line_1: true,
      //     city: true,
      //     state: true,
      //     pincode: true,
      //     house_number: true,
      //     office_number: true,
      //     office_name: true,
      //     office_floor: true,
      //     apartment_name: true,
      //     apartment_flat: true,
      //     apartment_floor: true,
      //     zipcode: true
      // }
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    console.log(this.props);
    if (this.props.cart.items.length <= 0) {
      this.props.history.push(`/`);
    }
    // let c = new Date(this.state.selectedDate);
    // let d = `${c.getFullYear()}-${c.getMonth()}-${c.getDate()}`;
    // let area = null;

    // if(this.props.delivery_location !== null && this.props.delivery_location !== undefined) {
    //     area = this.props.delivery_location.id;
    // };

    // if(area !== null && area !== undefined) {
    //     fetch(`https://projects.yellowsoft.in/testrk/app/delivery_time/${area}/${d}`)
    //     .then(resp => {
    //         return resp.json();

    //     })
    //     .then(respJson => {
    //         console.log(respJson);
    //         this.setState(prevState => {
    //             return {
    //                 ...prevState,
    //                 available_slots: respJson
    //             }
    //         })
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     })
    // }
  }

  componentDidUpdate() {}

  updateSelectedCountry = (countryCode) => {
    let countries = this.props.countries.filter((country, index) => {
      return country.code === countryCode;
    });
    if (countries.length > 0) {
      let country = countries[0];

      let countryslot = {
        label: this.props.lang == "en" ? country.title : country.title_ar,
        value: country.code,
      };
      this.state.error_state.country = country ? false : true;

      let obj = {};
      if (countryCode == "+965" || countryCode == "965") {
        obj = {
          address_line_1: "",
          address_line_2: "",
          city: "",
          state: "",
          zipcode: "",
          avenue: "",
          block: "",
          street: "",
          area: null,
          selectedDate: null,
          selected_slot: {
            label: null,
            value: null,
          },
          additional_information: "",
          house_number: "",
          office_name: "",
          office_number: "",
          office_floor: "",
          apartment_name: "",
          apartment_flat: "",
          apartment_floor: "",
        };
      } else {
        obj = {
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: null,
          zipcode: null,
          avenue: "",
          block: "",
          street: "",
          area: null,
          deliver_date: null,
          deliver_time: null,
          deliver_schedule: null,
          selectedDate: null,
          selected_slot: {
            label: null,
            value: null,
          },
          additional_information: "",
          house_number: "",
          office_name: "",
          office_number: "",
          office_floor: "",
          apartment_name: "",
          apartment_flat: "",
          apartment_floor: "",
          area: null,
          areaId: null,
          delivery_charges: 0,
        };

        secureStorage.setItem("delivery_location", {
          governate: null,
          area: null,
        });
        this.props.set_deliveryLocation({ governate: null, area: null });
      }

      this.setState((prevProps) => {
        return {
          ...prevProps,
          country: countryslot,
          ...obj,
        };
      });
    }
  };

  handleDateChange = (event) => {
    console.log(event);
    let d = new Date(event);
    console.log(
      `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}T${d.getHours()}-${d.getMinutes()}`
    );
    // setSelectedDate(event.target.value);
    // let d = new Date(event.target.value);
    // console.log(d);
    if (
      this.props.delivery_location !== null &&
      this.props.delivery_location !== undefined
    ) {
      let area = this.props.delivery_location.id;
      let date = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;

      fetch(
        `https://projects.yellowsoft.in/testrk/app/delivery_time/${area}/${date}`
      )
        .then((resp) => {
          return resp.json();
        })
        .then((respJson) => {
          console.log(respJson, "date changed");
          this.setState((prevState) => {
            return {
              ...prevState,
              available_slots: respJson,
              selectedDate: d,
              deliver_date: `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please select delivery location first");
    }
    // this.setState((prevState) => {
    //     return {
    //         ...prevState,
    //         selectedDate: event.target.value
    //     }
    // })
  };

  showOrHidePicker = (value) => {
    // this.setDataVariable("deliver-schedule", value);

    let deliver_date = null;
    let deliver_time = null;
    if (value === "deliver-now") {
      deliver_date = new Date();
    } else {
    }
    if (
      this.props.delivery_location !== null &&
      this.props.delivery_location !== undefined
    ) {
      // let area = this.props.delivery_location.id;
      // let date =
      // fetch(`https://projects.yellowsoft.in/testrk/app/delivery_time/${area}/${date}`)
      // .then(resp => {
      //     return resp.json();

      // })
      // .then(respJson => {
      //     console.log(respJson, "date changed");
      this.setState((prevState) => {
        return {
          ...prevState,
          // available_slots: respJson,
          deliver_schedule: value,
          show_picker: !prevState.show_picker,
        };
      });
      // })
      // .catch(err => {
      //     console.log(err);
      // })

      // this.setState((prevState) => {
      //     console.log("changing picker status");
      //     return {
      //         ...prevState,

      //     }
      // })
    } else {
      alert("Please select your country and area first");
    }
  };

  setDataVariable = (name, value) => {
    this.setState((prevState) => {
      console.log("changing picker status");
      let obj = {};
      prevState["error_state"][name] = false;
      obj[name] = value;
      return {
        ...prevState,
        ...obj,
      };
    });
  };

  setHouseType = (houztype) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        housetype: houztype,
      };
    });
  };

  timeConversion(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  selectedTimeSlot = (val) => {
    console.log(val);
    // let slots = this.state.available_slots.filter((slot,index) => {
    //     return slot.value === val;
    // });

    // if(slots.length > 0) {
    //     let selected_slot = slots[0];
    let deliver_time = val.split(" ");
    this.setState((prevState) => {
      return {
        ...prevState,
        selected_slot: val,
        deliver_date: `${deliver_time[0]}`,
        deliver_time: `${deliver_time[1]} ${deliver_time[2]}`,
      };
    });
    // }
  };

  selectedLocation = (areaId) => {
    let governate = null;
    let area = null;
    this.props.governates.map((gov) => {
      let isAreaExist = gov.list.filter((area) => {
        return area.id === areaId;
      });

      if (Array.isArray(isAreaExist) && isAreaExist.length > 0) {
        governate = gov;
        area = isAreaExist[0];
      }
    });

    console.log(governate);
    console.log(area);
    secureStorage.setItem("delivery_location", {
      governate: governate,
      area: area,
    });
    this.props.set_deliveryLocation({ governate: governate, area: area });
    this.setState((prevState) => {
      return {
        ...prevState,
        area: area,
        areaId: {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        },
        delivery_charges: governate.price,
      };
    });
  };

  equalsIgnoringCase = (text, other) => {
    return text.localeCompare(other, undefined, { sensitivity: "base" }) === 0;
  };

  checkCoupon = () => {
    console.log(
      `${BASE_API_URL}/coupon?coupon=${this.state.coupon}&amount=${this.props.cart.calculated_total}`
    );
    fetch(
      `${BASE_API_URL}/coupon?coupon=${this.state.coupon}&amount=${this.props.cart.calculated_total}`,
      {
        method: "POST",
        body: JSON.stringify({
          shop_id: SID,
          coupon: this.state.coupon,
          amount: this.props.cart.calculated_total,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        console.log(resp);
        if (resp.status == "Success") {
          this.setState((prevState) => {
            return {
              ...prevState,
              discount: parseFloat(resp.coupon_value).toFixed(3),
              coupon_err: null,
            };
          });
        } else if (resp.status == "Failed") {
          this.setState((prevState) => {
            return {
              ...prevState,
              discount: 0,
              coupon_err: resp.message,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  placeOrder = () => {
    let products = this.props.cart.items.map((item, index) => {
      let product = {
        product_id: item.id,
        product_name: item.title,
        product_name_ar: item.title_ar,
        product_price: item.price,
        instructions: "",
        quantity: item.qty,
        price: item.calculated_price,
        total: item.qty * item.calculated_price,
        addons: item.addons,
      };
      let addons = cloneDeep(item.addons);
      let updatedAddons = [];
      addons.map((addon) => {
        // let addonitems = [];
        let addonitems = addon.items.map((addonitem) => {
          return {
            product_id: item.id,
            addon_id: addon.id,
            addon_title: addonitem.title,
            addon_title_ar: addonitem.title_ar,
            price: addonitem.price,
          };
        });
        updatedAddons = [...updatedAddons, ...addonitems];
      });
      // product.addons.map(addon => {
      //     addon.addon_id = addon.id;
      // })
      product["addons"] = updatedAddons;
      return product;
    });

    let jsonData = {
      fname: this.state.fname,
      lname: this.state.lname ? this.state.lname : "",
      email: this.state.email,
      phone: this.state.mobile,
      mobile: "",
      country: this.state.country,
      area: this.state.area,
      block: this.state.block,
      street: this.state.street,
      avenue: this.state.avenue ? this.state.avenue : "",
      type: this.state.housetype,
      directions: this.state.additional_information
        ? this.state.additional_information
        : "",
      payment_method: this.state.payment_method,
      addr_type: "Delivery",
      delivery_timing: "2",
      delivery_date: null,
      delivery_time: null,
      price: parseFloat(this.props.cart.calculated_total),
      coupon: "",
      discount: 0,
      delivery_charges: 0,
      order_total: 0,
      member_id: "-1",
      products: products,
    };

    if (this.state.deliver_schedule === "deliver-now") {
      let d = new Date();
      console.log(d);
      console.log(d.getFullYear());
      console.log(d.getMonth());
      console.log(d.getDate());
      console.log(d.getHours());
      console.log(d.getMinutes());
      let dateStr = `${d.getFullYear()}-${
        parseInt(d.getMonth()) + 1
      }-${d.getDate()}`;
      let mins = d.getMinutes();
      if (d.getMinutes() <= 9) {
        mins = "0" + d.getMinutes();
      }
      let timeStr = this.timeConversion(`${d.getHours()}:${mins}`);
      let deliver_date = dateStr + " " + timeStr;
      console.log("delivery date", deliver_date);
      jsonData["delivery_time"] = deliver_date;
      jsonData["delivery_date"] = dateStr;
    } else if (this.state.deliver_schedule === "deliver-later") {
      // let d = new Date(this.state.)
      let d = this.state.selectedDate;
      let del = new Date(this.state.selectedDate);
      // console.log()
      // let c = new Date();
      // let cTime = c.getTime();
      // let dTime = del.getTime();
      // console.log(del);
      // console.log(c);
      // console.log(cTime);
      // console.log(dTime);
      // let checkDate = new Date();
      // checkDate.setSeconds((cTime-600000)/1000)
      // console.log(checkDate);
      // if(dTime >= (cTime-600000)) {

      //     let dateStr = d.split('T')[0];
      //     let timeStr = this.timeConversion(d.split('T')[1]);
      //     let deliver_date = dateStr + ' ' + timeStr;
      //     console.log("deliver later delivery date - " , deliver_date);
      //     jsonData["delivery_date"] = dateStr;
      //     jsonData["delivery_time"] = deliver_date;
      // } else {
      //     alert("delivery date/time should be in past");
      // }
      if (
        this.state.selected_slot !== null &&
        this.state.selected_slot !== undefined
      ) {
        console.log("set the delivery time and date");
        jsonData["delivery_time"] = this.state.selected_slot;
        jsonData[
          "delivery_date"
        ] = `${this.state.selectedDate.getDate()}-${this.state.selectedDate.getMonth()}-${this.state.selectedDate.getFullYear()}`;
      } else {
        alert("Please select delivery slot");
      }
    }

    if (this.state.housetype === "house") {
      jsonData["house"] = this.state.house_number;
      jsonData["building"] = "";
      jsonData["floor"] = "";
      jsonData["flat"] = "";
    } else if (this.state.housetype === "apartment") {
      jsonData["house"] = "";
      jsonData["building"] = this.state.apartment_name;
      jsonData["floor"] = this.state.apartment_floor;
      jsonData["flat"] = this.state.apartment_flat;
    } else if (this.state.housetype === "office") {
      jsonData["house"] = "";
      jsonData["building"] = this.state.office_name;
      jsonData["floor"] = this.state.office_floor;
      jsonData["flat"] = this.state.office_number;
    } else {
      jsonData["house"] = "";
      jsonData["building"] = "";
      jsonData["floor"] = "";
      jsonData["flat"] = "";
    }

    console.log(jsonData);

    // console.log(`https://al7alabi.com/app/guest?json=${jsonAppender}`);

    if (
      this.state.country !== null &&
      this.state.country !== "select your country"
    ) {
      jsonData["country"] = this.state.country;
    } else {
      jsonData["country"] = null;
      alert("Please select your country");
      return;
    }

    if (
      this.props.delivery_location !== null &&
      this.props.delivery_location !== undefined
    ) {
      // jsonData["area"] = this.props.delivery_location.id;
      jsonData["area"] = this.props.delivery_location.area;
      jsonData["delivery_charges"] =
        this.props.delivery_location.governate.price;
    } else {
      alert("Please select your area/Location");
      return;
    }

    let canSubmitOrder = true;
    for (const prope in jsonData) {
      // if(prope !== 'directions') {
      console.log();
      if (jsonData[prope] === null || jsonData[prope] === undefined) {
        console.log("null/undefined value is ", prope, "----", jsonData[prope]);
        canSubmitOrder = false;
      }
      // [{"product_id":"134","addon_id":"2899","addon_title":"5 Person","addon_title_ar":"5 أشخاص","price":"6.000"}]
      // }
    }
    jsonData["order_total"] =
      parseFloat(jsonData["price"]) +
      parseFloat(jsonData["delivery_charges"]) -
      parseFloat(jsonData["discount"]);
    console.log(jsonData, "new DAta");
    let jsonAppender = JSON.stringify(jsonData);
    console.log(jsonAppender);
    if (jsonData["price"])
      if (canSubmitOrder) {
        fetch(`${BASE_API_URL}/placeorder`, {
          method: "POST",
          body: jsonAppender,
        })
          .then((resp) => {
            return resp.json();
          })
          .then((resJson) => {
            console.log(resJson);

            alert(resJson.status);
            this.props.resetCart();
            // this.props.history.push("/");
            this.props.history.push(`/order-success/${resJson.invoice_id}`, {
              order_total: jsonData["total_price"],
            });
            // this.props.
          })
          .catch((err) => {
            console.log(err);
            alert("Order failed");
          });
      } else {
        alert("Please provide all the required fields");
      }
  };

  updateDeliveryType = (val) => {
    if (val == "deliver_now") {
      this.setState((prevState) => {
        return {
          ...prevState,
          deliver_date: null,
          deliver_time: null,
          selected_slot: { label: null, value: null },
          selectedDate: null,
          deliver_schedule: null,
          delivery_type: "deliver_now",
          delivery_charges:
            this.props.delivery_location_governate !== null &&
            this.props.delivery_location_governate !== undefined
              ? this.props.delivery_location_governate.price
              : 0,
        };
      });
    }
    if (val == "takeaway") {
      this.setState((prevState) => {
        return {
          ...prevState,
          delivery_type: "takeaway",
          delivery_charges: 0,
        };
      });
    }
    if (val == "deliver_later") {
      this.setState((prevState) => {
        return {
          ...prevState,
          delivery_type: "deliver_later",
          delivery_charges:
            this.props.delivery_location_governate !== null &&
            this.props.delivery_location_governate !== undefined
              ? this.props.delivery_location_governate.price
              : 0,
        };
      });
    }
  };

  orderPlacement = () => {
    // let products = this.props.cart.items.map((item, index) => {
    //     let product = {
    //         product_id: item.id,
    //         product_name: item.title,
    //         product_name_ar: item.title_ar,
    //         product_price: item.price,
    //         instructions: "",
    //         quantity: item.qty,
    //         price: item.calculated_price,
    //         total: item.qty * item.calculated_price,
    //         addons: item.addons

    //     }
    //     let addons = cloneDeep(item.addons);
    //     let updatedAddons = [];
    //     addons.map(addon => {
    //         // let addonitems = [];
    //         let addonitems = addon.items.map(addonitem => {
    //             return {
    //                 product_id: item.id,
    //                 addon_id: addon.id,
    //                 addon_title: addonitem.title,
    //                 addon_title_ar: addonitem.title_ar,
    //                 price: addonitem.price
    //             }
    //         })
    //         updatedAddons = [...updatedAddons, ...addonitems];

    //     })
    //     // product.addons.map(addon => {
    //     //     addon.addon_id = addon.id;
    //     // })
    //     product["addons"] = updatedAddons;
    //     return product
    // });

    let products = this.props.cart.items;
    let error_state = {
      fname: this.state.fname ? false : true,
      lname: this.state.lname ? false : true,
      phone: this.state.mobile ? false : true,
      mobile: this.state.mobile ? false : true,
      email: this.state.email ? false : true,
      country: false,
      area: false,
      governate: false,
      block: this.state.block ? false : true,
      street: this.state.street ? false : true,
      avenue: this.state.avenue ? false : true,
      housetype: false,
      payment_method: this.state.payment_method ? false : true,
      address_line_1: false,
      city: false,
      state: false,
      zipcode: false,
      house_number: false,
      office_number: false,
      office_name: false,
      office_floor: false,
      apartment_name: false,
      apartment_flat: false,
      apartment_floor: false,
      deliver_date: false,
      deliver_time: false,
    };

    let jsonData = {
      shop_id: SID,
      first_name: this.state.fname,
      last_name: this.state.lname ? this.state.lname : "",
      email: this.state.email ? this.state.email : null,
      phone: this.state.mobile ? this.state.mobile : null,
      country: this.state.country,
      area: this.state.area,
      governate: this.props.delivery_location_governate
        ? this.props.delivery_location_governate
        : null,
      block: this.state.block ? this.state.block : null,
      street: this.state.street ? this.state.street : null,
      avenue: this.state.avenue ? this.state.avenue : "",
      housetype: this.state.housetype ? this.state.housetype : null,
      directions: this.state.additional_information
        ? this.state.additional_information
        : "",
      payment_method: this.state.payment_method
        ? this.state.payment_method
        : null,
      delivery_type: this.state.delivery_type
        ? this.state.delivery_type
        : "delivery",
      // order_type: this.state.
      // payment_type: this.state.payment_type,
      // delivery_timing: '2',
      // delivery_date: '',
      // delivery_time: '',
      cart_value: parseFloat(this.props.cart.calculated_total).toFixed(3),
      coupon_type: "",
      coupon_value: parseFloat(this.state.discount).toFixed(3),
      coupon_code: this.state.coupon,
      discount: parseFloat(this.state.discount).toFixed(3),
      delivery_charges: parseFloat(this.state.delivery_charges).toFixed(3),
      order_total_: parseFloat(
        this.props.cart.calculated_total +
          this.state.delivery_charges -
          this.state.discount
      ).toFixed(3),
      member_id: "-1",
      products: products,
      checkout_type: "guest",
      branch_id: "",
      // payment_type: this.state.pay
      // conversion_currency_code: "Rupees",
      // conversion_rate: 245
    };

    if (
      this.state.country !== null &&
      this.state.country !== "select your country"
    ) {
      if (
        this.state.country.value == "965" ||
        this.state.country.value == "+965"
      ) {
        error_state.address_line_1 = false;
        error_state.city = false;
        error_state.state = false;
        jsonData["country"] = this.state.country;
        if (
          this.props.delivery_location !== null &&
          this.props.delivery_location !== undefined
        ) {
          jsonData["area"] = this.state.area ? this.state.area : null;
          jsonData["governate"] = this.props.delivery_location_governate
            ? this.props.delivery_location_governate
            : null;
          jsonData["delivery_charges"] = parseFloat(
            this.state.delivery_charges
          ).toFixed(3);
        } else {
          error_state.area = true;
          error_state.governate = true;
          // alert("Please select your area/Location");
          // return
        }

        // if(this.state.deliver_schedule === 'deliver-now') {
        //     let d = new Date();
        //     console.log(d);
        //     console.log(d.getFullYear());
        //     console.log(d.getMonth());
        //     console.log(d.getDate());
        //     console.log(d.getHours());
        //     console.log(d.getMinutes());
        //     let dateStr = `${d.getFullYear()}-${parseInt(d.getMonth()) + 1}-${d.getDate()}`;
        //     let mins = d.getMinutes();
        //     if(d.getMinutes() <= 9) {
        //         mins = '0' + d.getMinutes();
        //     }
        //     let timeStr = this.timeConversion(`${d.getHours()}:${mins}`);
        //     let deliver_date = dateStr + ' ' + timeStr;
        //     console.log("delivery date", deliver_date);
        //     // jsonData["delivery_time"] = deliver_date;
        //     // jsonData["delivery_date"] = dateStr;
        // } else if(this.state.deliver_schedule === "deliver-later") {
        //     // let d = new Date(this.state.)
        //     let d = this.state.selectedDate;
        //     let del = new Date(this.state.selectedDate);
        //     // console.log()
        //     // let c = new Date();
        //     // let cTime = c.getTime();
        //     // let dTime = del.getTime();
        //     // console.log(del);
        //     // console.log(c);
        //     // console.log(cTime);
        //     // console.log(dTime);
        //     // let checkDate = new Date();
        //     // checkDate.setSeconds((cTime-600000)/1000)
        //     // console.log(checkDate);
        //     // if(dTime >= (cTime-600000)) {

        //     //     let dateStr = d.split('T')[0];
        //     //     let timeStr = this.timeConversion(d.split('T')[1]);
        //     //     let deliver_date = dateStr + ' ' + timeStr;
        //     //     console.log("deliver later delivery date - " , deliver_date);
        //     //     jsonData["delivery_date"] = dateStr;
        //     //     jsonData["delivery_time"] = deliver_date;
        //     // } else {
        //     //     alert("delivery date/time should be in past");
        //     // }
        //     if(this.state.selected_slot !== null && this.state.selected_slot !== undefined) {
        //         console.log("set the delivery time and date");
        //         // jsonData["delivery_time"] = this.state.selected_slot;
        //         // jsonData["delivery_date"] = `${this.state.selectedDate.getDate()}-${this.state.selectedDate.getMonth()}-${this.state.selectedDate.getFullYear()}`;
        //     } else {
        //         alert("Please select delivery slot");
        //     }
        // }
        if (
          this.state.delivery_type == "deliver_later" ||
          this.state.delivery_type == "takeaway"
        ) {
          jsonData["delivery_date"] = this.state.deliver_date
            ? this.state.deliver_date
            : null;
          jsonData["delivery_time"] = this.state.deliver_time
            ? this.state.deliver_time
            : null;

          jsonData["selected_slot"] = this.state.selected_slot
            ? this.state.selected_slot
            : null;

          error_state.deliver_date = this.state.deliver_date ? false : true;
          error_state.deliver_time = this.state.deliver_time ? false : true;
        } else {
          jsonData["delivery_date"] = "";
          jsonData["delivery_time"] = "";

          jsonData["selected_slot"] = "";
        }

        if (this.state.housetype === "house") {
          jsonData["house_number"] = this.state.house_number
            ? this.state.house_number
            : null;
          jsonData["office_number"] = "";
          jsonData["office_name"] = "";
          jsonData["office_floor"] = "";
          jsonData["apartment_name"] = "";
          jsonData["apartment_flat"] = "";
          jsonData["apartment_floor"] = "";
          error_state.house_number = this.state.house_number ? false : true;
        } else if (this.state.housetype === "apartment") {
          jsonData["house_number"] = "";
          jsonData["office_number"] = "";
          jsonData["office_name"] = "";
          jsonData["office_floor"] = "";
          jsonData["apartment_name"] = this.state.apartment_name
            ? this.state.apartment_name
            : null;
          jsonData["apartment_floor"] = this.state.apartment_floor
            ? this.state.apartment_floor
            : null;
          jsonData["apartment_flat"] = this.state.apartment_flat
            ? this.state.apartment_flat
            : null;
          error_state.apartment_name = this.state.apartment_name ? false : true;
          error_state.apartment_floor = this.state.apartment_floor
            ? false
            : true;
          error_state.apartment_flat = this.state.apartment_flat ? false : true;
        } else if (this.state.housetype === "office") {
          jsonData["house_number"] = "";
          jsonData["office_number"] = this.state.office_number
            ? this.state.office_number
            : null;
          jsonData["office_name"] = this.state.office_name
            ? this.state.office_name
            : null;
          jsonData["office_floor"] = this.state.office_floor
            ? this.state.office_floor
            : null;
          jsonData["apartment_name"] = "";
          jsonData["apartment_floor"] = "";
          jsonData["apartment_flat"] = "";
          error_state.office_name = this.state.office_name ? false : true;
          error_state.office_number = this.state.office_number ? false : true;
          error_state.office_floor = this.state.office_floor ? false : true;
        } else {
          jsonData["house_number"] = "";
          jsonData["office_number"] = "";
          jsonData["office_name"] = "";
          jsonData["office_floor"] = "";
          jsonData["apartment_name"] = "";
          jsonData["apartment_floor"] = "";
          jsonData["apartment_flat"] = "";
        }
      } else {
        error_state.block = false;
        error_state.street = false;
        error_state.avenue = false;
        error_state.house_number = false;
        error_state.office_name = false;
        error_state.office_number = false;
        error_state.office_floor = false;
        error_state.apartment_name = false;
        error_state.apartment_flat = false;
        error_state.apartment_floor = false;
        error_state.address_line_1 = this.state.address_line_1 ? false : true;
        error_state.city = this.state.city ? false : true;
        error_state.state = this.state.state ? false : true;
        error_state.zipcode = this.state.zipcode ? false : true;
        jsonData["address_line1"] = this.state.address_line_1
          ? this.state.address_line_1
          : null;
        jsonData["address_line2"] = this.state.address_line_2
          ? this.state.address_line_2
          : null;
        jsonData["city"] = this.state.city ? this.state.city : null;
        jsonData["state"] = this.state.state ? this.state.state : null;
        jsonData["pincode"] = this.state.zipcode ? this.state.zipcode : null;
        jsonData["area"] = "";
        jsonData["governate"] = "";
        jsonData["block"] = "";
        jsonData["street"] = "";
        jsonData["avenue"] = "";
        jsonData["delivery_date"] = "";
        jsonData["delivery_time"] = "";
        jsonData["selected_slot"] = "";
      }

      let canSubmitOrder = true;
      for (const prope in jsonData) {
        // if(prope !== 'directions') {
        console.log();
        if (jsonData[prope] === null || jsonData[prope] === undefined) {
          console.log(
            "null/undefined value is ",
            prope,
            "----",
            jsonData[prope]
          );
          canSubmitOrder = false;
        }
        // [{"product_id":"134","addon_id":"2899","addon_title":"5 Person","addon_title_ar":"5 أشخاص","price":"6.000"}]
        // }
      }

      for (const ervar in error_state) {
        if (error_state[ervar]) {
          canSubmitOrder = false;
        }
      }
      jsonData["order_total"] = (
        parseFloat(this.props.cart.calculated_total) +
        parseFloat(this.state.delivery_charges) -
        parseFloat(this.state.discount)
      ).toFixed(3);
      console.log(jsonData, "new DAta");
      let jsonAppender = JSON.stringify(jsonData);
      console.log(jsonAppender);
      console.log(error_state);
      if (canSubmitOrder) {
        fetch(`${BASE_API_URL}/newplaceorder`, {
          method: "POST",
          body: jsonAppender,
          "Content-Type": "application/json",
        })
          .then((resp) => {
            return resp.json();
          })
          .then((resJson) => {
            console.log("-----------------return data----------------");
            console.log(resJson);

            alert(resJson.status);
            this.props.resetCart();
            this.props.history.push(`/order-success/${resJson.id}`, {
              order_total: jsonData["order_total"],
              order_id: resJson.id,
            });
          })
          .catch((err) => {
            console.log(err);
            alert("Order failed");
          });
      } else {
        // alert("Please provide all the required fields");
        let curr_state = cloneDeep(this.state);
        this.setState((prevState) => {
          return {
            ...prevState,
            error_state: {
              ...error_state,
            },
          };
        });
        return;
      }
    } else {
      error_state.country = true;
      jsonData["country"] = null;
      let curr_state = cloneDeep(this.state);
      this.setState((prevState) => {
        return {
          ...prevState,
          error_state: {
            ...error_state,
          },
        };
      });
      console.log(error_state);
      // alert("Please select your country");
      return;
    }
  };

  render() {
    let countryOptions = this.props.countries.map((country, index) => {
      // let options = [];
      let countryslot = {
        label: this.props.lang == "en" ? country.title : country.title_ar,
        value: country.code,
      };
      return countryslot;
    });

    // if(this.state.governate !== null && this.state.governate !== undefined) {
    let options = this.props.governates.map((governate, index) => {
      let item = {
        label: this.props.lang == "en" ? governate.title : governate.title_ar,
      };
      let options = governate.list.map((area, index) => {
        let option = {
          label: this.props.lang == "en" ? area.title : area.title_ar,
          value: area.id,
        };
        return option;
      });

      item["options"] = options;
      return item;
    });
    // }

    let items = this.props.cart.items.map((item, index) => {
      return (
        <li key={index}>
          <a>
            {item.qty}x{" "}
            {this.props.lang == "en"
              ? item.translations.en.title
              : item.translations.ar.title}
          </a>
          <span>
            {this.props.currency.base_currency}{" "}
            {(item.calculated_total * this.props.multiplier.multiplier).toFixed(
              3
            )}
          </span>
        </li>
      );
    });

    let slotOptions = this.state.available_slots.map((slot, index) => {
      let retslot = {
        label: slot.string,
        value: slot.value,
      };
      return retslot;
    });

    return (
      <>
        <main className="bg_gray">
          <div className="container margin_60_20">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="box_order_form">
                  <div className="head">
                    <div className="title">
                      <h3>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Address Details"]
                          : this.props.translations.ar["Address Details"]}
                      </h3>
                    </div>
                  </div>

                  <div className="main">
                    <label>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Country"]
                        : this.props.translations.ar["Country"]}{" "}
                      <span className="required">*</span>
                    </label>
                    {/* {this.state.error_state.country ? <p className="error">Country is required</p> : null} */}
                    <Select
                      onChange={(e) => this.updateSelectedCountry(e.value)}
                      placeholder={
                        this.props.lang === "en"
                          ? this.props.translations.en["Select Your Country"]
                          : this.props.translations.ar["Select Your Country"]
                      }
                      className="custom-select js-example-basic-single"
                      id="inputGroupSelectForTime"
                      options={countryOptions}
                      value={this.state.country}
                    ></Select>
                    {this.state.error_state.country ? (
                      <p className="error">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Country is required"]
                          : this.props.translations.ar["Country is required"]}
                        Country is required
                      </p>
                    ) : null}
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["First Name"]
                          : this.props.translations.ar["First Name"]}{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["First Name"]
                            : this.props.translations.ar["First Name"]
                        }
                        onChange={(e) =>
                          this.setDataVariable("fname", e.currentTarget.value)
                        }
                        value={this.state.first_name}
                      />
                      {this.state.error_state.fname ? (
                        <p className="error">
                          {this.props.lang == "en"
                            ? this.props.translations.en[
                                "First Name is required"
                              ]
                            : this.props.translations.ar[
                                "First Name is required"
                              ]}
                          First Name is required
                        </p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Last Name"]
                          : this.props.translations.ar["Last Name"]}{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Last Name"]
                            : this.props.translations.ar["Last Name"]
                        }
                        onChange={(e) =>
                          this.setDataVariable("lname", e.currentTarget.value)
                        }
                        value={this.state.last_name}
                      />
                      {this.state.error_state.lname ? (
                        <p className="error">
                          {this.props.lang == "en"
                            ? this.props.translations.en[
                                "Last Name is required"
                              ]
                            : this.props.translations.ar[
                                "Last Name is required"
                              ]}
                          Last Name is required
                        </p>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Email Address"]
                              : this.props.translations.ar["Email Address"]}
                          </label>
                          <input
                            className="form-control"
                            placeholder={
                              this.props.lang == "en"
                                ? this.props.translations.en["Email Address"]
                                : this.props.translations.ar["Email Address"]
                            }
                            value={this.state.email}
                            onChange={(e) =>
                              this.setDataVariable(
                                "email",
                                e.currentTarget.value
                              )
                            }
                          />
                          {this.state.error_state.email ? (
                            <p className="error">
                              {this.props.lang == "en"
                                ? this.props.translations.en[
                                    "Email Address is required"
                                  ]
                                : this.props.translations.ar[
                                    "Email Address is required"
                                  ]}
                              Email is required
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Phone"]
                              : this.props.translations.ar["Phone"]}{" "}
                            <span className="required">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder={
                              this.props.lang == "en"
                                ? this.props.translations.en["Phone"]
                                : this.props.translations.ar["Phone"]
                            }
                            value={this.state.mobile}
                            onChange={(e) =>
                              this.setDataVariable(
                                "mobile",
                                e.currentTarget.value
                              )
                            }
                          />
                          {this.state.error_state.mobile ? (
                            <p className="error">
                              {this.props.lang == "en"
                                ? this.props.translations.en[
                                    "Phone is required"
                                  ]
                                : this.props.translations.ar[
                                    "Phone is required"
                                  ]}
                              Phone is required
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* <label>{this.props.lang == "en" ? this.props.translations.en["Country"] : this.props.translations.ar["Country"]} <span className="required">*</span></label>
                                
                                <Select onChange={(e) => this.updateSelectedCountry(e.value)} placeholder={this.props.lang === "en" ? this.props.translations.en["Select Your Country"] : this.props.translations.ar["Select Your Country"]} className="custom-select js-example-basic-single" id="inputGroupSelectForTime" options={countryOptions} value={this.state.country}></Select>
                                {this.state.error_state.country ? <p className="error">{this.props.lang == "en" ? this.props.translations.en["Country is required"] : this.props.translations.ar["Country is required"]}Country is required</p> : null} */}

                    {this.state.country !== null &&
                    this.state.country !== undefined ? (
                      this.state.country.value == "+965" ||
                      this.state.country.value == "965" ? (
                        <>
                          <label>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Area"]
                              : this.props.translations.ar["Area"]}{" "}
                            <span className="required">*</span>
                          </label>
                          {this.state.error_state.area ? (
                            <p className="error">
                              {this.props.lang == "en"
                                ? this.props.translations.en["Area is required"]
                                : this.props.translations.ar["Area is requied"]}
                              Area is required
                            </p>
                          ) : null}
                          <Select
                            onChange={(e) => this.selectedLocation(e.value)}
                            placeholder={
                              this.props.lang === "en"
                                ? this.props.translations.en["Select Area"]
                                : this.props.translations.ar["Select Area"]
                            }
                            className="custom-select js-example-basic-single"
                            id="inputGroupSelect04"
                            options={options}
                            value={this.state.areaId}
                          ></Select>

                          {this.state.country ? (
                            this.state.country.value === "+965" ||
                            this.state.country.value == "965" ? (
                              <div className="row mt-3">
                                <div className="col-4">
                                  <label className="container_radio">
                                    {this.props.lang == "en"
                                      ? this.props.translations.en[
                                          "Deliver Now"
                                        ]
                                      : this.props.translations.ar[
                                          "Deliver Now"
                                        ]}
                                    Deliver Now
                                    {this.state.delivery_type ==
                                    "deliver_now" ? (
                                      <input
                                        type="radio"
                                        value="deliver_now"
                                        checked
                                        name="opt_order"
                                        onClick={(e) =>
                                          this.setDataVariable(
                                            "delivery_type",
                                            e.currentTarget.value
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="radio"
                                        value="deliver_now"
                                        name="opt_order"
                                        onClick={(e) =>
                                          this.updateDeliveryType(
                                            e.currentTarget.value
                                          )
                                        }
                                      />
                                    )}
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div className="col-4">
                                  <label className="container_radio">
                                    {this.props.lang == "en"
                                      ? this.props.translations.en[
                                          "Deliver Later"
                                        ]
                                      : this.props.translations.ar[
                                          "Deliver Later"
                                        ]}
                                    Deliver Later
                                    {this.state.delivery_type ==
                                    "deliver_later" ? (
                                      <input
                                        type="radio"
                                        value="deliver_later"
                                        checked
                                        name="opt_order"
                                        onClick={(e) =>
                                          this.setDataVariable(
                                            "delivery_type",
                                            e.currentTarget.value
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="radio"
                                        value="deliver_later"
                                        name="opt_order"
                                        onClick={(e) =>
                                          this.updateDeliveryType(
                                            e.currentTarget.value
                                          )
                                        }
                                      />
                                    )}
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                {/* {this.props.branches.length > 0 ? */}
                                <div className="col-4">
                                  <label className="container_radio">
                                    {this.props.lang == "en"
                                      ? this.props.translations.en["Take Away"]
                                      : this.props.translations.ar["Take Away"]}
                                    Take away
                                    {this.state.delivery_type == "takeaway" ? (
                                      <input
                                        type="radio"
                                        value="takeaway"
                                        name="opt_order"
                                        onClick={(e) =>
                                          this.setDataVariable(
                                            "delivery_type",
                                            e.currentTarget.value
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="radio"
                                        value="takeaway"
                                        name="opt_order"
                                        onClick={(e) =>
                                          this.updateDeliveryType(
                                            e.currentTarget.value
                                          )
                                        }
                                      />
                                    )}
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                {/* : null } */}
                              </div>
                            ) : null
                          ) : null}

                          <div className="row" style={{ marginTop: "15px" }}>
                            {this.state.delivery_type == "deliver_later" ||
                            this.state.delivery_type == "takeaway" ? (
                              <>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="form-group">
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en["Date"]
                                        : this.props.translations.ar[
                                            "Date"
                                          ]}{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        value={this.state.selectedDate}
                                        onChange={this.handleDateChange}
                                        placeholder={
                                          this.props.lang == "en"
                                            ? this.props.translations.en[
                                                "Select Date"
                                              ]
                                            : this.props.translations.ar[
                                                "Select Date"
                                              ]
                                        }
                                        disablePast={true}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                  {this.state.error_state.deliver_date ? (
                                    <p className="error">
                                      {this.props.lang == "en"
                                        ? this.props.translations.en[
                                            "Date is required"
                                          ]
                                        : this.props.translations.ar[
                                            "Date is required"
                                          ]}
                                      Date is required
                                    </p>
                                  ) : null}
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="form-group">
                                    {/* <input type="text" class="form-control"  aria-describedby="" placeholder="Area*" value={this.state.area} onChange={(e) => this.setDataVariable("area", e.currentTarget.value)}/> */}
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en["TimeSlot"]
                                        : this.props.translations.ar[
                                            "TimeSlot"
                                          ]}
                                      TimeSlot{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <Select
                                      onChange={(e) =>
                                        this.selectedTimeSlot(e.value)
                                      }
                                      placeholder={
                                        this.props.lang === "en"
                                          ? this.props.translations.en[
                                              "Select Your TimeSlot"
                                            ]
                                          : this.props.translations.ar[
                                              "Select Your TimeSlot"
                                            ]
                                      }
                                      className="custom-select js-example-basic-single"
                                      id="inputGroupSelectForTime"
                                      options={slotOptions}
                                      value={this.state.selected_slot.value}
                                    ></Select>
                                  </div>
                                  {this.state.error_state.deliver_time ? (
                                    <p className="error">
                                      {this.props.lang == "en"
                                        ? this.props.translations.en[
                                            "TimeSlot is required"
                                          ]
                                        : this.props.translations.ar[
                                            "TimeSlot is required"
                                          ]}
                                      Timeslot is required
                                    </p>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <label>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Block"]
                                : this.props.translations.ar["Block"]}{" "}
                              <span className="required">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder={
                                this.props.lang == "en"
                                  ? this.props.translations.en["Block"]
                                  : this.props.translations.ar["Block"]
                              }
                              value={this.state.block}
                              onChange={(e) =>
                                this.setDataVariable(
                                  "block",
                                  e.currentTarget.value
                                )
                              }
                            />
                            {this.state.error_state.block ? (
                              <p className="error">
                                {this.props.lang == "en"
                                  ? this.props.translations.en[
                                      "Block is required"
                                    ]
                                  : this.props.translations.ar[
                                      "Block is required"
                                    ]}
                                Block is required
                              </p>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Street"]
                                : this.props.translations.ar["Street"]}{" "}
                              <span className="required">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder={
                                this.props.lang == "en"
                                  ? this.props.translations.en["Street"]
                                  : this.props.translations.ar["Street"]
                              }
                              value={this.state.street}
                              onChange={(e) =>
                                this.setDataVariable(
                                  "street",
                                  e.currentTarget.value
                                )
                              }
                            />
                            {this.state.error_state.street ? (
                              <p className="error">
                                {this.props.lang == "en"
                                  ? this.props.translations.en[
                                      "Street is required"
                                    ]
                                  : this.props.translations.ar[
                                      "Street is required"
                                    ]}
                                Street is required
                              </p>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Avenue"]
                                : this.props.translations.ar["Avenue"]}{" "}
                              <span className="required">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder={
                                this.props.lang == "en"
                                  ? this.props.translations.en["Avenue"]
                                  : this.props.translations.ar["Avenue"]
                              }
                              value={this.state.avenue}
                              onChange={(e) =>
                                this.setDataVariable(
                                  "avenue",
                                  e.currentTarget.value
                                )
                              }
                            />
                            {this.state.error_state.avenue ? (
                              <p className="error">
                                {this.props.lang == "en"
                                  ? this.props.translations.en[
                                      "Avenue is required"
                                    ]
                                  : this.props.translations.ar[
                                      "Avenue is required"
                                    ]}
                                Avenue is required
                              </p>
                            ) : null}
                          </div>
                          <div
                            className="row"
                            style={{ textAlign: "center", height: "45px" }}
                          >
                            <div
                              onClick={() => {
                                this.setHouseType("house");
                              }}
                              className="col-4"
                              style={{ borderRadius: "7px" }}
                            >
                              {this.props.lang == "en"
                                ? this.props.translations.en["Home"]
                                : this.props.translations.ar["Home"]}
                            </div>
                            <div
                              onClick={() => {
                                this.setHouseType("office");
                              }}
                              className="col-4"
                              style={{ borderRadius: "7px" }}
                            >
                              {this.props.lang == "en"
                                ? this.props.translations.en["OFFICE"]
                                : this.props.translations.ar["OFFICE"]}
                              OFFICE
                            </div>
                            <div
                              onClick={() => {
                                this.setHouseType("apartment");
                              }}
                              className="col-4"
                              style={{ borderRadius: "7px" }}
                            >
                              {this.props.lang == "en"
                                ? this.props.translations.en["APARTMENT"]
                                : this.props.translations.ar["APARTMENT"]}
                              APARTMENT
                            </div>
                          </div>
                          {this.state.housetype === "house" ? (
                            <>
                              <div className="form-group">
                                <label>
                                  {this.props.lang == "en"
                                    ? this.props.translations.en["House Number"]
                                    : this.props.translations.ar[
                                        "House Number"
                                      ]}{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder={
                                    this.props.lang == "en"
                                      ? this.props.translations.en[
                                          "House Number"
                                        ]
                                      : this.props.translations.ar[
                                          "House Number"
                                        ]
                                  }
                                  value={this.state.house_number}
                                  onChange={(e) => {
                                    this.setDataVariable(
                                      "house_number",
                                      e.currentTarget.value
                                    );
                                  }}
                                />
                                {this.state.error_state.house_number ? (
                                  <p className="error">
                                    {this.props.lang == "en"
                                      ? this.props.translations.en[
                                          "House Number is required"
                                        ]
                                      : this.props.translations.ar[
                                          "House Number is required"
                                        ]}
                                    House Number is required
                                  </p>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                          {this.state.housetype === "office" ? (
                            <>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en[
                                            "Office Name"
                                          ]
                                        : this.props.translations.ar[
                                            "Office Name"
                                          ]}
                                      Office Name{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      placeholder={
                                        this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Office Name"
                                            ]
                                          : this.props.translations.ar[
                                              "Office Name"
                                            ]
                                      }
                                      value={this.state.office_name}
                                      onChange={(e) => {
                                        this.setDataVariable(
                                          "office_name",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    {this.state.error_state.office_name ? (
                                      <p className="error">
                                        {this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Office Name is required"
                                            ]
                                          : this.props.translations.ar[
                                              "Office Name is required"
                                            ]}
                                        Office Name is required
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en[
                                            "Office Number"
                                          ]
                                        : this.props.translations.ar[
                                            "Office Number"
                                          ]}{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      placeholder={
                                        this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Office Number"
                                            ]
                                          : this.props.translations.ar[
                                              "Office Number"
                                            ]
                                      }
                                      value={this.state.office_number}
                                      onChange={(e) => {
                                        this.setDataVariable(
                                          "office_number",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    {this.state.error_state.office_number ? (
                                      <p className="error">
                                        {this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Office Number is required"
                                            ]
                                          : this.props.translations.ar[
                                              "Office Number is required"
                                            ]}
                                        Office Number is required
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en[
                                            "Office Floor"
                                          ]
                                        : this.props.translations.ar[
                                            "Office Floor"
                                          ]}
                                      Office Floor{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      placeholder={
                                        this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Office Floor"
                                            ]
                                          : this.props.translations.ar[
                                              "Office Floor"
                                            ]
                                      }
                                      value={this.state.office_floor}
                                      onChange={(e) => {
                                        this.setDataVariable(
                                          "office_floor",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    {this.state.error_state.office_floor ? (
                                      <p className="error">
                                        {this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Office Floor is required"
                                            ]
                                          : this.props.translations.ar[
                                              "Office Floor"
                                            ]}
                                        Office Floor is required
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}

                          {this.state.housetype === "apartment" ? (
                            <>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en[
                                            "Apartment Name"
                                          ]
                                        : this.props.translations.ar[
                                            "Apartment Name"
                                          ]}
                                      Apartment Name{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      placeholder={
                                        this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Apartment Name"
                                            ]
                                          : this.props.translations.ar[
                                              "Apartment Name"
                                            ]
                                      }
                                      value={this.state.apartment_name}
                                      onChange={(e) => {
                                        this.setDataVariable(
                                          "apartment_name",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    {this.state.error_state.apartment_name ? (
                                      <p className="error">
                                        {this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Apartment Name is required"
                                            ]
                                          : this.props.translations.ar[
                                              "Apartment Name is required"
                                            ]}
                                        Apartment Name is required
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en[
                                            "Flat Number"
                                          ]
                                        : this.props.translations.ar[
                                            "Flat Number"
                                          ]}{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      placeholder={
                                        this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Flat Number"
                                            ]
                                          : this.props.translations.ar[
                                              "Flat Number"
                                            ]
                                      }
                                      value={this.state.apartment_flat}
                                      onChange={(e) => {
                                        this.setDataVariable(
                                          "apartment_flat",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    {this.state.error_state.apartment_flat ? (
                                      <p className="error">
                                        {this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Flat Number is required"
                                            ]
                                          : this.props.translations.ar[
                                              "Flat Number is required"
                                            ]}
                                        Flat Number is required
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      {this.props.lang == "en"
                                        ? this.props.translations.en["Floor"]
                                        : this.props.translations.ar["Floor"]}
                                      Floor <span className="required">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      placeholder={
                                        this.props.lang == "en"
                                          ? this.props.translations.en["Floor"]
                                          : this.props.translations.ar["Floor"]
                                      }
                                      value={this.state.apartment_floor}
                                      onChange={(e) => {
                                        this.setDataVariable(
                                          "apartment_floor",
                                          e.currentTarget.value
                                        );
                                      }}
                                    />
                                    {this.state.error_state.apartment_floor ? (
                                      <p className="error">
                                        {this.props.lang == "en"
                                          ? this.props.translations.en[
                                              "Floor is required"
                                            ]
                                          : this.props.translations.ar[
                                              "Floor is required"
                                            ]}
                                        Floor is required
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null
                    ) : null}

                    {this.state.country !== null &&
                    this.state.country !== undefined ? (
                      this.state.country.value !== "+965" &&
                      this.state.country.value != "965" ? (
                        <>
                          <div className="form-group">
                            <label>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Address Line 1"]
                                : this.props.translations.ar["Address Line 1"]}
                              Address Line 1 <span className="required">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder={
                                this.props.lang == "en"
                                  ? this.props.translations.en["Address Line 1"]
                                  : this.props.translations.ar["Address Line 1"]
                              }
                              value={this.state.address_line_1}
                              onChange={(e) =>
                                this.setDataVariable(
                                  "address_line_1",
                                  e.currentTarget.value
                                )
                              }
                            />
                            {this.state.error_state.address_line_1 ? (
                              <p className="error">
                                {this.props.lang == "en"
                                  ? this.props.translations.en[
                                      "Address Line 1 is required"
                                    ]
                                  : this.props.translations.ar[
                                      "Address Line 1 is required"
                                    ]}
                                Address Line 1 is required
                              </p>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Address Line 2"]
                                : this.props.translations.ar["Address Line 2"]}
                              Address Line 2
                            </label>
                            <input
                              className="form-control"
                              placeholder="Address Line2"
                              value={this.state.address_line_2}
                              onChange={(e) =>
                                this.setDataVariable(
                                  "address_line_2",
                                  e.currentTarget.value
                                )
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              {this.props.lang == "en"
                                ? this.props.translations.en["City"]
                                : this.props.translations.ar["City"]}
                              City <span className="required">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder={
                                this.props.lang == "en"
                                  ? this.props.translations.en["City"]
                                  : this.props.translations.ar["City"]
                              }
                              value={this.state.city}
                              onChange={(e) =>
                                this.setDataVariable(
                                  "city",
                                  e.currentTarget.value
                                )
                              }
                            />
                            {this.state.error_state.city ? (
                              <p className="error">
                                {this.props.lang == "en"
                                  ? this.props.translations.en["City"]
                                  : this.props.translations.ar["City"]}
                                City is required
                              </p>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  {this.props.lang == "en"
                                    ? this.props.translations.en["State"]
                                    : this.props.translations.ar["State"]}
                                  State <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder={
                                    this.props.lang == "en"
                                      ? this.props.translations.en["State"]
                                      : this.props.translations.ar["State"]
                                  }
                                  value={this.state.state}
                                  onChange={(e) =>
                                    this.setDataVariable(
                                      "state",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                {this.state.error_state.state ? (
                                  <p className="error">
                                    {this.props.lang == "en"
                                      ? this.props.translations.en["State"]
                                      : this.props.translations.ar["State"]}
                                    State is required
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  {this.props.lang == "en"
                                    ? this.props.translations.en["Postal Code"]
                                    : this.props.translations.ar["Postal Code"]}
                                  Postal Code{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder={
                                    this.props.lang == "en"
                                      ? this.props.translations.en[
                                          "Postal Code"
                                        ]
                                      : this.props.translations.ar[
                                          "Postal Code"
                                        ]
                                  }
                                  value={this.state.zipcode}
                                  onChange={(e) =>
                                    this.setDataVariable(
                                      "zipcode",
                                      e.currentTarget.value
                                    )
                                  }
                                />
                                {this.state.error_state.zipcode ? (
                                  <p className="error">
                                    {this.props.lang == "en"
                                      ? this.props.translations.en[
                                          "Postal Code"
                                        ]
                                      : this.props.translations.ar[
                                          "Postal Code"
                                        ]}
                                    Postal Code is required
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <label>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Additional Information"]
                      : this.props.translations.ar["Additional Information"]}
                  </label>
                  <input
                    className="form-control"
                    placeholder={
                      this.props.lang == "en"
                        ? this.props.translations.en["Additional Information"]
                        : this.props.translations.ar["Additional Information"]
                    }
                    value={this.state.additional_information}
                    onChange={(e) =>
                      this.setDataVariable(
                        "additional_information",
                        e.currentTarget.value
                      )
                    }
                  />
                </div>

                <div className="box_order_form">
                  <div className="head">
                    <div className="title">
                      <h3>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Payment Method"]
                          : this.props.translations.ar["Payment Method"]}{" "}
                        <span className="required">*</span>
                      </h3>
                      {this.state.error_state.payment_method ? (
                        <p className="error">
                          {this.props.lang == "en"
                            ? this.props.translations.en[
                                "Payment Method is required"
                              ]
                            : this.props.translations.ar[
                                "Payment Method is required"
                              ]}
                          Payment Method is required
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="main">
                    <div className="payment_select">
                      <label className="container_radio">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Credit Card"]
                          : this.props.translations.ar["Credit Card"]}
                        <input
                          type="radio"
                          value="credit_card"
                          name="payment_method"
                          onClick={(e) => {
                            this.setDataVariable(
                              "payment_method",
                              e.currentTarget.value
                            );
                          }}
                        />
                        <span className="checkmark"></span>
                        <i
                          className="icon_creditcard"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </label>
                    </div>

                    <div className="payment_select">
                      <label className="container_radio">
                        {this.props.lang == "en"
                          ? this.props.translations.en["KNET"]
                          : this.props.translations.ar["KNET"]}
                        KNET
                        <input
                          type="radio"
                          value="knet"
                          name="payment_method"
                          onClick={(e) => {
                            this.setDataVariable(
                              "payment_method",
                              e.currentTarget.value
                            );
                          }}
                        />
                        <span className="checkmark"></span>
                        <i
                          className="icon_creditcard"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </label>
                    </div>

                    {/* <div className="payment_select" id="paypal"> */}
                    <div className="payment_select">
                      <label className="container_radio">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Pay with Paypal"]
                          : this.props.translations.ar["Pay with Paypal"]}
                        <input
                          type="radio"
                          value="paypal"
                          name="payment_method"
                          onClick={(e) => {
                            this.setDataVariable(
                              "payment_method",
                              e.currentTarget.value
                            );
                          }}
                        />
                        <span className="checkmark"></span>
                        <i
                          className="icon_wallet"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </label>
                    </div>
                    <div className="payment_select">
                      <label className="container_radio">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Pay with Cash"]
                          : this.props.translations.ar["Pay with Cash"]}
                        <input
                          type="radio"
                          value="cod"
                          name="payment_method"
                          onClick={(e) => {
                            this.setDataVariable(
                              "payment_method",
                              e.currentTarget.value
                            );
                          }}
                        />
                        <span className="checkmark"></span>
                        <i
                          className="icon_wallet"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4" id="sidebar_fixed">
                <div className="box_order">
                  <div className="head">
                    <h3>
                      {this.props.lang == "en"
                        ? this.props.translations.en["Order Summary"]
                        : this.props.translations.ar["Order Summary"]}
                    </h3>
                    {/* <div>{this.props.lang == "en" ? this.props.translations.en["Address Details"] : this.props.translations.ar["Address Details"]}Pizzeria da Alfredo</div> */}
                  </div>

                  <div className="main">
                    {this.state.country !== null &&
                    this.state.country !== undefined ? (
                      this.state.country.value === "+965" ||
                      this.state.country.value == "965" ? (
                        <ul>
                          {this.state.selectedDate ? (
                            <li>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Date"]
                                : this.props.translations.ar["Date"]}
                              <span>{this.state.deliver_date}</span>
                            </li>
                          ) : null}
                          {this.state.selected_slot ? (
                            <li>
                              {this.props.lang == "en"
                                ? this.props.translations.en["Hour"]
                                : this.props.translations.ar["Hour"]}
                              <span>{this.state.deliver_time}</span>
                            </li>
                          ) : null}
                          <li>
                            {this.props.lang == "en"
                              ? this.props.translations.en["Type"]
                              : this.props.translations.ar["Type"]}
                            <span>{this.state.delivery_type}</span>
                          </li>
                        </ul>
                      ) : null
                    ) : null}
                    <hr />
                    <ul className="clearfix">
                      {/* <li><a href="#0">1x Enchiladas</a><span>$11</span></li>
                                    <li><a href="#0">2x Burrito</a><span>$14</span></li>
                                    <li><a href="#0">1x Chicken</a><span>$18</span></li>
                                    <li><a href="#0">2x Corona Beer</a><span>$9</span></li>
                                    <li><a href="#0">2x Cheese Cake</a><span>$11</span></li> */}
                      {items}
                    </ul>

                    {/* {this.state.country ? this.state.country.value === "+965" || this.state.country.value == "965" ? <div className="row opt_order">
                                      <div className="col-4">
                                          <label className="container_radio">Deliver Now
                                              {this.state.delivery_type == "deliver_now" ? <input type="radio" value="deliver_now" checked name="opt_order" onClick={(e) => this.setDataVariable("delivery_type", e.currentTarget.value)}/> : 
                                                <input type="radio" value="deliver_now" name="opt_order" onClick={(e) => this.updateDeliveryType(e.currentTarget.value)}/> }
                                              <span className="checkmark"></span>
                                          </label>
                                      </div>
                                      <div className="col-4">
                                          <label className="container_radio">Deliver Later
                                              {this.state.delivery_type == "deliver_later" ? <input type="radio" value="deliver_later" checked name="opt_order" onClick={(e) => this.setDataVariable("delivery_type", e.currentTarget.value)}/> : 
                                                <input type="radio" value="deliver_later" name="opt_order" onClick={(e) => this.updateDeliveryType(e.currentTarget.value)}/> }
                                              <span className="checkmark"></span>
                                          </label>
                                      </div>
                                      <div className="col-4">
                                          <label className="container_radio">Take away
                                              {this.state.delivery_type == "takeaway" ? <input type="radio" value="takeaway" name="opt_order" onClick={(e) => this.setDataVariable("delivery_type", e.currentTarget.value)}/>  :
                                              <input type="radio" value="takeaway" name="opt_order" onClick={(e) => this.updateDeliveryType(e.currentTarget.value)}/> }
                                              <span className="checkmark"></span>
                                          </label>
                                      </div>  
                                  </div>
                                : null : null } */}

                    <div className="form-group">
                      <label>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Have Coupon Code ?"]
                          : this.props.translations.ar["Have Coupon Code ?"]}
                      </label>
                      <input
                        className="form-control"
                        placeholder={
                          this.props.lang == "en"
                            ? this.props.translations.en["Enter Coupon Code"]
                            : this.props.translations.ar["Enter Coupon Code"]
                        }
                        value={this.state.coupon}
                        onChange={(e) => {
                          this.setDataVariable("coupon", e.currentTarget.value);
                        }}
                      />
                      <button
                        className="form-control"
                        onClick={() => {
                          this.checkCoupon();
                        }}
                      >
                        {this.props.lang == "en"
                          ? this.props.translations.en["Go"]
                          : this.props.translations.ar["Go"]}
                      </button>
                      <p style={{ color: "red" }}>
                        {this.state.coupon_err !== null &&
                        this.state.coupon_err !== undefined
                          ? this.state.coupon_err
                          : null}
                      </p>
                    </div>

                    <ul className="clearfix">
                      <li>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Subtotal"]
                          : this.props.translations.ar["Subtotal"]}
                        <span>
                          {this.props.currency.base_currency}{" "}
                          {parseFloat(
                            this.props.cart.calculated_total *
                              this.props.multiplier.multiplier
                          ).toFixed(3)}
                        </span>
                      </li>
                      {this.state.delivery_type === "deliver_later" ||
                      this.state.delivery_type == "deliver_now" ? (
                        <li>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Delivery Fee"]
                            : this.props.translations.ar["Delivery Fee"]}{" "}
                          <span>
                            {this.props.currency.base_currency}{" "}
                            {parseFloat(
                              this.state.delivery_charges *
                                this.props.multiplier.multiplier
                            ).toFixed(3)}
                          </span>
                        </li>
                      ) : null}
                      {this.state.discount > 0 ? (
                        <li>
                          {this.props.lang == "en"
                            ? this.props.translations.en["Discount"]
                            : this.props.translations.ar["Discount"]}
                          <span>
                            {this.props.currency.base_currency}{" "}
                            {parseFloat(
                              this.state.discount *
                                this.props.multiplier.multiplier
                            ).toFixed(3)}
                          </span>
                        </li>
                      ) : null}
                      <li className="total">
                        {this.props.lang == "en"
                          ? this.props.translations.en["Total"]
                          : this.props.translations.ar["Total"]}
                        <span>
                          {this.props.currency.base_currency}{" "}
                          {(
                            (parseFloat(this.props.cart.calculated_total) +
                              parseFloat(this.state.delivery_charges) -
                              parseFloat(this.state.discount)) *
                            this.props.multiplier.multiplier
                          ).toFixed(3)}
                        </span>
                      </li>
                      {/* <li className="total">Total<span>KWD {parseFloat(this.props.cart.calculated_total).toFixed(3)}</span></li>
                                    <li className="total">Total<span>KWD {parseFloat(this.state.delivery_charges).toFixed(3)}</span></li>
                                    <li className="total">Total<span>KWD { parseFloat(this.state.discount).toFixed(3)}</span></li> */}
                    </ul>
                    <a
                      onClick={() => this.orderPlacement()}
                      className="btn_1 gradient full-width mb_5"
                    >
                      {this.props.lang == "en"
                        ? this.props.translations.en["Order Now"]
                        : this.props.translations.ar["Order Now"]}
                    </a>
                    <div className="text-center">
                      <small>
                        {this.props.lang == "en"
                          ? this.props.translations.en["Or Call Us At"]
                          : this.props.translations.ar["Or Call Us At"]}{" "}
                        <strong>{this.props.shop.contact_phone}</strong>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    shop: state.shop,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    countries: state.countries.data,
    branches: state.branches.items,
    governates: state.governates.data,
    delivery_location: state.deliveryLocation.data.area,
    delivery_location_governate: state.deliveryLocation.data.governate,
    currency: state.currency,
    multiplier: state.multiplier,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    resetCart: () => dispatch({ type: actionTypes.RESET_CART, payload: null }),
    set_deliveryLocation: (location) =>
      dispatch({
        type: actionTypes.SET_DELIVERY_LOCATION,
        payload: { location: location },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
