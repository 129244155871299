import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import { Modal } from "react-bootstrap";

import cloneDeep from "lodash/cloneDeep";
import { Carousel } from "react-responsive-carousel";

import { getSelectedProduct } from "../../../store/actions/popProduct";
import PopupSkeleton from "../PopupSkeleton/PopupSkeleton";
import "./ProductModal.css";
class ProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      product: null,
      isLoading: true,
      qty: 1,
      productUpdatedPrice: 0,
    };
  }

  componentDidMount() {
    console.log(this.props.selectedProduct.selectedProduct);
    let product = this.props.selectedProduct.selectedProduct;
    this.setState((prevProps) => {
      return {
        ...prevProps,
        product: product,
      };
    });
    console.log("this is state prouct", this.state);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedProduct !== this.props.selectedProduct) {
      let product = this.props.selectedProduct.selectedProduct;
      this.setState((prevProps) => {
        return {
          ...prevProps,
          product: this.props.selectedProduct.selectedProduct,
          productUpdatedPrice:
            parseFloat(product.price) > parseFloat(product.discount) &&
            parseFloat(product.discount) != 0 &&
            parseFloat(product.discount) !== undefined &&
            parseFloat(product.discount) !== null
              ? parseFloat(product.discount)
              : parseFloat(product.price),
        };
      });
    }
  }

  componentWillUnmount() {}

  updateCheckBoxes = () => {
    this.props.selectedProduct.selectedProduct.addons.map((addon, ai) => {
      let items = this.getCheckedBoxes(`checkboxof-${ai}`);
      console.log(items, "getCheckboxes items");

      if (addon.minimum >= 1 && addon.maximum > 1) {
        let checkboxes = document.getElementsByName(`checkboxof-${ai}`);
        if (items.length == addon.maximum) {
          for (var i = 0; i < checkboxes.length; i++) {
            if (items.includes(checkboxes[i].getAttribute("id"))) {
              console.log("not disabled", checkboxes[i].getAttribute("id"));
            } else {
              checkboxes[i].disabled = true;
            }
          }
        } else {
          for (var i = 0; i < checkboxes.length; i++) {
            if (items.includes(checkboxes[i].getAttribute("id"))) {
              console.log("not disabled", checkboxes[i].getAttribute("id"));
            } else {
              checkboxes[i].disabled = false;
            }
          }
        }

        // var checkboxesChecked = [];
        // // loop over them all
        // for (var i=0; i<checkboxes.length; i++) {
        //     // And stick the checked ones onto an array...
        //     if (checkboxes[i].checked) {
        //         checkboxesChecked.push(checkboxes[i]);
        //     }
        // }

        // if(checkboxesChecked.length == addon.maximum) {
        //     console.log("maximum addons selected");
        //     for(var c= 0; c < checkboxes.length; c++) {
        //         if(checkboxes[c] == checkboxesChecked[0]) {
        //             console.log(true, checkboxes[c]);

        //         } else {
        //             console.log(false, checkboxes[c]);
        //             // checkboxes[c].disabled = true;
        //         }
        //     }

        // }
      }
    });
  };

  canAddProduct = () => {
    let addonPossibilites = this.props.selectedProduct.selectedProduct.addons.map(
      (addon, ai) => {
        let items = this.getCheckedBoxes(`checkboxof-${ai}`);
        console.log(items, "getCheckboxes items");
        let newAddonItems = [];
        let prevAddon = cloneDeep(addon);
        items.map((item) => {
          let addonIt = addon.items.filter((addonItem, ii) => {
            // if(item === addonItem.id) {
            // console.log(addonItem);
            // console.log(item);
            return ii == item;
            // }
          });
          // console.log(addonIt, "these are found addon items");

          newAddonItems = [...newAddonItems, ...addonIt];
          // let addonItm = addon.items.filter(addonItem => {
          //     return addonItem.id === item;
          // });
          // console.log(addonItm, "these is addonItm");
          // if(addonItm.length > 0) {
          //     newAddonItems.push(addonItm[0]);
          // }
        });

        prevAddon.items = newAddonItems;

        if (
          prevAddon.items.length >= prevAddon.minimum &&
          prevAddon.items.length <= prevAddon.maximum
        ) {
          return true;
        } else {
          return false;
        }
      }
    );
    return addonPossibilites;
  };

  calculateAddonsPrice = (addons) => {
    let total = 0;
    if (addons !== undefined && addons !== null) {
      addons.map((addon, index) => {
        if (
          addon.items.length >= addon.minimum &&
          addon.items.length <= addon.maximum
        ) {
          addon.items.map((item, i) => {
            total += parseFloat(item.price);
          });
        }
      });
      console.log(total, "calculated price");
      return total;
    }
  };

  getCheckedBoxes(chkboxName) {
    var checkboxes = document.getElementsByName(chkboxName);
    var checkboxesChecked = [];
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i].getAttribute("id"));
      }
    }
    // Return the array if it is non-empty, or null
    return checkboxesChecked.length > 0 ? checkboxesChecked : [];
  }

  getAddons = () => {
    // console.log(value);
    let retValue = true;
    let newAddons = this.props.selectedProduct.selectedProduct.addons.map(
      (addon, ai) => {
        let items = this.getCheckedBoxes(`checkboxof-${ai}`);

        console.log(items, "getCheckboxes items");
        let newAddonItems = [];
        let prevAddon = cloneDeep(addon);
        items.map((item) => {
          let addonIt = addon.items.filter((addonItem, ii) => {
            // if(item === addonItem.id) {
            // console.log(addonItem);
            // console.log(item);
            return ii == item;
            // }
          });
          // console.log(addonIt, "these are found addon items");

          newAddonItems = [...newAddonItems, ...addonIt];
          // let addonItm = addon.items.filter(addonItem => {
          //     return addonItem.id === item;
          // });
          // console.log(addonItm, "these is addonItm");
          // if(addonItm.length > 0) {
          //     newAddonItems.push(addonItm[0]);
          // }
        });
        prevAddon.items = newAddonItems;
        return prevAddon;
      }
    );
    console.log(newAddons);
    return newAddons;
  };

  updateProductPrice = () => {
    // let addons =
    this.updateCheckBoxes();
    let addonPossibilites = this.canAddProduct();
    let canUpdate = true;
    addonPossibilites.map((pos) => {
      if (pos) {
        canUpdate = true;
      } else {
        canUpdate = false;
      }
    });
    if (canUpdate) {
      let addons = this.getAddons();
      if (addons) {
        console.log(addons, "these are selected addons");
        if (addons !== null && addons !== undefined) {
          let newAddonsPrice = 0;
          if (addons.length > 0) {
            newAddonsPrice = this.calculateAddonsPrice(addons);
          }
          console.log(newAddonsPrice);
          let product = this.props.selectedProduct.selectedProduct;
          // let newProductPrice = parseFloat(this.props.selectedProduct.selectedProduct.price) + parseFloat(newAddonsPrice);
          let newProductPrice =
            parseFloat(product.price) > parseFloat(product.discount) &&
            parseFloat(product.discount) != 0 &&
            parseFloat(product.discount) !== undefined &&
            parseFloat(product.discount) !== null
              ? parseFloat(product.discount) + parseFloat(newAddonsPrice)
              : parseFloat(product.price) + parseFloat(newAddonsPrice);
          console.log(newProductPrice, "new Product Price");
          this.setState((prevState) => {
            return {
              ...prevState,
              productUpdatedPrice: newProductPrice,
            };
          });
        }
      } else {
        console.log("no addons");
      }
    } else {
      // alert("Please select all required addons to see product price");
      let addons = this.getAddons();

      let newAddonsPrice = 0;
      if (addons !== undefined || addons !== null) {
        newAddonsPrice = this.calculateAddonsPrice(addons);
      }
      this.setState((prevState) => {
        let product = this.props.selectedProduct.selectedProduct;
        return {
          ...prevState,
          productUpdatedPrice:
            parseFloat(product.price) > parseFloat(product.discount) &&
            parseFloat(product.discount) != 0 &&
            parseFloat(product.discount) !== undefined &&
            parseFloat(product.discount) !== null
              ? parseFloat(product.discount)
              : parseFloat(product.price),
        };
      });
    }
  };

  decreaseQtyOfProduct = () => {
    let addons = this.getAddons();
    console.log(addons, "these are selected addons");
    let newAddonsPrice = this.calculateAddonsPrice(addons);
    console.log(newAddonsPrice);

    let prod = cloneDeep(this.props.selectedProduct.selectedProduct);
    let images = cloneDeep(this.props.selectedProduct.selectedProduct.images);
    let product = {
      ...prod,
      addons: [...addons],
      images: [...images],
    };

    console.log("this is product for decreasing", product);
    let currentProduct = cloneDeep(product);
    let pageqty = this.state.qty;
    // let pageqty = document.getElementById(-qty").innerHTML;
    console.log(pageqty, "this is page qty");
    let quantity = parseInt(pageqty);
    let qunty = 0;
    if (quantity !== undefined && quantity !== null) {
      if (quantity <= 0) {
        qunty = 0;
      } else {
        qunty = parseInt(quantity);
        if (isNaN(qunty)) {
          qunty = 0;
        } else {
          qunty = qunty - 1;
        }
      }
    } else {
      qunty = 0;
    }

    currentProduct["qty"] = parseInt(qunty);
    currentProduct["calculated_price"] =
      (parseFloat(product.price) > parseFloat(product.discount) &&
      parseFloat(product.discount) != 0 &&
      parseFloat(product.discount) !== undefined &&
      parseFloat(product.discount) !== null
        ? parseFloat(product.discount)
        : parseFloat(product.price)) + parseFloat(this.calculateAddonsPrice);
    currentProduct["calculated_total"] =
      parseInt(currentProduct.qty) *
      parseFloat(currentProduct.calculated_price);

    if (currentProduct["qty"] > 0) {
      this.props.addItemToCart(currentProduct);
    } else {
      this.props.removeItemFromCart(currentProduct);
    }
  };

  increaseQtyOfProduct = () => {
    console.log("increasing");
    // export default ProductPage;
    let addons = this.getAddons();
    console.log(addons, "these are selected addons");
    if (addons !== null && addons !== undefined) {
      let newAddonsPrice = this.calculateAddonsPrice(addons);

      console.log(newAddonsPrice);
      // let newProductPrice = parseFloat(this.state.product.product.price) + parseFloat(newAddonsPrice);
      // this.setState(prevState => {
      //     return {
      //         ...prevState,
      //         productUpdatedPrice: newProductPrice
      //     }
      // })
      let prod = cloneDeep(this.props.selectedProduct.selectedProduct);
      let images = cloneDeep(this.props.selectedProduct.selectedProduct.images);
      let product = {
        ...prod,
        addons: [...addons],
        images: images,
      };

      console.log("this is product for increasing", product);
      let currentProduct = cloneDeep(product);
      // currentProduct["addons"] = [];
      // let qty = this.state.qty - 1;
      let pageqty = this.state.qty;

      console.log(pageqty, "this is page qty");
      let qunty = parseInt(pageqty) + 1;
      //     let quantity = parseInt(pageqty);
      //     let qunty = 0;
      //    if(quantity !== undefined && quantity !== null) {
      //         if(quantity <= 0) {
      //             qunty = 0;

      //         } else {
      //             qunty = parseInt(quantity);
      //             if(isNaN(qunty)) {
      //                 qunty = 0;
      //             } else {
      //                 qunty = qunty + 1;
      //             }
      //         }
      //    } else {
      //        qunty = 0;
      //    }
      currentProduct["qty"] = parseInt(qunty);
      currentProduct["calculated_price"] =
        (parseFloat(product.price) > parseFloat(product.discount) &&
        parseFloat(product.discount) != 0 &&
        parseFloat(product.discount) !== undefined &&
        parseFloat(product.discount) !== null
          ? parseFloat(product.discount)
          : parseFloat(product.price)) + parseFloat(this.calculateAddonsPrice);
      currentProduct["calculated_total"] =
        parseInt(currentProduct.qty) *
        parseFloat(currentProduct.calculated_price);
      console.log(currentProduct);
      if (currentProduct["qty"] > 0) {
        this.props.addItemToCart(currentProduct);
      } else {
        this.props.removeItemFromCart(currentProduct);
      }

      // console.log("this is modified data", currentProduct);
    }
  };

  onChangeQtyOfProduct = (qua) => {
    console.log(qua);

    let quantity = parseInt(qua);

    let qunty = 0;
    if (quantity !== undefined && quantity !== null) {
      if (quantity <= 0) {
        qunty = 0;
      } else {
        qunty = parseInt(quantity);
        if (isNaN(qunty)) {
          qunty = 0;
        }
      }
    } else {
      qunty = 0;
    }

    this.setState(
      (prevState) => {
        return {
          ...prevState,
          qty: qunty,
        };
      },
      () => {
        let currentProduct = cloneDeep(this.props.product);

        // this.props.addItemToCart(currentProduct);
        if (this.state.qty > 0) {
          currentProduct["addons"] = [];
          // let qty = this.state.qty - 1;

          currentProduct["qty"] = parseInt(this.state.qty);
          // currentProduct["calculated_price"] = this.props.product.price > this.props.product.discount && this.props.product.discount != 0 && this.props.product.discount !== undefined && this.props.product.discount !== null ? this.props.product.discount : this.props.product.price;
          // currentProduct["calculated_total"] = currentProduct.qty * currentProduct.calculated_price;
          currentProduct["calculated_price"] =
            parseFloat(this.props.product.price) >
              parseFloat(this.props.product.discount) &&
            parseFloat(this.props.product.discount) != 0 &&
            parseFloat(this.props.product.discount) !== undefined &&
            parseFloat(this.props.product.discount) !== null
              ? parseFloat(this.props.product.discount)
              : parseFloat(this.props.product.price);
          currentProduct["calculated_total"] =
            parseInt(currentProduct.qty) *
            parseFloat(currentProduct.calculated_price);
          this.props.addItemToCart(currentProduct);
        } else {
          this.props.removeItemFromCart(currentProduct);
        }
      }
    );
  };

  addToCart = (e) => {
    console.log(e);
    let qty = this.state.qty;
    console.log(qty);
    let productUpdatedPrice = this.state.productUpdatedPrice;

    console.log(productUpdatedPrice);
    // let addons =
    let addonPossibilites = this.canAddProduct();
    let canUpdate = true;
    addonPossibilites.map((pos) => {
      if (pos) {
        // canUpdate = true;
      } else {
        canUpdate = false;
      }
    });
    if (canUpdate) {
      if (this.state.qty > 0) {
        let addons = this.getAddons();
        console.log(addons, "these are selected addons");
        let newAddonsPrice = this.calculateAddonsPrice(addons);
        console.log(newAddonsPrice);

        let prod = cloneDeep(this.props.selectedProduct.selectedProduct);
        let images = cloneDeep(
          this.props.selectedProduct.selectedProduct.images
        );
        let product = {
          ...prod,
          addons: [...addons],
          images: images,
        };

        console.log("this is product for decreasing", product);
        let currentProduct = cloneDeep(product);
        let pageqty = this.state.qty;
        // let pageqty = document.getElementById(-qty").innerHTML;

        currentProduct["qty"] = parseInt(pageqty);
        currentProduct["calculated_price"] = productUpdatedPrice;
        currentProduct["calculated_total"] =
          parseInt(currentProduct.qty) *
          parseFloat(currentProduct.calculated_price);
        // currentProduct["calculated_total"] = parseInt(qty) * parseFloat(currentProduct.calculated_price);

        console.log("this is the current product data", currentProduct);

        this.props.addItemToCart(currentProduct);

        this.setState(
          (prevState) => {
            let stateClone = cloneDeep(prevState);
            let product = stateClone.product.product;
            return {
              ...prevState,
              isLoading: false,
              qty: 1,
              addtext: true,
              // status: "ADDED"
              // productUpdatedPrice: parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price)
            };
          },
          () => {
            this.props.setSelectedProduct(null);
            this.props.setShowProductPopUp(false);
          }
        );
      }
    } else {
      alert("Please select all required addons to see product price");
    }
  };

  decreaseQty = () => {
    this.setState(
      (prevState) => {
        let qty = null;
        if (prevState.qty <= 1) {
          qty = 1;
        } else {
          qty = prevState.qty - 1;
        }
        console.log(qty);
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,
          qty: qty,
        };
      },
      () => {
        this.updateProductPrice();
      }
    );
  };

  increaseQty = () => {
    this.setState(
      (prevState) => {
        console.log(prevState.qty);
        let qty = null;
        if (prevState.qty < 0) {
          qty = 0;
        } else {
          qty = prevState.qty + 1;
        }
        console.log(qty);
        let currentState = cloneDeep(prevState);
        return {
          ...currentState,
          qty: qty,
        };
      },
      () => {
        this.updateProductPrice();
      }
    );
  };

  // openModal = () => {
  //     let modal = document.getElementById("myModal");
  //     modal.style.display = "block";
  // }

  // closeModal = () => {
  //     let modal = document.getElementById("myModal");
  //     modal.style.display = "none";

  //     this.props.history.push("/");

  // }

  handleClosingPopUp = () => {
    this.props.setShowProductPopUp(false);
    this.props.setSelectedProduct(null);
  };

  handleQuantity = (quan, type) => {
    if (type == "actual") {
      let quantity = parseInt(quan);

      let qunty = 0;
      if (quantity !== undefined && quantity !== null) {
        if (quantity <= 0) {
          qunty = 0;
        } else {
          qunty = parseInt(quantity);
          if (isNaN(qunty)) {
            qunty = 0;
          }
        }
      } else {
        qunty = 0;
      }

      this.setState((prevState) => {
        // quantity: parseInt(quan);

        if (isNaN(qunty)) {
          return {
            ...prevState,
            quantity: 1,
          };
        } else {
          return {
            ...prevState,
            quantity: parseInt(qunty),
          };
        }
      });
    } else if (type == "plus") {
      // let updatedQuantity =
      this.setState((prevState) => {
        return {
          ...prevState,
          quantity: parseInt(prevState.quantity) + 1,
        };
      });
    } else if (type == "minus") {
      // let updatedQuantity =
      this.setState((prevState) => {
        if (prevState.quantity <= 1) {
          return {
            ...prevState,
            quantity: 1,
          };
        } else {
          return {
            ...prevState,
            quantity: parseInt(prevState.quantity) - 1,
          };
        }
      });
    }
  };

  render() {
    let addons = null;
    // console.log(this.props.selectedProduct.selectedProduct.images);
    let images = [];

    // let translations = JSON.parse(this.props.selectedProduct.selectedProduct.translations);
    if (
      this.props.selectedProduct.selectedProduct !== null &&
      this.props.selectedProduct.selectedProduct !== undefined
    ) {
      if (this.props.selectedProduct.selectedProduct.images.length > 0) {
        images = this.props.selectedProduct.selectedProduct.images.map(
          (img, index) => {
            return (
              <div>
                <img class="img-responsive" src={img.image} />
              </div>
            );
          }
        );
      }
      addons = this.props.selectedProduct.selectedProduct.addons.map(
        (addon, aindex) => {
          let items = addon.items.map((item, index) => {
            if (
              (addon.minimum == 0 || addon.minimum == 1) &&
              addon.maximum == 1
            ) {
              console.log(item.translations.en.title);
              return (
                <li key={index}>
                  <label className="container_radio">
                    {this.props.lang == "en"
                      ? item.translations.en.title
                      : item.translations.ar.title}
                    <span>+ {item.price}</span>
                    <input
                      id={index}
                      type="radio"
                      value={index}
                      name={`checkboxof-${aindex}`}
                      addon={`${aindex}`}
                      onClick={(e) => this.updateProductPrice()}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              );
            } else if (addon.minimum >= 0 && addon.maximum > 1) {
              console.log(item.translations.en.title);

              return (
                <li key={index}>
                  <label className="container_check">
                    {this.props.lang == "en"
                      ? item.translations.en.title
                      : item.translations.ar.title}
                    <span>+ {item.price}</span>
                    <input
                      type="checkbox"
                      addon={aindex}
                      id={index}
                      value={index}
                      name={`checkboxof-${aindex}`}
                      onClick={(e) => this.updateProductPrice()}
                    />

                    <span className="checkmark"></span>
                  </label>
                </li>
              );
            }
          });

          return addon.items.length > 0 ? (
            <div key={aindex}>
              <h5>
                {this.props.lang == "en"
                  ? addon.translations.en.title
                  : addon.translations.ar.title}
              </h5>
              <p>
                quantity : {addon.minimum} (minimum) - {addon.maximum} (maximum){" "}
              </p>
              <ul class="clearfix">{items}</ul>
            </div>
          ) : null;
        }
      );
    }

    return (
      <Modal
        id="modal-dialog"
        show={this.props.selectedProduct.showProductPopUp}
        onHide={() => this.handleClosingPopUp()}
        backdrop="static"
        keyboard={false}
      >
        {this.props.selectedProduct.selectedProduct !== null &&
        this.props.selectedProduct.selectedProduct !== undefined ? (
          <>
            {/* <Modal.Header closeButton className="small-dialog-header">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.lang == "en"
                  ? this.props.selectedProduct.selectedProduct.translations.en
                      .title
                  : this.props.selectedProduct.selectedProduct.translations.ar
                      .title}{" "}
                - {this.state.productUpdatedPrice}
              </Modal.Title>
            </Modal.Header> */}

            <Modal.Body closeButton className="content">
              {this.props.selectedProduct.selectedProduct !== null &&
              this.props.selectedProduct.selectedProduct !== undefined ? (
                <div
                  className="Header"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 0.8,
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="popupHeaderCustom"
                      style={{ fontSize: "18px" }}
                    >
                      <b className="popupHeaderCustom">
                        {this.props.lang == "en"
                          ? this.props.selectedProduct.selectedProduct
                              .translations.en.title
                          : this.props.selectedProduct.selectedProduct
                              .translations.ar.title}{" "}
                        - {this.state.productUpdatedPrice}
                      </b>
                    </p>
                    <p>
                      {this.props.lang == "en"
                        ? this.props.selectedProduct.selectedProduct
                            .translations.en.description
                        : this.props.selectedProduct.selectedProduct
                            .translations.ar.description}
                    </p>
                  </div>
                  <div
                    className="closer"
                    style={{
                      display: "flex",
                      flex: 0.2,
                      justifyContent: "flex-end",
                      boxSizing: "border-box",
                      // alignItems: "center",
                    }}
                  >
                    {/* <span
                      className="fa fa-times"
                      onClick={this.handleClosingPopUp}
                    ></span> */}
                    <img
                      alt="close Product"
                      src="img/cross.png"
                      style={{ width: "25px", height: "25px" }}
                      onClick={this.handleClosingPopUp}
                    />
                  </div>
                </div>
              ) : null}
              {this.props.selectedProduct.selectedProduct !== null &&
              this.props.selectedProduct.selectedProduct !== undefined ? (
                <Carousel>{images}</Carousel>
              ) : null}

              <h5>Quantity</h5>
              <div className="numbers-row">
                {/* <input type="text" value={this.state.quantity} id="qty_1" minimum="1" className="qty2 form-control" name="quantity" onChange={(e) => this.handleQuantity(e.currentTarget.value, "actual")}/> */}
                <input
                  className="form-control"
                  value={this.state.qty}
                  name="qty"
                  onChange={(e) =>
                    this.handleQuantity(e.target.value, "actual")
                  }
                />
                <div
                  className="inc button_inc"
                  onClick={() => this.increaseQty(1, "plus")}
                >
                  +
                </div>
                <div
                  className="dec button_inc"
                  onClick={() => this.decreaseQty(1, "minus")}
                >
                  -
                </div>
              </div>
              {addons}

              {/* <h5>Extra Ingredients</h5>
              <ul className="clearfix">
                  <li>
                      <label className="container_check">Extra Tomato<span>+ $4.30</span>
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                      </label>
                  </li>
                  <li>
                      <label className="container_check">Extra Peppers<span>+ $2.50</span>
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                      </label>
                  </li>
                  <li>
                      <label className="container_check">Extra Ham<span>+ $4.30</span>
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                      </label>
                  </li>
              </ul>
               */}
            </Modal.Body>
            <Modal.Footer className="footer">
              <div className="row small-gutters">
                <div className="col-md-4">
                  <button
                    type="reset"
                    className="btn_1 outline full-width mb-mobile"
                    onClick={() => this.handleClosingPopUp()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-md-8">
                  <button
                    type="reset"
                    className="btn_1 full-width"
                    onClick={(e) => {
                      this.addToCart(e);
                    }}
                  >
                    Add to cart
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </>
        ) : (
          <PopupSkeleton></PopupSkeleton>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    categories: state.categories.items,
    selectedProduct: state.selectedProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    setQtyIndicator: (productId) =>
      dispatch({
        type: actionTypes.SET_QUANTITY_INDICATOR_ID,
        payload: { productId: productId },
      }),
    addItemToCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (product) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
