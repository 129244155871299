import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL, SID } from "../../config";
import { connect } from "react-redux";
class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    console.log(this.props);
    // let mBanner = document.getElementsByClassName("mBanner mobile");
    // mBanner[0].style.visibility = 'hidden';
    // mBanner[0].style.height = "60px";

    fetch(`${BASE_API_URL}/getpagecontent/${SID}/about_us`, {
      method: "GET",
    })
      .then((resp) => {
        return resp.json();
      })
      .then((resjson) => {
        console.log(resjson);
        // let trans = JSON.parse(resjson.translations);
        // resjson.translations = trans;
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              data: resjson,
            };
          },
          () => {
            this.setState((currentStae) => {
              return {
                ...currentStae,
                loading: false,
              };
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    let trans = {
      en: {
        title: null,
        description: null,
      },
      ar: {
        title: null,
        description: null,
      },
    };
    if (!this.state.loading) {
      trans = JSON.parse(this.state.data.translations);
    }
    return this.state.loading ? (
      <>
        <div
          className="skelPopupBody animate"
          style={{ width: "80%", boxSizing: "border-box", padding: "0px 15px" }}
        ></div>
        <div
          className="skelPopupBody animate"
          style={{ width: "60%", boxSizing: "border-box", padding: "0px 15px" }}
        ></div>
        <div
          className="skelPopupBody animate"
          style={{ width: "50%", boxSizing: "border-box", padding: "0px 15px" }}
        ></div>
        <div
          className="skelPopupBody animate"
          style={{ width: "40%", boxSizing: "border-box", padding: "0px 15px" }}
        ></div>
      </>
    ) : (
      <>
        <section className="checkout">
          <div className="container">
            <div className="row" style={{ margin: "5px" }}>
              <div className="checkoutHeader">
                <h3>
                  {this.props.lang == "en" ? trans.en.title : trans.ar.title}
                </h3>
              </div>

              <div className="checkoutFields">
                <div
                  className="row"
                  style={{ margin: "0px" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      this.props.lang == "en"
                        ? trans.en.description
                        : trans.ar.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
  };
};

export default connect(mapStateToProps, null)(AboutUs);
