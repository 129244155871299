import React, { Component } from 'react';
import Select from 'react-select';
import governatesReducer from '../../../../store/reducers/governatesReducer';
import { secureStorage } from '../../../../locStore';

import { connect } from 'react-redux';

import * as actionTypes from '../../../../store/actions/actions';


class DeliveryOption extends Component {
    

    constructor(props) {
        super(props);
        console.log(this.props.delivery_location, "in constructor");
        this.state = {
            areaId : null
        }
    }


    componentDidMount() {
        // console.log(this.props.delivery_location.area.id, "this is area id in mount");
        if(this.props.delivery_location !== null && this.props.delivery_location !== undefined) {

        
            this.setState((prevState) => {
                return {
                    areaId: {
                    
                        
                                label: this.props.lang == "en" ? this.props.delivery_location.title : this.props.delivery_location.title_ar,
                                value: this.props.delivery_location.id
                            
                    }
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        console.log("update is calling")
        
        if(this.props.delivery_location !== prevProps.delivery_location) {
            console.log("state is set");
            this.setState((prevState) => {
                return {
                    ...prevState,
                    areaId: {
                
                       
                        label: this.props.lang == "en" ? this.props.delivery_location.title : this.props.delivery_location.title_ar,
                        value: this.props.delivery_location.id
                    
                    }
                }
            })
        }
    }

    selectedLocation = (areaId) => {
        let governate = null;
        let area = null;
        this.props.governates.map(gov => {
            let isAreaExist = gov.list.filter(area => {
                return area.id === areaId;
            });
            
            if(Array.isArray(isAreaExist) && isAreaExist.length > 0) {
                governate = gov;
                area = isAreaExist[0];

            }
        })

        console.log(governate);
        console.log(area);
        secureStorage.setItem("delivery_location", {governate: governate, area: area})
        this.props.set_deliveryLocation({governate: governate, area: area})
        this.setState(prevState => {
            return {
                ...prevState,
                areaId: area.id
            }
        })
        // this.props
        // console.log(areaId);
    }
    
    render() {

        let options = this.props.governates.map((governate, index) => {
            let item = {
                label: this.props.lang == 'en' ? governate.title : governate.title_ar
            }
            let options = governate.list.map((area, index) => {
                let option = {
                    label: this.props.lang == 'en' ? area.title : area.title_ar,
                    value: area.id
                }
                return option;
            });

            item["options"] = options;
            return item;
        });


        // console.log(this.state.areaId, "in render");

        return (
            <>
                <div className="bannerLeft">
                                <h1 className="welcomeText">{this.props.lang == "en" ? this.props.translations.en["Welcome to Layers Cake Boutique"] : this.props.translations.ar["Welcome to Layers Cake Boutique"]} <span className="shopStatus">
                                    {this.props.lang == "en" ? this.props.translations.en["Open"] : this.props.translations.ar["Open"]}
                                </span></h1>
                                {/* <h1 className="welcomeText">Welcome to Layers Cake Boutique<span className="shopStatus">Open</span></h1> */}
                                    <div className="deliveryType">
                                        <h2>{this.props.lang == "en" ? this.props.translations.en["Select Your Delivery Option"] : this.props.translations.ar["Select Your Delivery Option"]}</h2>
                                        {/* <h2>Select Your Delivery Option</h2> */}
                                        <div className="nav nav-tabs deliveryTabs" id="nav-tab" role="tablist">
                                            <a className="nav-item nav-link active" id="deliveryopt-tab" data-toggle="tab" href="#deliveryopt" role="tab" aria-controls="deliveryopt" aria-selected="true">{this.props.lang == "en" ? this.props.translations.en["Delivery"]: this.props.translations.ar["Delivery"]}</a>
                                            {/* <a className="nav-item nav-link active" id="deliveryopt-tab" data-toggle="tab" href="#deliveryopt" role="tab" aria-controls="deliveryopt" aria-selected="true">Delivery</a> */}
                                            {/* <a className="nav-item nav-link" id="pickupopt-tab" data-toggle="tab" href="#pickupopt" role="tab" aria-controls="pickupopt" aria-selected="false">Self Pickup</a> */}
                                        </div>
                                        <div className="tab-content deliveryTabdetails" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="deliveryopt" role="tabpanel" aria-labelledby="deliveryopt-tab">
                                                <div className="loaction">
                                                    <div className="input-group">
                                                        {/* <select className="custom-select js-example-basic-single" id="inputGroupSelect04">
                                                            <option selected>Type Delivery Location Here</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select> */}

                                                        <Select onChange={(e) => this.selectedLocation(e.value)} placeholder={this.props.lang === "en" ? this.props.translations.en["Select Area"] : this.props.translations.ar["Select Area"]} className="custom-select js-example-basic-single" id="inputGroupSelect04" options={options} value={this.state.areaId}></Select>
                                                        {/* <div className="input-group-append">id
                                                            <button className="btn btnPickup" type="button"><img src="assets/img/auto.png" width="20" /> Auto Detect</button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade deliveryTabdetails" id="pickupopt" role="tabpanel" aria-labelledby="pickupopt-tab">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
            </>
        );
    }
}




const mapStateToProps = (state) => {
    return {
      translations: state.translations,
      governates: state.governates.data,
      lang: state.lang.lang,
      delivery_location: state.deliveryLocation.data.area
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        set_deliveryLocation: (location) => dispatch({type: actionTypes.SET_DELIVERY_LOCATION, payload: {location: location}})
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOption);