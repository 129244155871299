// export const SET_SHOPID = 'SET_SHOPID';
export const CHANGE_HELLO = 'CHANGE_HELLO';
// export const UPDATE_SHOP = 'UPDATE_SHOP';
export const UPDATE_SHOP = 'SET_SHOP';
// export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_BRANCHES = 'SET_BRANCHES';
export const SET_FILTER = 'SET_FILTER';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_DELIVERY_LOCATION = 'SET_DELIVERY_LOCATION';
export const SET_GOVERNATES = 'SET_GOVERNATES';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_QUANTITY_INDICATOR_ID = 'SET_QUANTITY_INDICATOR_ID';
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_PRODUCTS_LOADER = "SET_PRODUCTS_LOADER";
// Cart Actions
export const SET_CART_ON_RELOAD = 'SET_CART_ON_RELOAD';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const UPDATE_CART_ITEM  = 'UPDATE_CART_ITEM';
export const UPDATE_CART_BEFORE_ORDER = 'UPDATE_CART_BEFORE_ORDER';
export const RESET_CART = 'RESET_CART';

export const SET_CURRENCY = 'SET_CURRENCY';


export const SET_MULTIPLIER = 'SET_MULTIPLIER';

export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';



export const HANDLE_SIGNIN_MODAL = 'HANDLE_SIGN_IN_MODAL';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const HANDLE_PRODUCT_POPUP = 'HANDLE_PRODUCT_POPUP';
export const HANDLE_DELIVERY_DISCOUNT = 'HANDLE_DELIVERY_DISCOUNT';