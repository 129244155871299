import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as actionTypes from '../../../store/actions/actions';

class Features extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 0
        }
    }


    selectedCategory = (i) => {
        // let cat = null;
        // this.props.categories.map((category, i) => {
            
        // });
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedCategory: i
            }
        })
    }

    render() {
        let cats = this.props.categories.map((category, index) => {
            return (
                <li key={index} className={this.state.selectedCategory == index ? "catActive" : null} onClick={() => this.selectedCategory(index)}><a className="list-group-item list-group-item-action" href={`#section-${index}`}>{this.props.lang == "en" ? category.title : category.title_ar}</a></li>

            );
        })
        return (
            <nav className="secondary_nav sticky_horizontal">
              <div className="container">
                  <ul id="secondary_nav">
                      {cats}
                      
                  </ul>
              </div>
              <span></span>
          </nav>

            
        );
    }
}





const mapStateToProps = (state) => {
    return {
      translations: state.translations,
      governates: state.governates.data,
      lang: state.lang.lang,
      delivery_location: state.deliveryLocation.data.area,
      categories: state.categories.items
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        set_deliveryLocation: (location) => dispatch({type: actionTypes.SET_DELIVERY_LOCATION, payload: {location: location}})
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(Features);


// export default Features