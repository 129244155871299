import * as actionTypes from '../actions/actions';



const initialState = {
    data: {governate: null, area: null}
}

const deliveryLocationReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_DELIVERY_LOCATION:
            let location = action.payload.location;
            return {
                data: location
            }

    }
    return state;
}

export default deliveryLocationReducer;