import React, { Component } from "react";
import { Link } from "react-router-dom";
import CategoryProducts from "./CategoryProducts/CategoryProducts";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actions";
import CheckOutSidebar from "./CheckOutSidebar/CheckOutSidebar";
class HomeProducts extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="bg_gray">
          <div className="container margin_detail">
            <div className="row">
              <div className="col-lg-8 list_menu">
                <CategoryProducts></CategoryProducts>
              </div>

              {this.props.cart.items.length > 0 ? (
                <CheckOutSidebar></CheckOutSidebar>
              ) : null}
              {/* <CheckOutSidebar></CheckOutSidebar> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeProducts);

// export default HomeProducts;
