import * as actionTypes from '../actions/actions';
import { secureStorage } from '../../locStore';
import cloneDeep from 'lodash/cloneDeep';
const initialState = {
    en: {},
    ar: {}
}

const translationReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_TRANSLATIONS:
            // set secureStorage to redux cart
            console.log(action.payload.translations, "payload cart ");
            
            let storeClone = cloneDeep(action.payload.translations);
            console.log(storeClone, "deep clone store");
            return storeClone;
    }
    return state;
}

export default translationReducer;


