import * as actionTypes from '../actions/actions';
import { secureStorage } from '../../locStore';
import cloneDeep from 'lodash/cloneDeep';
const initialState = {
    
    calculated_total: 0,
    items: []
}



const calculateCartTotal = (items) => {
    let total = 0;
    items.map(item => {
        // if(item.addons.length <= 0) {
            console.log(item.calculated_total);
        total += parseFloat(item.calculated_total);
        // total += item.calculated_price * item.qty;
        // } else {
        //     let addons_total = 0;
        //     item.addons.map(addon => {
        //         addon.items.map()
        //     })
        // }
    });
    return total;
}

const cartReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_CART_ON_RELOAD:
            // set secureStorage to redux cart
            console.log(action.payload.cart, "payload cart ");
            
            let storeClone = cloneDeep(action.payload.cart);
            console.log(storeClone, "deep clone store");
            return storeClone;
        case actionTypes.ADD_CART_ITEM:
            let storageCart = secureStorage.getItem('cart');
            let storageClone = cloneDeep(storageCart);
            let currentItem = cloneDeep(action.payload.item);
            let index = action.payload.index;
            if(currentItem.addons.length <= 0) {

            
                let itemExistList = storageClone.items.filter((item, index) => {
                    return item.id !== currentItem.id;   
                });
                if(index === null || index === undefined) {
                    let items = [...itemExistList, currentItem]
                    storageClone["items"] = items;
                    let calculated_total = calculateCartTotal(items);
    
                    storageClone["calculated_total"] = calculated_total;
                    secureStorage.setItem("cart", storageClone);
                    console.log("added add-to-cart type item");
                    return storageClone;
                    
                } else {
                    storageClone.items[index] = currentItem;
                    
                    let calculated_total = calculateCartTotal(storageClone.items);
    
                    storageClone["calculated_total"] = calculated_total;
                    secureStorage.setItem("cart", storageClone);
                    console.log("added add-to-cart type item");
                    return storageClone;
                }

                
                
            } else {
                // let items = [...storageClone["items"], currentItem];
                // storageClone["items"] = items;
                // let calculated_total = calculateCartTotal(items);

                // storageClone["calculated_total"] = calculated_total;
                // secureStorage.setItem("cart", storageClone);
                // console.log("added add-to-cart type item");
                // return storageClone;
                if(index === null || index === undefined) {
                    let items = [...storageClone.items, currentItem]
                    storageClone["items"] = items;
                    let calculated_total = calculateCartTotal(items);
    
                    storageClone["calculated_total"] = calculated_total;
                    secureStorage.setItem("cart", storageClone);
                    console.log("added add-to-cart type item");
                    return storageClone;
                    
                } else {
                    storageClone.items[index] = currentItem;
                    
                    let calculated_total = calculateCartTotal(storageClone.items);
    
                    storageClone["calculated_total"] = calculated_total;
                    secureStorage.setItem("cart", storageClone);
                    console.log("added add-to-cart type item");
                    return storageClone;
                }

            }

          
        case actionTypes.REMOVE_CART_ITEM: 
            // remove cart item and update secureStorage
            console.log("removing cart item");
            let prod = cloneDeep(action.payload.item);
            let i = cloneDeep(action.payload.index);
            console.log(i);
            let storage_cart = secureStorage.getItem('cart');
            let storage_clone = cloneDeep(storage_cart);
            if(i !== undefined && i !== null) {
                console.log("executing expected");
                let newCartItems = storage_clone.items.filter((item, ind) => {
                    return ind !== parseInt(i);
                });
                let updated_calculated_total = calculateCartTotal(newCartItems);
                let updatedState = {
                    items : newCartItems,
                    calculated_total: updated_calculated_total
                }
                console.log(updatedState);
                secureStorage.setItem('cart', updatedState);
                return updatedState;

            } else {

                console.log("executing unexpected");
                
                let latest_list = storage_clone.items.filter(item => {
                    return item.id !== prod.id;
                })
                
                
                // let clone_remaining_items = cloneDeep(latest_list);
                // let updated_calculated_total = storage_cart.calculated_total - current_item[0].item_total_price;
                let updated_calculated_total = calculateCartTotal(latest_list);
                let updatedState = {
                    items : latest_list,
                    calculated_total: updated_calculated_total
                }
                console.log(updatedState);
                secureStorage.setItem('cart', updatedState);
                return updatedState;
            
            }
        case actionTypes.UPDATE_CART_ITEM:
            // update cart item and update secureStorage

        case actionTypes.UPDATE_CART_BEFORE_ORDER:
            let current_store_clone = cloneDeep(action.payload.cart);
            // console.log(storeClone, "deep clone store");
            return current_store_clone;

        case actionTypes.RESET_CART:
            secureStorage.setItem('cart', {items: [], calculated_total: 0});
            return {
                
                calculated_total: 0,
                items: []
            }

    }
    return state;
}

export default cartReducer;




//  //add cart item and update secureStorage
//  let item = [action.payload.item];
//  // let new_addons = [];
//  let new_addons = item.addons.map(addon => {
//      let new_addon_items = addon.addon_items.map(addon_item => {
//          return {
//              ...addon_item
//          }
//      })
//      let new_addon = {
//          ...addon,
//          addon_items: [...new_addon_items]
//      }
//      return new_addon
//  })
//  let new_item = {
//      ...action.paylod.item,
//      addons: new_addons,
//      images: action.payload.item.images[0].file_url,
//      categories: [],
//      labels: [],


//  }
//  let item_total = 0;
//  new_item.addons.map(addon => {
//      let addon_total = 0;
//      addon.addon_items.map(addon_item => {
//          addon_total += addon_item.price
//      })
//      console.log(addon_total, "this is addon total");
//      item_total += addon_total;
//  });
//  console.log(item_total, "this is item total")
 

//  let calculated_total = state.calculated_total + item_total;
//  console.log(calculated_total, "this is calculated total");

//  let new_items = [...state.items, ...item];
//  // console.log(new_items, 'these are new items');
//  secureStorage.setItem('cart', {items: new_items, calculated_total: calculated_total});
//  return new_items;
