import React, { Component } from 'react';
import { BASE_API_URL } from '../../config';
import { jsPDF } from "jspdf";

import html2canvas from 'html2canvas';
// import html2canvas from 'html2canvas-render-offscreen';

class ViewOrderDetails extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            orderData: {
                invoice: null,
                products: []
            },
            loading: true
        }
    }

    componentDidMount() {
        let order_id = this.props.match.params.order_id;
        fetch(`${BASE_API_URL}/order/${order_id}`)
        .then(resp => {
            return resp.json();
        })
        .then(resJson => {
            console.log(resJson);
            this.setState(prevState => {
                return {
                    ...prevState,
                    orderData: resJson,
                    loading: false
                }
            });
            document.getElementById("address").innerHTML = resJson.invoice.address_str;
            document.getElementById("address-billing").innerHTML = resJson.invoice.address_str;

        })
        .catch(err => {
            console.log(err);
        })
    }

    generateReceipt = () => {
        const input = document.getElementById('receiptContainer');
        // width: 3000px !important; height: 3000px !important;
        // input.style.width = "3000px !important";
        // input.style.width = 3000;
        // input.style.height = "3000px !important";
        // input.style.height = 3000;
        console.log("generating pdf");
        html2canvas(input, {scrollY: -window.scrollY})
        html2canvas(input)
        .then((canvas) => {
            console.log("canvas creating pdf");
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            console.log("pdf creation completed");
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(`order-${this.props.match.params.order_id}.pdf`);
            console.log("pdf downloading");

        });
    }
    
    
    
    render() {


        let products = null;

        products = this.state.orderData.products.map((product, index) => {
            let addons = product.addons.map((addon, i) => {
                let adon = <span key={i}>{addon.addon_title} - {addon.addon_title_ar} - (price - {addon.price})</span>;
                return adon
            });

            let prod = (<div className="row" key={index}>
                                    
            <div className="col-lg-6 col-sm-8" style={{textAlign: "left", padding: "0px"}}>
                <p>{product.product_name} - ${product.product_name_ar}<br></br>Qty : {product.quantity} <br></br> {addons}</p>
            </div>
            <div className="col-lg-3">
                
            </div>
            <div className="col-lg-3 col-sm-4">
                <p>{product.price}</p>
            </div>
            
            
        </div>)
            return prod;
        })
        return this.state.loading ? <p>loading</p> : (
            <>
                <div className="container" style={{marginTop: "20px"}}>
                <div className="row">
                    <div className="col-lg-2">

                    </div>
                    {/* boxShadow: "0 0 30px 0px #e6e5e5" */}
                    <div id="receiptContainer" className="col-lg-8" style={{textAlign: "center", padding: "0px", border: "1px solid #d4d4d4", paddingTop: "15px", paddingBotoom: "15px", }}>
                        <img src={`/assets/img/confirm.png`} width="50" height="50"/>
                        <h3>We've received your order</h3>
                        <p>Order Number {`${this.state.orderData.invoice.id}`} - 
                        {this.state.orderData.invoice.delivery === "0" ? (`( In Process )`) : null}
                        {this.state.orderData.invoice.delivery === "1" ? (`( Delivered )`) : null}
                        {/* {this.state.orderData.invoice.delivery === "0" ? (`in Process`) : null} */}
                        </p>
                        <p style={{color:"#b7b6b6"}}>A copy of receipt has been sent to your mail - {`${this.state.orderData.invoice.email}`}</p>
                        <hr></hr>
                        <p></p>
                        <div className="row">
                            <div className="col-lg-1">

                            </div>
                            <div className="col-lg-10">
                                <div className="row">
                                    <h6><strong>Delivery Details</strong></h6>
                                   

                                </div>
                                <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12" style={{textAlign: "left", padding: "0px"}}>
                                        <p><strong>Delivery For</strong></p>
                                        <p>{`${this.state.orderData.invoice.fname} ${this.state.orderData.invoice.lname}`} <br/> {`${this.state.orderData.invoice.phone}`}</p>
                                        {/* <p>Phone Number here</p> */}
                                    </div>
                                    <div className="col-lg-6 col-sm-12" style={{textAlign: "left", padding: "0px"}}>
                                        <p><strong>Delivery Method</strong></p>
                                        <p>Standard Delivery - Please read below to see when your items will be delivered</p>
                                        
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-lg-6 col-sm-12" style={{textAlign: "left", padding: "0px"}}>
                                        <p><strong>Address</strong></p>
                                        <p id="address">{`${this.state.orderData.invoice.address_str}`}</p>
                                        {/* <p>Phone Number here</p> */}
                                    </div>
                                    <div className="col-lg-6 col-sm-12" style={{textAlign: "left", padding: "0px"}}>
                                        
                                        
                                    </div>
                                </div>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <div className="row" style={{textAlign:"left", padding: "0px"}}>
                                    <h6><strong>Order Summary</strong></h6>
                                    <br></br>
                                    <br></br>
                                    <br></br>

                                    
                                    


                                    
                                </div>

                                {products}
                                {/* <div className="row">
                                    
                                    <div className="col-lg-6 col-sm-8" style={{textAlign:"left", padding: "0px"}}>
                                        <p>{`${this.state.orderData.products[0].product_name} - ${this.state.orderData.products[0].product_name_ar}`}<br></br>Qty : {this.state.orderData.products[0].quantity}</p>
                                    </div>
                                    <div className="col-lg-3">
                                        
                                    </div>
                                    <div className="col-lg-3 col-sm-4">
                                        <p>{this.state.orderData.products[0].price}</p>
                                    </div>
                                    
                                    
                                </div> */}



                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>

                                <div className="row">
                                    <div className="col-lg-3 col-sm-4" style={{textAlign:"left", padding: "0px"}}>
                                        <p>Sub Total</p>
                                        <p>Delivery Charges</p>
                                        <p>Total</p>
                                    </div>
                                    <div className="col-lg-6 col-sm-4">
                                        <p>...................................</p>
                                        <p>...................................</p>
                                        <p>...................................</p>
                                    </div>
                                    <div className="col-lg-3 col-sm-4">
                                        <p>KD. {parseFloat(this.state.orderData.invoice.price).toFixed(3)}</p>
                                        <p>KD. {parseFloat(this.state.orderData.invoice.delivery_charges).toFixed(3)}</p>
                                        <p>KD. {parseFloat(this.state.orderData.invoice.total_price).toFixed(3)}</p>
                                    </div>
                                </div>
                                
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>

                                <div className="row">
                                    <h6><strong>Payment Information</strong></h6>
                                    <br></br>
                                    
                                </div>
                                <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12" style={{textAlign:"left", padding: "0px"}}>
                                        {this.state.orderData.invoice.payment_method === "COD" ? <p>Opted for Cash On Delivery</p> : null}
                                        {/* {this.state.orderData.invoice.payment_method ? <p>Opted for Cash On Delivery</p> : null}
                                        {this.state.orderData.invoice.payment_method ? <p>Opted for Cash On Delivery</p> : null} */}
                                    </div>
                                    <div className="col-lg-4">

                                    </div>
                                    <div className="col-lg-4 col-sm-12" style={{textAlign:"left", padding: "0px"}}>
                                        <p><strong>Billing Address</strong></p>
                                        <p id="address-billing"></p>
                                    </div>
                                </div>
                                

                                
                            </div>
                            <div className="col-lg-1">

                            </div>
                        </div>
                            
                        
                        
                    </div>
                    <div className="col-lg-2">
                    </div>
                </div>
            
            
                <div className="row" style={{textAlign: "center", marginTop: "20px"}}>
                    <div className="col-lg-2 col-sm-4">

                    </div>
                    <div className="col-lg-8 col-sm-4">
                        <button className="btn btn-primary" onClick={this.generateReceipt}>Download Receipt</button>

                    </div>
                    <div className="col-lg-2 col-sm-4">

                    </div>
                </div>
            </div>
                
            </>
        );
    }
}


export default ViewOrderDetails;