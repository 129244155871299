import {BASE_API_URL, getCookie} from '../../config';
import * as actionTypes from '../actions/actions';


export const syncPopUpProduct = (resultJson) => {
    return {
        type: actionTypes.SET_SELECTED_PRODUCT,
        payload: {
            product: resultJson
        }
    }
}



export const getSelectedProduct = (prod_id) => {
    return (dispatch) => {
        if(prod_id !== null && prod_id !== undefined) {

            fetch(`${BASE_API_URL}/product/${prod_id}`, {
                method: "GET"
            })
            .then(result => {
                return result.json();
            })
            .then(resultJson => {
                console.log(resultJson)
                // this.props.updateShop(resultJson);
                resultJson.discount = resultJson.price;
                resultJson.price = resultJson.old_price;
                dispatch(syncPopUpProduct(resultJson));
            })
            .catch(err => {
                console.log(err);
            });
        } else {
            dispatch({type: actionTypes.SET_SELECTED_PRODUCT, payload: {product: null}})
        } 
    }
}