import * as actionTypes from '../actions/actions';


const initialState = {
    facebook: null,
    twitter: null,
    instagram: null,
    snapchat: null,
    logo: null,
    phone: null,
    payment_gateway_type: null,
    international_shipping_basis: null
}

const initialAction = {
    payload: {
        shopRes: {
            // data: {
            //     facebook: null,
            //     twitter: null,
            //     instagram: null,
            //     snapchat: null,
            //     logo: null,
            //     phone: null,
            //     payment_gateway_type: null,
            //     international_shipping_basis: null
            
            // }
            data: {

            }
        }
    }
}


const shopReducer = (state = initialState, action= initialAction) => {
    // let shopData = action.payload.shopRes.data;
    switch(action.type) {
        case actionTypes.UPDATE_SHOP:
            console.log("update shop called");
            let shopData = action.payload.shopData;

            return {
                ...state,
                ...shopData
                // facebook: shopData.facebook,
                // twitter: shopData.twitter,
                // instagram: shopData.instagram,
                // logo: shopData.logo,
                // phone: shopData.phone,
                // payment_gateway_type: shopData.payment_gateway_type,
                // international_shipping_basis: shopData.international_shipping_basis
            }
    }
    return state;
}


export default shopReducer;