import React, { Component } from 'react';
import { BASE_API_URL } from '../../config';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
class OrderStatus extends Component {
    

    constructor(props) {
        super(props);

        this.state = {
            statusCodes: [
                "Order Pending",
                "In Progress",
                "Order Accepted",
                "Delivered",
                "Cancelled"
            ],
            searchKey: null,
            orders: []
        }
    }

    updateSearchKey = (keyword) => {
        this.setState(prevState => {
            return {
                ...prevState,
                searchKey: keyword,
                orders: []
            }
        });
    }
    
    
    getOrderDetails = () => {
        // this.setState(prevState => {
        //     return {
        //         ...prevState,
        //         searchKey: keyword 
        //     }
        // }, () => {

            console.log(this.state.searchKey);
            fetch(`${BASE_API_URL}/track/${this.state.searchKey}`,{
                method: "GET",
                headers: {

                }
            })
            .then((orders) => {
                console.log(orders);
                return orders.json();
            })
            .then(ordersJson => {
                console.log(ordersJson);
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        orders: ordersJson
                    }
                })
            })
            .catch(err => {
                console.log(err);
            })
        // })
    }

    render() {

        let orders = this.state.orders.map((order, index) => {
            return <tr>
                    <td className="text-left" ><Link to={`/order-details/${order.id}`}>{order.id}</Link></td>
                    <td className="text-right">{this.props.lang == "en" ? this.props.translations.en[this.state.statusCodes[order.delivery_status_code]] : this.props.translations.ar[this.state.statusCodes[order.delivery_status_code]]}</td>
                </tr>
        });

        return (
            <>
                {/* <section className="checkout"> */}
                    <div className="container" style={{minHeight: "500px"}}>
                        
                        <div className="row">
                            <div className="col-3 col-xs-1">

                            </div>
                            <div className="col-6 col-xs-10">
                                <div className="row">
                                    <h2>{this.props.lang == "en" ? this.props.translations.en["Order Status"] : this.props.translations.ar["Order Status"]}</h2>
                                </div>
                                <div className="row"> 
                                    
                                    <div className="col-sm-9">
                                        <div className="form-group">
                                            <input type="text" onChange={(e) => this.updateSearchKey(e.currentTarget.value)} className="form-control" value={this.state.searchKey} aria-describedby="" placeholder={this.props.lang == "en" ? this.props.translations.en["Enter mobile number/order id*"] : this.props.translations.ar["Enter mobile number/order id*"]} />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <a onClick={() => this.getOrderDetails()} className="trackbtn">{this.props.lang == "en" ? this.props.translations.en["Submit"] : this.props.translations.ar["Submit"]}</a>
                                        </div>
                                    </div>
                                        
                                </div>
                                <div className="row">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th className="text-left">{this.props.lang == "en" ? this.props.translations.en["Order Id"] : this.props.translations.ar["Order Id"]}</th>
                                                <th className="text-right">{this.props.lang == "en" ? this.props.translations.en["Order Delivery Status"] : this.props.translations.ar["Order Delivery Status"]}</th>
                                            </tr>
                                            {/* {this.state.orders ? orders : <tr><td>No Orders Found : (</td></tr>} */}
                                            {orders}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-3">

                            </div>
                        </div>
                            
                    
                    </div>
                {/* </section> */}
                        
                        
            </>
        );
    }


}




const mapStateToProps = (state) => {
    return {
        // shop: state.shop,
        lang: state.lang.lang,
        settings: state.settings.data,
        cart: state.cart,
        lang: state.lang.lang,
        translations: state.translations,
        showSignInModal: state.handleSignInModal.show,
        selectedProduct: state.selectedProduct
    }
  }

export default connect(mapStateToProps, null)(OrderStatus);