import * as actionTypes from '../actions/actions';

import cloneDeep from 'lodash/cloneDeep';


const initialState = {
    selectedProduct: null,
    showProductPopUp: false
}

const popUpProductReducer = (state = initialState, action) => {
    let currentState = cloneDeep(state);

    switch(action.type) {
        case actionTypes.SET_SELECTED_PRODUCT:
            return {
                ...currentState,
                selectedProduct: action.payload.product
                
            }
            // return Promise.resolve({
            //     type:'UPDATE_STATE',
            //     key, value
            // });

        case actionTypes.HANDLE_PRODUCT_POPUP:
            // let currentState = cloneDeep(state);

            return {
                ...currentState,
                showProductPopUp: action.payload.show_product_popup
            }

    }
    return state;
}

export default popUpProductReducer;