// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
import reportWebVitals from "./reportWebVitals";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { secureStorage } from "./locStore";
import * as actionTypes from "./store/actions/actions";

import thunk from "redux-thunk";

// import testReducer from './store/reducers/testReducer';
import cartReducer from "./store/reducers/cartReducer";
// import shopReducer from './store/reducers/shopReducer';
// import categoriesReducer from './store/reducers/categoriesReducer';
import languageReducer from "./store/reducers/languageReducer";
// import currencyReducer from './store/reducers/currencyreducer';

import cloneDeep from "lodash/cloneDeep";
// import multiplierReducer from './store/reducers/multiplierReducer';
// import translationReducer from './store/reducers/translationReducer';
import { BASE_API_URL } from "./config";
import bannersReducer from "./store/reducers/bannersReducer";
import branchesReducer from "./store/reducers/branchesReducer";
import categoriesReducer from "./store/reducers/categoriesReducer";
import translationReducer from "./store/reducers/translationReducer";
import settingsReducer from "./store/reducers/settingsReducer";
import governatesReducer from "./store/reducers/governatesReducer";
import deliveryLocationReducer from "./store/reducers/deliveryLocationReducer";
import selectedCategoryReducer from "./store/reducers/selectedCategoryReducer";
import qtyIndicatorReducer from "./store/reducers/quantityIndicator";
import searchInputReducer from "./store/reducers/searchInputReducer";
import countriesReducer from "./store/reducers/countriesReducer";
import productsLoaderReducer from "./store/reducers/productsLoaderReducer";
import handleSignInModaReducer from "./store/reducers/handleSignInModalReducer";
import popUpProductReducer from "./store/reducers/popUpProductReducer";
import shopReducer from "./store/reducers/shopReducer";
import currenciesReducer from "./store/reducers/currenciesReducer";
import currencyReducer from "./store/reducers/currencyreducer";
import multiplierReducer from "./store/reducers/multiplierReducer";
// // import { result } from 'lodash';
let domain_name = window.location.hostname;

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  return true;
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// let storageSid = secureStorage.getItem("sid");

// if(storageSid == "" || storageSid == "undefined" || storageSid == null) {
//   console.log("calling fetch");
//   fetch(`${BASE_API_URL}/getshopid/${domain_name}`)
//   .then(result => {
//     return result.json();
//   })
//   .then(data => {
//     console.log(data, "this is domain_name id data");
//     secureStorage.setItem('sid', data.data[0]._id);
//     // let setTheCookie = new Promise(function(resolve, reject){

//     //   if(setCookie("sid", data.data[0]._id)) {
//     //     resolve(true);

//     //   } else {
//     //     reject(false);
//     //   }
//     // });

//     // setTheCookie
//     // .then(suc => {
//     let sid = secureStorage.getItem('sid');
//     console.log(sid, "this is sid");
//     if(sid != "" && sid != null && sid != "undefined") {
//       // continueExec()
// const rootReducer = combineReducers({
//   test: testReducer,
//   cart: cartReducer,
//   shop: shopReducer,
//   categories: categoriesReducer,
//   lang: languageReducer,
//   currency: currencyReducer,
//   multiplier: multiplierReducer,
//   translations: translationReducer
// });

// const logBoss = (store) => {
//   return (next) => {
//     return (action) => {
//       console.log("we can do whatever we want here and it will be executed before action reaches reducer after dispatching");
//       console.log(store.getState());
//       console.log("I just printed the current state");
//       const result = next(action);
//       return result;
//     }
//   }
// }
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logBoss, thunk)));

//       // if(secureStorage.getItem('shopId') == "undefined" || secureStorage.getItem('shopId') == null) {
//       //   console.log("shopId available", secureStorage.getItem('cart'));
//       //   secureStorage.setItem('shopId');
//       // }

//       if(secureStorage.getItem('cart') == "undefined" || secureStorage.getItem("cart") == null) {
//         console.log("checking is cart available", secureStorage.getItem('cart'));
//         secureStorage.setItem('cart', {items: [], calculated_total: 0});

//       } else {
//         console.log("got the cart and dispatching", secureStorage.getItem('cart'));

//         store.dispatch({type: actionTypes.SET_CART_ON_RELOAD, payload: {cart: secureStorage.getItem('cart')}});

//         // secureStorage.setItem('cart', {items: [
//         //   {
//         //     title: "product 1",
//         //     arabic_title: "product 1 arabic",
//         //     price: 10.1,
//         //     quantity: 2,

//         //   }
//         // ], calculated_total: 20.2, base_currency: "KWD"});
//       }

//       if(secureStorage.getItem('base_currency') == "undefined" || secureStorage.getItem("base_currency") == null) {
//         // console.log("got the cart", secureStorage.getItem('cart'));
//         secureStorage.setItem('base_currency', "KWD");

//       } else {

//         store.dispatch({type: actionTypes.SET_CURRENCY, payload: {base_currency: secureStorage.getItem('base_currency')}});

//         // secureStorage.setItem('cart', {items: [
//         //   {
//         //     title: "product 1",
//         //     arabic_title: "product 1 arabic",
//         //     price: 10.1,
//         //     quantity: 2,

//         //   }
//         // ], calculated_total: 20.2, base_currency: "KWD"});
//       }

// if(secureStorage.getItem('lang') == "undefined" || secureStorage.getItem("lang") == null) {
//   // console.log("got the cart", secureStorage.getItem('cart'));
//   secureStorage.setItem('lang', "en");

// } else {

//   // store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: secureStorage.getItem('lang')}});
//   let lang = secureStorage.getItem('lang');
//   let mainBody = document.getElementById('root-body');
//   if(lang == "ar") {
//       store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: lang}});

//       mainBody.classList.add("right-to-left");
//       //
//       // document.body.appendChild(`<link rel="stylesheet" href="%PUBLIC_URL%/assets/css/rtl.css">`);
//       var arabicDiv = document.getElementById('arabicDiv');
//       if(arabicDiv != null) {
//           arabicDiv.remove();
//       }

//       let adders = document.createElement("div");
//       var att = document.createAttribute("id");       // Create a "class" attribute
//       att.value = "arabicDiv";                           // Set the value of the class attribute
//       adders.setAttributeNode(att);
//       let arabStyles = document.createElement('link');
//       arabStyles.type = 'text/css';
//       arabStyles.rel = 'stylesheet';
//       arabStyles.href =  '/assets/css/rtl.css';
//       adders.appendChild(arabStyles);

//       document.body.appendChild(adders);

//   } else if(lang == "en") {
//     store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: lang}});

//       mainBody.classList.remove("right-to-left");
//       var arabicDiv = document.getElementById('arabicDiv');
//       if(arabicDiv != null) {
//           arabicDiv.remove();
//       }
//   }

//         // secureStorage.setItem('cart', {items: [
//         //   {
//         //     title: "product 1",
//         //     arabic_title: "product 1 arabic",
//         //     price: 10.1,
//         //     quantity: 2,

//         //   }
//         // ], calculated_total: 20.2, base_currency: "KWD"});
//       }

//       if(secureStorage.getItem('multiplier') == "undefined" || secureStorage.getItem("multiplier") == null) {
//         // console.log("got the cart", secureStorage.getItem('cart'));
//         secureStorage.setItem('multiplier', 1);

//       } else {

//         store.dispatch({type: actionTypes.SET_MULTIPLIER, payload: {multiplier: secureStorage.getItem('multiplier')}});

//         // secureStorage.setItem('cart', {items: [
//         //   {
//         //     title: "product 1",
//         //     arabic_title: "product 1 arabic",
//         //     price: 10.1,
//         //     quantity: 2,

//         //   }
//         // ], calculated_total: 20.2, base_currency: "KWD"});
//       }

//       // console.log("after cart ", secureStorage.getItem('cart'));
//       // if(secureStorage.getItem('lang') !== undefined) {
//       //   store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: secureStorage.getItem('lang')}});
//       // } else {
//       //   secureStorage.setItem("lang", "en");
//       // }

//       ReactDOM.render(
//         <React.StrictMode>
//           <Provider store={store}>
//             <App />
//           </Provider>
//         </React.StrictMode>,
//         document.getElementById('root')
//       );

//       // If you want your app to work offline and load faster, you can change
//       // unregister() to register() below. Note this comes with some pitfalls.
//       // Learn more about service workers: https://bit.ly/CRA-PWA
//       serviceWorker.unregister();
//     } else {
//       console.log("didn't set the sid");
//     }
//     // })
//     // .catch(err => {
//     //   console.log("error while setting the cookie", err);
//     // })

//   })
//   .catch(err => {
//     console.log(err);
//   })

// } else {
//   // continueExec();
//   console.log("calling default");
const rootReducer = combineReducers({
  // test: testReducer,
  cart: cartReducer,
  settings: settingsReducer,
  shop: shopReducer,
  categories: categoriesReducer,
  governates: governatesReducer,
  countries: countriesReducer,
  lang: languageReducer,
  banners: bannersReducer,
  branches: branchesReducer,
  selectedCategory: selectedCategoryReducer,
  qtyIndicator: qtyIndicatorReducer,
  currencies: currenciesReducer,
  currency: currencyReducer,
  multiplier: multiplierReducer,
  translations: translationReducer,
  deliveryLocation: deliveryLocationReducer,
  searchInput: searchInputReducer,
  productsLoader: productsLoaderReducer,
  handleSignInModal: handleSignInModaReducer,
  selectedProduct: popUpProductReducer,
});

const logBoss = (store) => {
  return (next) => {
    return (action) => {
      console.log(
        "we can do whatever we want here and it will be executed before action reaches reducer after dispatching"
      );
      console.log(store.getState());
      console.log("I just printed the current state");
      const result = next(action);
      return result;
    };
  };
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logBoss, thunk))
);

//   // if(secureStorage.getItem('shopId') == "undefined" || secureStorage.getItem('shopId') == null) {
//   //   console.log("shopId available", secureStorage.getItem('cart'));
//   //   secureStorage.setItem('shopId');
//   // }

//   if(secureStorage.getItem('cart') == "undefined" || secureStorage.getItem("cart") == null) {
//     console.log("checking is cart available", secureStorage.getItem('cart'));
//     secureStorage.setItem('cart', {items: [], calculated_total: 0});

//   } else {
//     console.log("got the cart and dispatching", secureStorage.getItem('cart'));

//     store.dispatch({type: actionTypes.SET_CART_ON_RELOAD, payload: {cart: secureStorage.getItem('cart')}});

//     // secureStorage.setItem('cart', {items: [
//     //   {
//     //     title: "product 1",
//     //     arabic_title: "product 1 arabic",
//     //     price: 10.1,
//     //     quantity: 2,

//     //   }
//     // ], calculated_total: 20.2, base_currency: "KWD"});
//   }

if (
  secureStorage.getItem("base_currency") == "undefined" ||
  secureStorage.getItem("base_currency") == null
) {
  // console.log("got the cart", secureStorage.getItem('cart'));
  secureStorage.setItem("base_currency", "KWD");
} else {
  store.dispatch({
    type: actionTypes.SET_CURRENCY,
    payload: { base_currency: secureStorage.getItem("base_currency") },
  });

  // secureStorage.setItem('cart', {items: [
  //   {
  //     title: "product 1",
  //     arabic_title: "product 1 arabic",
  //     price: 10.1,
  //     quantity: 2,

  //   }
  // ], calculated_total: 20.2, base_currency: "KWD"});
}

if (
  secureStorage.getItem("delivery_location" === undefined) ||
  secureStorage.getItem("delivery_location") === null
) {
  secureStorage.setItem("delivery_location", { governate: null, area: null });
  store.dispatch({
    type: actionTypes.SET_DELIVERY_LOCATION,
    payload: { location: { governate: null, area: null } },
  });
} else {
  let deliveryLocation = cloneDeep(secureStorage.getItem("delivery_location"));

  store.dispatch({
    type: actionTypes.SET_DELIVERY_LOCATION,
    payload: { location: deliveryLocation },
  });
}

if (
  secureStorage.getItem("cart") === undefined ||
  secureStorage.getItem("cart") === null
) {
  secureStorage.setItem("cart", { calculated_total: 0, items: [] });
  store.dispatch({
    type: actionTypes.SET_CART_ON_RELOAD,
    payload: { cart: { calculated_total: 0, items: [] } },
  });
} else {
  let cart = cloneDeep(secureStorage.getItem("cart"));

  store.dispatch({
    type: actionTypes.SET_CART_ON_RELOAD,
    payload: { cart: cart },
  });
}

if (
  secureStorage.getItem("lang") == "undefined" ||
  secureStorage.getItem("lang") == null
) {
  // console.log("got the cart", secureStorage.getItem('cart'));
  secureStorage.setItem("lang", "en");
} else {
  // store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: secureStorage.getItem('lang')}});
  let lang = secureStorage.getItem("lang");
  let mainBody = document.getElementById("root");
  if (lang == "ar") {
    store.dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: "ar" } });
    secureStorage.setItem("lang", "ar");
    // mainBody.classList.remove("ltr");
    // mainBody.classList.add("rtl");
    //
    // // document.body.appendChild(`<link rel="stylesheet" href="%PUBLIC_URL%/assets/css/rtl.css">`);
    // var arabicDiv = document.getElementById('arabicDiv');
    // if(arabicDiv != null) {
    //     arabicDiv.remove();
    // }

    // let adders = document.createElement("div");
    // var att = document.createAttribute("id");       // Create a "class" attribute
    // att.value = "arabicDiv";                           // Set the value of the class attribute
    // adders.setAttributeNode(att);
    // let arabStyles = document.createElement('link');
    // arabStyles.type = 'text/css';
    // arabStyles.rel = 'stylesheet';
    // arabStyles.href =  '/assets/css/rtl.css';
    // adders.appendChild(arabStyles);

    // document.body.appendChild(adders);
  } else if (lang == "en") {
    store.dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: "en" } });
    secureStorage.setItem("lang", "en");
    // mainBody.classList.remove("rtl");
    // mainBody.classList.add("ltr");
    // mainBody.classList.remove("rtl");
  }
}
//     // secureStorage.setItem('cart', {items: [
//     //   {
//     //     title: "product 1",
//     //     arabic_title: "product 1 arabic",
//     //     price: 10.1,
//     //     quantity: 2,

//     //   }
//     // ], calculated_total: 20.2, base_currency: "KWD"});
//   }

if (
  secureStorage.getItem("multiplier") == "undefined" ||
  secureStorage.getItem("multiplier") == null
) {
  // console.log("got the cart", secureStorage.getItem('cart'));
  secureStorage.setItem("multiplier", 1);
} else {
  store.dispatch({
    type: actionTypes.SET_MULTIPLIER,
    payload: { multiplier: secureStorage.getItem("multiplier") },
  });

  // secureStorage.setItem('cart', {items: [
  //   {
  //     title: "product 1",
  //     arabic_title: "product 1 arabic",
  //     price: 10.1,
  //     quantity: 2,

  //   }
  // ], calculated_total: 20.2, base_currency: "KWD"});
}

//   // console.log("after cart ", secureStorage.getItem('cart'));
//   // if(secureStorage.getItem('lang') !== undefined) {
//   //   store.dispatch({type: actionTypes.SET_LANGUAGE, payload: {lang: secureStorage.getItem('lang')}});
//   // } else {
//   //   secureStorage.setItem("lang", "en");
//   // }
console.log(store.getState());

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
