import React, { Component } from "react";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";

import { connect } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { BASE_API_URL } from "../../../../config";

import { secureStorage } from "../../../../locStore";

import cloneDeep from "lodash/cloneDeep";

import * as actionTypes from "../../../../store/actions/actions";
class CheckOutSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobielCart: false,
    };
    console.log(this.props);
  }

  handleMobileCart = () => {
    console.log("mobile cart handled");
    this.setState((prevState) => {
      return {
        ...prevState,
        showMobileCart: !prevState.showMobileCart,
      };
    });
  };

  decreaseQty = (product, index) => {
    // console.log(product);
    // let quantity = parseInt(product.qty);
    // // let quantity = parseInt(document.getElementById(`cartItem-${product.id}`).value);
    // console.log(quantity);
    // if(quantity !== undefined && quantity !== null) {
    //     if(quantity == 0) {
    //         quantity = 0;
    //     } else {
    //         quantity = isNaN(quantity) ? 0 : quantity - 1
    //     }
    // } else {
    //     quantity = 0
    // }

    let currentProduct = cloneDeep(product);
    // currentProduct["addons"] = [];
    // let qty = this.state.qty - 1;
    // currentProduct["qty"] = quantity;
    // currentProduct["calculated_price"] = product.calculated_price
    // currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
    // if(quantity > 0) {
    //     this.props.addItemToCart(currentProduct, index);

    // } else {
    console.log(index);
    this.props.removeItemFromCart(currentProduct, index);
    // }
  };

  render() {
    let items = this.props.cart.items.map((item, index) => {
      console.log(index + "item");
      return (
        // <li key={index}><img src="img/favicon.ico" onClick={() => this.decreaseQty(item, index)}/><a>{item.qty}x {this.props.lang == "en" ? item.translations.en.title: item.translations.ar.title}</a><span>KWD {item.calculated_total.toFixed(3)}</span></li>
        // <li key={index}>
        //     <div><i aria-hidden="true" className="icon_close_alt2" onClick={() => this.decreaseQty(item, index)} style={{color: 'red', cursor: 'pointer'}} ></i>{item.qty} x {this.props.lang == "en" ? item.translations.en.title: item.translations.ar.title} </div>
        //     <div>{(item.calculated_total * this.props.multiplier.multiplier).toFixed(3)}</div>
        // </li>
        <li className="listOs" key={index}>
          <div className="leftOs">
            <i
              aria-hidden="true"
              className="icon_close_alt2"
              onClick={() => this.decreaseQty(item, index)}
              style={{ color: "red", cursor: "pointer" }}
            ></i>
            {item.qty} x{" "}
            {this.props.lang == "en"
              ? item.translations.en.title
              : item.translations.ar.title}{" "}
          </div>
          <div className="rightOs">
            {(item.calculated_total * this.props.multiplier.multiplier).toFixed(
              3
            )}
          </div>
        </li>
      );
    });
    return (
      <div className="col-lg-4 " id="sidebar_fixed">
        <div className="box_order mobile_fixed animate_animated animate__flash">
          <div className="head">
            <h3>
              {this.props.lang == "en"
                ? this.props.translations.en["Order Summary"]
                : this.props.translations.ar["Order Summary"]}
            </h3>
            {/* <a href="#0" className="close_panel_mobile"><i className="icon_close"></i></a> */}
          </div>

          <div className="main">
            <ul className="clearfix">{items}</ul>
            <ul className="clearfix">
              <li>
                {this.props.lang == "en"
                  ? this.props.translations.en["Subtotal"]
                  : this.props.translations.ar["Subtotal"]}
                <span>
                  {this.props.currency.base_currency}{" "}
                  {(
                    this.props.cart.calculated_total *
                    this.props.multiplier.multiplier
                  ).toFixed(3)}
                </span>
              </li>
              {/* <li>Delivery fee<span>KWD 0.000</span></li> */}
              <li className="total">
                {this.props.lang == "en"
                  ? this.props.translations.en["Total"]
                  : this.props.translations.ar["Total"]}
                <span>
                  {this.props.currency.base_currency}{" "}
                  {(
                    this.props.cart.calculated_total *
                    this.props.multiplier.multiplier
                  ).toFixed(3)}
                </span>
              </li>
            </ul>
            {/* <div className="row opt_order">
                                      <div className="col-6">
                                          <label className="container_radio">Delivery
                                              <input type="radio" value="option1" name="opt_order" checked/>
                                              <span className="checkmark"></span>
                                          </label>
                                      </div>
                                      <div className="col-6">
                                          <label className="container_radio">Take away
                                              <input type="radio" value="option1" name="opt_order"/>
                                              <span className="checkmark"></span>
                                          </label>
                                      </div> 
                                  </div>*/}

            <div className="btn_1_mobile">
              <Link to="/checkout" className="btn_1 gradient full-width mb_5">
                {this.props.lang == "en"
                  ? this.props.translations.en["Order Now"]
                  : this.props.translations.ar["Order Now"]}
              </Link>
              <div className="text-center">
                <small>
                  {this.props.lang == "en"
                    ? this.props.translations.en[
                        "No money charged on this steps"
                      ]
                    : this.props.translations.ar[
                        "No money charged on this steps"
                      ]}
                </small>
              </div>
            </div>
          </div>
        </div>

        {this.state.showMobileCart ? (
          <div className="box_order mobile_fixed" style={{ display: "block" }}>
            <div className="head">
              <h3>Order Summary</h3>
              <a className="close_panel_mobile">
                <i className="icon_close" onClick={this.handleMobileCart}></i>
              </a>
            </div>

            <div className="main">
              <ul className="clearfix">
                {/* <li>
                  <a href="#0">1x Enchiladas</a>
                  <span>$11</span>
                </li>
                <li>
                  <a href="#0">2x Burrito</a>
                  <span>$14</span>
                </li>
                <li>
                  <a href="#0">1x Chicken</a>
                  <span>$18</span>
                </li>
                <li>
                  <a href="#0">2x Corona Beer</a>
                  <span>$9</span>
                </li>
                <li>
                  <a href="#0">2x Cheese Cake</a>
                  <span>$11</span>
                </li> */}
                {items}
              </ul>
              <ul className="clearfix">
                {/* <li>
                  Subtotal<span>$56</span>
                </li>
                <li>
                  Delivery fee<span>$10</span>
                </li>
                <li className="total">
                  Total<span>$66</span>
                </li> */}

                <li>
                  {this.props.lang == "en"
                    ? this.props.translations.en["Subtotal"]
                    : this.props.translations.ar["Subtotal"]}
                  <span>
                    {this.props.currency.base_currency}{" "}
                    {(
                      this.props.cart.calculated_total *
                      this.props.multiplier.multiplier
                    ).toFixed(3)}
                  </span>
                </li>
                {/* <li>Delivery fee<span>KWD 0.000</span></li> */}
                <li className="total">
                  {this.props.lang == "en"
                    ? this.props.translations.en["Total"]
                    : this.props.translations.ar["Total"]}
                  <span>
                    {this.props.currency.base_currency}{" "}
                    {(
                      this.props.cart.calculated_total *
                      this.props.multiplier.multiplier
                    ).toFixed(3)}
                  </span>
                </li>
              </ul>

              <div className="btn_1_mobile">
                <Link to="/checkout" className="btn_1 gradient full-width mb_5">
                  Order Now
                </Link>
                <div className="text-center">
                  <small>No money charged on this steps</small>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.props.cart.items.length > 0 ? (
          <div className="btn_reserve_fixed">
            {/* <Link to="/checkout" className="btn_1 gradient full-width"> */}
            <Link
              onClick={this.handleMobileCart}
              className="btn_1 gradient full-width"
            >
              {this.props.lang == "en"
                ? this.props.translations.en["View Basket"]
                : this.props.translations.ar["View Basket"]}
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    cart: state.cart,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
    currency: state.currency,
    multiplier: state.multiplier,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    // setLang: (lang) => dispatch({type: actionTypes.SET_LANGUAGE, payload: { lang : lang}}),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    // setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    // setShowProductPopUp: (show_product_popup) => dispatch({type: actionTypes.HANDLE_PRODUCT_POPUP, payload: { show_product_popup: show_product_popup }}),
    setQtyIndicator: (productId) =>
      dispatch({
        type: actionTypes.SET_QUANTITY_INDICATOR_ID,
        payload: { productId: productId },
      }),
    addItemToCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    updateCart: (product) =>
      dispatch({ type: actionTypes.ADD_CART_ITEM, payload: { item: product } }),
    removeItemFromCart: (product, i) =>
      dispatch({
        type: actionTypes.REMOVE_CART_ITEM,
        payload: { item: product, index: i },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutSidebar);

// export default CheckOutSidebar;
