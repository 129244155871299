import React, { Component } from 'react';
import {Link } from 'react-router-dom';

import { connect } from 'react-redux';

import * as actionTypes from '../../../../store/actions/actions';
class CategoryProduct extends Component {


    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <>
                <div className="col-md-6">
                        <a className="menu_item modal_dialog" >
                            <figure><img src="img/menu-thumb-placeholder.jpg" data-src="img/menu-thumb-1.jpg" alt="thumb" className="lazy" /></figure>
                            <h3>1. Mexican Enchiladas</h3>
                            <p>Fuisset mentitum deleniti sit ea.</p>
                            {/* <strong>{parseFloat(prod.discount) > 0 ?  `KWD ${this.props.product.discount}` : `KWD ${this.props.product.price}`}</strong> */}
                        </a>
                    </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        // shop: state.shop,
        lang: state.lang.lang,
        settings: state.settings.data,
        cart: state.cart,
        lang: state.lang.lang,
        translations: state.translations,
        showSignInModal: state.handleSignInModal.show
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        // updateShop: () => dispatch(updateShopDetails()),
        // getCategories: () => dispatch(getCategories()),
        setLang: (lang) => dispatch({type: actionTypes.SET_LANGUAGE, payload: { lang : lang}}),
        // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
        // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
    }
  }
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(CategoryProduct);
  

// export default CategoryProduct;