import React, { Component } from 'react';
import { BASE_API_URL } from '../../config';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
 
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
// import { secureStorage } from '../../../../locStore';
import cloneDeep from "lodash/cloneDeep";
import * as actionTypes from '../../store/actions/actions';
import { clone } from 'lodash';

import Modal from 'react-modal'
import { Link } from 'react-router-dom';
import Image, { Shimmer } from 'react-shimmer'

class ProductPage extends Component {
    

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            product: null,
            isLoading: true,
            qty: 1,
            productUpdatedPrice: 0,
            // showModal: false
        }
    }

    componentDidMount() {
        console.log(this.props);
        let mBanner = document.getElementsByClassName("mBanner mobile");
        mBanner[0].style.visibility = 'hidden';
        mBanner[0].style.height = "60px";
        fetch(`${BASE_API_URL}/product/${this.props.match.params.productId}`)
        .then(res => {
            return res.json();
        })
        .then(resjson => {
            console.log(resjson);
            this.setState(prevState => {
                let product = resjson.product
                return {
                    ...prevState,
                    product: resjson,
                    productUpdatedPrice: parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price)
                    
                }
            }, () => {
                this.setState((prevSta) => {
                    return {

                        ...prevSta,
                        isLoading: false
                    }
                });
            })
        })
        .catch(err => {
            console.log(err);
        });


    }
    
    componentWillUnmount () {
        let mBanner = document.getElementsByClassName("mBanner mobile");
        mBanner[0].style.removeProperty('visibility');
        mBanner[0].style.removeProperty('height');
    }


    updateCheckBoxes = () => {
        
        this.state.product.addons.map(addon => {
            let items = this.getCheckedBoxes(`checkboxof-${addon.id}`);
            console.log(items, "getCheckboxes items");

            if(addon.minimum >= 1 && addon.maximum > 1) {
                let checkboxes = document.getElementsByName(`checkboxof-${addon.id}`);
                if(items.length == addon.maximum) {
                    for(var i = 0; i < checkboxes.length; i++) {
                        if(items.includes(checkboxes[i].getAttribute('id'))) {
                            console.log("not disabled", checkboxes[i].getAttribute('id'));
                        } else {
                            checkboxes[i].disabled = true;
                        }
                    }
                } else {
                    for(var i = 0; i < checkboxes.length; i++) {
                        if(items.includes(checkboxes[i].getAttribute('id'))) {
                            console.log("not disabled", checkboxes[i].getAttribute('id'));
                        } else {
                            checkboxes[i].disabled = false;
                        }
                    }
                }

                // var checkboxesChecked = [];
                // // loop over them all
                // for (var i=0; i<checkboxes.length; i++) {
                //     // And stick the checked ones onto an array...
                //     if (checkboxes[i].checked) {
                //         checkboxesChecked.push(checkboxes[i]);
                //     }
                // }

                // if(checkboxesChecked.length == addon.maximum) {
                //     console.log("maximum addons selected");
                //     for(var c= 0; c < checkboxes.length; c++) {
                //         if(checkboxes[c] == checkboxesChecked[0]) {
                //             console.log(true, checkboxes[c]);

                //         } else {
                //             console.log(false, checkboxes[c]);
                //             // checkboxes[c].disabled = true;
                //         }
                //     }
                    
                // }

                
            }

            

        });   
    }

    canAddProduct = () => {
        let addonPossibilites = this.state.product.addons.map(addon => {
            let items = this.getCheckedBoxes(`checkboxof-${addon.id}`);
            console.log(items, "getCheckboxes items");
            let newAddonItems = [];
            let prevAddon = cloneDeep(addon);
            items.map(item => {
                let addonIt = addon.items.filter(addonItem => {
                    // if(item === addonItem.id) {
                    // console.log(addonItem);
                    // console.log(item);
                    return addonItem.id == item
                    // }
                });
                // console.log(addonIt, "these are found addon items");

                
                newAddonItems = [...newAddonItems, ...addonIt];
                // let addonItm = addon.items.filter(addonItem => {
                //     return addonItem.id === item;
                // });
                // console.log(addonItm, "these is addonItm");
                // if(addonItm.length > 0) {
                //     newAddonItems.push(addonItm[0]);
                // }
            });


            prevAddon.items = newAddonItems;


            if(prevAddon.items.length >= prevAddon.minimum && prevAddon.items.length <= prevAddon.maximum) {
                return true
            } else {
                return false;
            }

           

            

        });
        return addonPossibilites;
    }

    calculateAddonsPrice = (addons) => {
        let total = 0;
        if(addons !== undefined && addons !== null) {

        
            addons.map((addon, index) => {
                if(addon.items.length >= addon.minimum && addon.items.length <= addon.maximum) {

                    addon.items.map((item, i) => {
                        total += parseFloat(item.price);
                    })
                }
            });
            console.log(total, "calculated price");
            return total;
        }
    }

    getCheckedBoxes(chkboxName) {
        var checkboxes = document.getElementsByName(chkboxName);
        var checkboxesChecked = [];
        // loop over them all
        for (var i=0; i<checkboxes.length; i++) {
           // And stick the checked ones onto an array...
           if (checkboxes[i].checked) {
              checkboxesChecked.push(checkboxes[i].getAttribute("id"));
           }
        }
        // Return the array if it is non-empty, or null
        return checkboxesChecked.length > 0 ? checkboxesChecked : [];
    }

    getAddons = () => {
        // console.log(value);
        let retValue = true;
        let newAddons = this.state.product.addons.map(addon => {
            let items = this.getCheckedBoxes(`checkboxof-${addon.id}`);

            
            console.log(items, "getCheckboxes items");
            let newAddonItems = [];
            let prevAddon = cloneDeep(addon);
            items.map(item => {
                let addonIt = addon.items.filter(addonItem => {
                    // if(item === addonItem.id) {
                    // console.log(addonItem);
                    // console.log(item);
                    return addonItem.id == item
                    // }
                });
                // console.log(addonIt, "these are found addon items");

                
                newAddonItems = [...newAddonItems, ...addonIt];
                // let addonItm = addon.items.filter(addonItem => {
                //     return addonItem.id === item;
                // });
                // console.log(addonItm, "these is addonItm");
                // if(addonItm.length > 0) {
                //     newAddonItems.push(addonItm[0]);
                // }
            });
            prevAddon.items = newAddonItems;
            return prevAddon;

           

            

        })
        console.log(newAddons);
        return newAddons;
    }

    updateProductPrice = () => {
        // let addons = 
        this.updateCheckBoxes();
        let addonPossibilites = this.canAddProduct();
        let canUpdate = true;
        addonPossibilites.map(pos => {
            if(pos) {
                canUpdate = true;
            } else {
                canUpdate = false;
            }
        })
        if(canUpdate) {

        
            let addons = this.getAddons();
            if(addons) {
                console.log(addons, "these are selected addons");
                if(addons !== null && addons !== undefined) {
                    let newAddonsPrice = this.calculateAddonsPrice(addons);
                    console.log(newAddonsPrice);
                    let newProductPrice = parseFloat(this.state.product.product.price) + parseFloat(newAddonsPrice);
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            productUpdatedPrice: newProductPrice
                        }
                    })
                }
                

            }
            
        } else {
            // alert("Please select all required addons to see product price");
            this.setState(prevState => {
                let product = this.state.product.product;
                return {
                    ...prevState,
                    productUpdatedPrice: parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price)
                    
                }
            });
        }
    }


    decreaseQtyOfProduct = () => {
     
        let addons = this.getAddons();
        console.log(addons, "these are selected addons");
        let newAddonsPrice = this.calculateAddonsPrice(addons);
        console.log(newAddonsPrice);
        
        let prod = cloneDeep(this.state.product.product);
        let images = cloneDeep(this.state.product.images);
        let product = {
            ...prod,
            addons: [
                ...addons
            ],
            images: [
                ...images
            ]
            
        }

        console.log("this is product for decreasing", product);
        let currentProduct = cloneDeep(product);
        let pageqty = this.state.qty;
        // let pageqty = document.getElementById(-qty").innerHTML;
        console.log(pageqty, "this is page qty");
        let quantity = parseInt(pageqty);
        let qunty = 0;
       if(quantity !== undefined && quantity !== null) {
            if(quantity <= 0) {
                qunty = 0;

            } else {
                qunty = parseInt(quantity);
                if(isNaN(qunty)) {
                    qunty = 0;
                } else {
                    qunty = qunty - 1;
                }
            }
       } else {
           qunty = 0;
       }
       
        currentProduct["qty"] = parseInt(qunty);
        currentProduct["calculated_price"] = (parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price)) + parseFloat(this.calculateAddonsPrice);
        currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
        
        if(currentProduct["qty"] > 0) {
            
            this.props.addItemToCart(currentProduct);

            

        } else {
            this.props.removeItemFromCart(currentProduct);
        }

        
    }

    increaseQtyOfProduct = () => {
        console.log("increasing");
        // export default ProductPage;
        let addons = this.getAddons();
        console.log(addons, "these are selected addons");
        if(addons !== null && addons !== undefined) {

        
            let newAddonsPrice = this.calculateAddonsPrice(addons);

            console.log(newAddonsPrice);
            // let newProductPrice = parseFloat(this.state.product.product.price) + parseFloat(newAddonsPrice);
            // this.setState(prevState => {
            //     return {
            //         ...prevState,
            //         productUpdatedPrice: newProductPrice
            //     }
            // })
            let prod = cloneDeep(this.state.product.product);
            let images = cloneDeep(this.state.product.images);
            let product = {
                ...prod,
                addons: [
                    ...addons
                ],
                images: [
                    ...images
                ]
                
            }

            console.log("this is product for increasing", product);
            let currentProduct = cloneDeep(product);
            // currentProduct["addons"] = [];
            // let qty = this.state.qty - 1;
            let pageqty = this.state.qty;
            
            console.log(pageqty, "this is page qty");
            let qunty = parseInt(pageqty) + 1;
        //     let quantity = parseInt(pageqty);
        //     let qunty = 0;
        //    if(quantity !== undefined && quantity !== null) {
        //         if(quantity <= 0) {
        //             qunty = 0;

        //         } else {
        //             qunty = parseInt(quantity);
        //             if(isNaN(qunty)) {
        //                 qunty = 0;
        //             } else {
        //                 qunty = qunty + 1;
        //             }
        //         }
        //    } else {
        //        qunty = 0;
        //    }
            currentProduct["qty"] = parseInt(qunty);
            currentProduct["calculated_price"] = (parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price)) + parseFloat(this.calculateAddonsPrice);
            currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
            console.log(currentProduct);
            if(currentProduct["qty"] > 0) {
                this.props.addItemToCart(currentProduct);

            } else {
                this.props.removeItemFromCart(currentProduct)
            }

            // console.log("this is modified data", currentProduct);
        }

    }

    onChangeQtyOfProduct = (qua) => {
        console.log(qua);
        
        let quantity = parseInt(qua);

       let qunty = 0;
       if(quantity !== undefined && quantity !== null) {
            if(quantity <= 0) {
                qunty = 0;

            } else {
                qunty = parseInt(quantity);
                if(isNaN(qunty)) {
                    qunty = 0;
                }
            }
       } else {
           qunty = 0;
       }
        
        this.setState(prevState => {
            return {
                ...prevState,
                qty: qunty
            }
        }, () => {

            let currentProduct = cloneDeep(this.props.product);
            
            // this.props.addItemToCart(currentProduct);
            if(this.state.qty > 0) {

                currentProduct["addons"] = [];
                // let qty = this.state.qty - 1;

                currentProduct["qty"] = parseInt(this.state.qty);
                // currentProduct["calculated_price"] = this.props.product.price > this.props.product.discount && this.props.product.discount != 0 && this.props.product.discount !== undefined && this.props.product.discount !== null ? this.props.product.discount : this.props.product.price;
                // currentProduct["calculated_total"] = currentProduct.qty * currentProduct.calculated_price;
                currentProduct["calculated_price"] = parseFloat(this.props.product.price) > parseFloat(this.props.product.discount) && parseFloat(this.props.product.discount) != 0 && parseFloat(this.props.product.discount) !== undefined && parseFloat(this.props.product.discount) !== null ? parseFloat(this.props.product.discount) : parseFloat(this.props.product.price);
                currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
                this.props.addItemToCart(currentProduct);

            } else {
                this.props.removeItemFromCart(currentProduct);
            }

        })
        
    }


    addToCart = () => {
        let qty = this.state.qty;
        let productUpdatedPrice = this.state.productUpdatedPrice;
        // let addons = 
        let addonPossibilites = this.canAddProduct();
        let canUpdate = true;
        addonPossibilites.map(pos => {
            if(pos) {
                // canUpdate = true;
            } else {
                canUpdate = false;
            }
        })
        if(canUpdate) {
            if(this.state.qty > 0) {

            
            
                let addons = this.getAddons();
                console.log(addons, "these are selected addons");
                let newAddonsPrice = this.calculateAddonsPrice(addons);
                console.log(newAddonsPrice);
                
                let prod = cloneDeep(this.state.product.product);
                let images = cloneDeep(this.state.product.images);
                let product = {
                    ...prod,
                    addons: [
                        ...addons
                    ],
                    images: [
                        ...images
                    ]
                    
                }
        
                console.log("this is product for decreasing", product);
                let currentProduct = cloneDeep(product);
                let pageqty = this.state.qty;
                // let pageqty = document.getElementById(-qty").innerHTML;
                
            
                currentProduct["qty"] = parseInt(pageqty);
                currentProduct["calculated_price"] = productUpdatedPrice; 
                currentProduct["calculated_total"] = parseInt(currentProduct.qty) * parseFloat(currentProduct.calculated_price);
                
                
                    
                this.props.addItemToCart(currentProduct);
        
                this.setState((prevState) => {
                    let stateClone = cloneDeep(prevState);
                    let product = stateClone.product.product;
                    return {
                        ...prevState,
                        isLoading: false,
                        qty: 1,
                        addtext: true,
                        // status: "ADDED"
                        // productUpdatedPrice: parseFloat(product.price) > parseFloat(product.discount) && parseFloat(product.discount) != 0 && parseFloat(product.discount) !== undefined && parseFloat(product.discount) !== null ? parseFloat(product.discount) : parseFloat(product.price)
                    }
                }, () => {
                    this.openModal();
                });


        
            }
            
        } else {
            alert("Please select all required addons to see product price");
        }
    }

    decreaseQty = () => {
        this.setState(prevState => {
            let qty = null;
            if(prevState.qty <= 1) {
                qty = 1;
            } else {
                qty = prevState.qty - 1;
            }
            console.log(qty);
            let currentState = cloneDeep(prevState);
            return {
                ...currentState,
                qty: qty

            }

        })
    }

    increaseQty = () => {
        this.setState(prevState => {
            console.log(prevState.qty);
            let qty = null;
            if(prevState.qty < 0) {
                qty = 0;
            } else {
                qty = prevState.qty + 1;
            }
            console.log(qty);
            let currentState = cloneDeep(prevState);
            return {
                ...currentState,
                qty: qty

            }

        })
    }


    openModal = () => {
        let modal = document.getElementById("myModal");
        modal.style.display = "block";
    }

    closeModal = () => {
        let modal = document.getElementById("myModal");
        modal.style.display = "none";

        this.props.history.push("/");

    }
    
    render() {
       

        let images = null;
        let addons = null;
        if(this.state.product !== null && this.state.product !== undefined) {
            if(this.state.product.images !== null && this.state.product.images !== undefined && this.state.product.images !== []) {
                images = this.state.product.images.map((image, index) => {
                    return (
                        <div key={index}>
                            <img src={image} />
                            {/* <p className="legend">Legend 1</p> */}
                        </div>
                    )
                })
            }


            if(this.state.product.addons.length > 0) {
                
                // let addon = null;

                addons = this.state.product.addons.map((addon,index) => {
                    // let addon = null;
                    let items = null;
                    if(addon.minimum == 1 && addon.maximum == 1) {

                    
                        items = addon.items.map((item, i) => {
                            return (
                                <label className="cusRadio" itemId={item.id} key={i}>{this.props.lang === "en" ? item.title : item.title_ar} <span className="addonPrice">{item.price} KD</span>
                                    <input type="radio" name="radio" id={item.id} name={`checkboxof-${addon.id}`} onClick={(e)=> this.updateProductPrice()} item={i} addon={index}/>
                                    
                                    <span className="checkmark"></span>
                                </label>
                            )
                        });
                    } else {
                        items = addon.items.map((item, i) => {

                            // <div className="row">
                            //             <p itemId={item.id} key={i}><input type="checkbox" id={item.id} name={`checkboxof-${addon.id}`} onClick={(e) => console.log(e.currentTarget.value)}></input> {this.props.lang === "en" ? item.title : item.title_ar} (<span className="addonPrice">{item.price} KD</span>)</p>
                            //         </div>
                            return (
                                <>

                                    
                                <label className="cusRadio" itemId={item.id} key={i}>{this.props.lang === "en" ? item.title : item.title_ar} <span className="addonPrice">{item.price} KD</span>
                                    <input type="checkbox" id={item.id} name={`checkboxof-${addon.id}`} addon={index} onClick={(e)=> this.updateProductPrice()} />
                                    <span className="checkmark"></span>
                                </label>
                                </>
                            )
                        });
                    }

                    let heading = null;
                    if(addon.minimum == 1 && addon.maximum ==1) {
                        heading = <h1>{this.props.lang == "en"? this.props.translations.en["Select"] : this.props.translations.ar["Select"]} {this.props.lang === "en" ? addon.title : addon.title_ar}<span className="float-right">{this.props.lang == "en"? this.props.translations.en["1 Required"] : this.props.translations.ar["1 Required"]}</span></h1>
                    } else {
                        heading = <h1>{this.props.lang == "en"? this.props.translations.en["Select"] : this.props.translations.ar["Select"]} {this.props.lang === "en" ? addon.title : addon.title_ar}<span className="float-right">{this.props.lang == "en"? this.props.translations.en["More Than 1 Required"] : this.props.translations.ar["More Than 1 Required"]}</span></h1>

                    }
                    //   : <h1>`Select {this.props.lang === "en" ? addon.title : addon.title_ar}<span className="float-right">{addon.minimum}-{addon.maximum} Required</span></h1>`
                     

                    return (<section className="mOptions" addonId={addon.id} key={index}>
                                <div className="container mOptiontitle">
                                    <div className="row">
                                        {heading}
                                    </div>
                                </div>
                                <div className="container mOptionslist">
                                    {/* <div className="row"> */}
                                        {items}
                                        
                                    {/* </div> */}
                                </div>
                            </section>)

                })

            }
        }

        

        return this.state.isLoading !== true ? (
            <>
                <div id="myModal" class="modal">

                    <div class="modal-content">
                    {/* <span class="close" onClick={() => this.closeModal()}>&times;</span> */}
                        <div style={{marginTop:"10px"}} className="container">
                            <div className="row">
                                <div col-6>
                                    <p>PRODUCT ADDED</p>
                                </div>
                                <div col-3>
                                    <Link to={"/checkout"}><button className="primary">CHECKOUT</button></Link>
                                </div>
                                <div col-6>
                                    <button className="primary" onClick={() => {this.closeModal()}}>CONTINUE SHOPPING</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>

                <div className="container">
                    <div className="row">
                    

                        <div className="col-md-6 col-12-sm col-xs-12 p-0">
                            <Carousel>
                                {images}
                            </Carousel>
                            
                        </div>
                        <div className="col-md-6 bgltgr pMobile-0" style={{}}>
                            <section className="mProductdetails">
                                <div className="container">
                                    <div className="row">
                                        <h2>{this.props.lang === "en" ? this.state.product.product.title : this.state.product.product.title_ar}</h2>
                                        <p>{this.props.lang === "en" ? this.state.product.product.description : this.state.product.product.description_ar}</p>
                                    </div>
                                </div>
                            </section>
                            <section className="mPricestripe">
                                <div className="container">
                                    <div className="row">
                                        <p><span className="float-left">{this.props.lang == "en"? this.props.translations.en["Add to Cart"] : this.props.translations.ar["Add to Cart"]}</span><span className="float-right">{this.state.productUpdatedPrice} KD</span></p>
                                    </div>
                                </div>
                            </section>
                            {addons}
                            
                            <section className="mproductDetailqty">
                                <div className="container">
                                    <div className="row">
                                        <div className="mproqty">
                                            <p onClick={() => {this.decreaseQty()}}>-</p>
                                            <p id="page-qty">{this.state.qty}</p>
                                            <p onClick={() => {this.increaseQty()}}>+</p>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <a href="#" className="shareProduct"><img src="/assets/img/share.png" /> Share with a Friend</a>
                                        <a className="shareProduct" href={`http://api.addthis.com/oexchange/0.8/offer?url=${window.location.href}`} rel="nofollow"><img src="/assets/img/share.png" /> Share with a Friend</a>
                                    </div> */}
                                </div>
                            </section>
                            <a className="proAdd" onClick={() => {this.addToCart()}}>{this.props.lang == "en"? this.props.translations.en["Add to Cart"] : this.props.translations.ar["Add to Cart"]}</a>
                            
                        </div>
                    </div>
                </div>
            </>
        ) : <div className="container">
        <div className="row">
        

            <div className="col-md-6 col-12-sm col-xs-12 p-0">
                <Shimmer/>

                
            </div>
            <div className="col-md-6 bgltgr pMobile-0" style={{}}>
                <section className="mProductdetails">
                    <div className="container">
                        <div className="row">
                            <Shimmer />

                        </div>
                    </div>
                </section>
                <section className="mPricestripe">
                    <div className="container">
                        <div className="row">
                            <Shimmer />
                            
                        </div>
                    </div>
                </section>
                {addons}
                
                <section className="mproductDetailqty">
                    <div className="container">
                        <div className="row">
                            <div className="mproqty">
                                <p onClick={() => {this.decreaseQty()}}>-</p>
                                <p id="page-qty">{this.state.qty}</p>
                                <p onClick={() => {this.increaseQty()}}>+</p>
                            </div>
                        </div>
                        {/* <div className="row">
                            <a href="#" className="shareProduct"><img src="/assets/img/share.png" /> Share with a Friend</a>
                            <a className="shareProduct" href={`http://api.addthis.com/oexchange/0.8/offer?url=${window.location.href}`} rel="nofollow"><img src="/assets/img/share.png" /> Share with a Friend</a>
                        </div> */}
                    </div>
                </section>
                <a className="proAdd" onClick={() => {this.addToCart()}}>{this.props.lang == "en"? this.props.translations.en["Add to Cart"] : this.props.translations.ar["Add to Cart"]}</a>
                
            </div>
        </div>
    </div>
    }
}



const mapStateToProps = (state) => {
    return {
        translations: state.translations,
        lang: state.lang.lang,
        qtyIndicator: state.qtyIndicator,
        cart : state.cart,
        selectedCategory: state.selectedCategory.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setQtyIndicator : (productId) => dispatch({type: actionTypes.SET_QUANTITY_INDICATOR_ID, payload: {productId: productId}}),
        addItemToCart : (product) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product}}),
        updateCart : (product) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product}}),
        removeItemFromCart : (product) => dispatch({type: actionTypes.REMOVE_CART_ITEM, payload: {item: product}})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);

// export default ProductPage;