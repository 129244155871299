import React, { Component } from 'react';
import Cart from './Cart/Cart';
import DeliveryOption from './DeliveryOption/DeliveryOption';
// import DeliveryOptionForCountries from './DeliveryOption/DeliveryOptionForCountries';
import {connect } from 'react-redux';

import {Modal, Button} from 'react-bootstrap';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import cloneDeep from 'lodash/cloneDeep';
import Slider from "react-slick";
import SimpleSlider from './SimpleSlider';


class Banner1 extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            showProductModal: false
        }
    }

    componentDidMount() {
        console.log(this.props.lang);
        document.getElementsByClassName("slick-prev")[0].style.display = "none";
        document.getElementsByClassName("slick-next")[0].style.display = "none";

    }

    
    handleModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                showProductModal: !prevState.showProductModal
            }
        })
    }


    
    
    render() {
        // let allbanners = cloneDeep(this.props.banners.items);
        // let sortedBans = [];
        // if(this.props.lang == "en") {
        //     sortedBans = allbanners;
        // } else if(this.props.lang == "ar") {
        //     sortedBans = this.props.banners.items.reverse();

        // }
        var settings = {
            dots: true,
            arrows: false,
            autoplay: true
        };

        // let bans = sortedBans.map((banner, bi) => {
        let bans = this.props.banners.items.map((banner, bi) => {
            // if(bi == 0) {
                // return <a href={banner.image} className="btn_hero" title="Photo title" data-effect="mfp-zoom-in"><i className="icon_image"></i>View photos</a>
                return <div>
                    <img width="100%" className="img-responsive" src={banner.image} />
                    {/* <img className="img-responsive" style={{height: "70vh"}} src={"https://oklothing.com/uploads/banners/a2d1ca567430004e2ea82ca458b59053.JPG"} /> */}
                    {/* <p className="legend">Legend {bi}</p> */}
                </div>
            // } else {
            //     return <a href={banner.image} title="Photo title" data-effect="mfp-zoom-in"></a>
            // }

        })
        return (
            <>
           
               {/* <Carousel style={{height: "78vh"}}> */}
               {/* <Carousel style={this.props.lang == 'ar' ? {direction: "ltr"} : {}}> */}
                {/* <div>
                    <img src="assets/1.jpeg" />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src="assets/2.jpeg" />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                    <img src="assets/3.jpeg" />
                    <p className="legend">Legend 3</p>
                </div> */}
                {/* <div className="container">
                    <div className="row" height="400px"> */}

                    {/* <div className="row"> */}
                    <Slider settings={settings}>
                        {bans}
                    </Slider>
                    {/* </div> */}
                    {/* </div>
                </div>  */}
                
                {/* <SimpleSlider></SimpleSlider> */}
            {/* </Carousel> */}
                
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        translations: state.translations,
        settings: state.settings.data,
        lang: state.lang.lang,
        qtyIndicator: state.qtyIndicator,
        cart : state.cart,
        banners: state.banners,
        showSignInModal: state.handleSignInModal.show
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // setQtyIndicator : (productId) => dispatch({type: actionTypes.SET_QUANTITY_INDICATOR_ID, payload: {productId: productId}}),
        // addItemToCart : (product, index) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product, index: index}}),
        // updateCart : (product) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product}}),
        // removeItemFromCart : (product, index) => dispatch({type: actionTypes.REMOVE_CART_ITEM, payload: {item: product, index: index}})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner1);


// export default Banner;