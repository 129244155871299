import React, { Component } from "react";

// import PageWrapper from '../MainContainer/PageWrapper/PageWrapper';
// import ProductPage from '../ProductPage/ProductPage';

// import ShoppingCartPage from '../ShoppingCartPage/ShoppingCartPage';

import { Route, Link, Switch, Redirect } from "react-router-dom";
import { BASE_API_URL, SID } from "../../config";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
// import Header from '../CommonHeader/CommonHeader';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import IndexPage from "../IndexPage/IndexPage";
import MyAccount from "../MyAccount/MyAccount";
import OrderStatus from "../OrderStatus/OrderStatus";
// import TrackOrder from '../TrackOrder/TrackOrder';
// import Contact from '../Contact/Contact';
// import AboutUs from '../AboutUs/AboutUs';

// import CategoryPage from '../CategoryPage/CategoryPage';
// import GuestCheckOutPage from '../GuestCheckOutPage/GuestCheckOutPage';
// import {BASE_API_URL, getCookie} from '../../config';

import * as actionTypes from "../../store/actions/actions";

import { connect } from "react-redux";
import ProductPage from "../ProductPage/ProductPage";
// import OrderSuccessPage from '../OrderSuccessPage/OrderSuccessPage';
// import CheckoutPage from '../CheckoutPage/CheckoutPage';
// import OrderSuccessPage from '../OrderSuccessPage/OrderSuccessPage';
import ViewOrderDetails from "../ViewOrderDetails/ViewOrderDetails";
import OrderPage from "../OrderPage/OrderPage";
import OrderSuccessPage from "../OrderSuccessPage/OrderSuccessPage";
import { cloneDeep } from "lodash";
import ViewOrder from "../ViewOrderDetails/ViewOrder";

// import MobileMenuContainer from '../MobileMenuContainer/MobileMenuContainer';
// import MobileMenuOverlay from '../MobileMenuOveralay/MobileMenuOverlay';

// const SID = getCookie('sid');
// import { secureStorage } from '../../locStore';
// const SID = secureStorage.getItem('sid');
class RootPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
    };
  }

  componentDidMount() {
    let settingsPromise = this.getPromise(`${BASE_API_URL}`);
    let homePromise = this.getPromise(`${BASE_API_URL}/home`);
    Promise.all([settingsPromise, homePromise])
      .then((respon) => {
        console.log(respon[0], "this is settings response");
        console.log(respon[1], "this is home response");
        console.log("these are categories", respon[1].categories);
        let settingsValue = this.props.set_settings(respon[0].settings);
        let categoriesValue = this.props.set_categories(respon[1].categories);

        let translationsValue = this.props.set_translations(respon[0].words);
        let governatesValue = this.props.set_governates(respon[0].areas);
        let countriesValue = this.props.set_countries(respon[0].countries);
        let currenciesValue = this.props.set_currencies(respon[0].currencies);
        let banners = this.props.set_banners(respon[1].banners);
        let branches = this.props.set_branches(respon[1].branches);
        let shopdata = this.props.set_shopdata(respon[0].shop_data);
        let titleTag = document.getElementsByTagName("title")[0];
        titleTag.innerHTML =
          JSON.parse(respon[0].shop_data.translations).en.title +
          " | " +
          JSON.parse(respon[0].shop_data.translations).ar.title;
        let selectedCategory = cloneDeep(respon[1].categories[0]);
        selectedCategory.id = 0;
        if (respon[1].categories.length > 0) {
          selectedCategory = this.props.set_selectedCategory(selectedCategory);
        }
        if (
          settingsValue &&
          shopdata &&
          categoriesValue &&
          translationsValue &&
          governatesValue &&
          selectedCategory
        ) {
          return true;
        } else {
          return true;
        }
      })
      .then((value) => {
        console.log("this is loading set: ", value);
        if (value) {
          // document.getElementById("iniloader").remove();
          this.setState((prevState) => {
            return {
              ...prevState,
              isloading: false,
              // isloading: true,
            };
          });
        }
      })
      .then(() => {
        let booter = document.getElementById("boot");
        booter.style.display = "none";
      });
  }

  getPromise = (url) => {
    let prom = fetch(url, {
      method: "POST",
      body: JSON.stringify({
        shop_id: SID,
      }),
    }).then((res) => {
      return res.json();
    });
    return prom;
  };

  render() {
    return this.state.isloading ? null : (
      <>
        <Header />
        <Switch>
          <Route
            path="/"
            component={(props) => <IndexPage {...props}></IndexPage>}
            exact
          />
          <Route
            path="/product/:productId"
            component={(props) => (
              <ProductPage
                {...props}
                translations={this.props.translations}
                lang={this.props.lang}
              ></ProductPage>
            )}
            exact
          />
          <Route path="/about-us" component={AboutUs} exact />
          <Route path="/contact-us" component={ContactUs} exact />
          <Route path="/myaccount" component={MyAccount} exact />
          <Route path="/order-status" component={OrderStatus} exact />
          <Route path="/checkout" component={OrderPage} exact />
          {/* <Route path="/order-details/:order_id" component={ViewOrderDetails} exact/> */}
          <Route path="/order-details/:order_id" component={ViewOrder} exact />
          <Route
            path="/order-success/:order_id"
            component={OrderSuccessPage}
            exact
          />
          {/* <Route path="/order-success" component={OrderSuccessPage} exact/> */}

          <Redirect to="/" />
        </Switch>
        <div
          id="toTop"
          className="detail_page"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        ></div>
        {/* <ProductPage /> */}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    lang: state.lang.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_translations: (translations) =>
      dispatch({
        type: actionTypes.SET_TRANSLATIONS,
        payload: { translations: translations },
      }),
    set_shopdata: (shopdata) =>
      dispatch({
        type: actionTypes.UPDATE_SHOP,
        payload: { shopData: shopdata },
      }),
    set_categories: (categories) =>
      dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: { cats: categories },
      }),
    set_banners: (banners) =>
      dispatch({
        type: actionTypes.SET_BANNERS,
        payload: { banners: banners },
      }),
    set_branches: (branches) =>
      dispatch({
        type: actionTypes.SET_BRANCHES,
        payload: { branches: branches },
      }),
    set_settings: (settings) =>
      dispatch({
        type: actionTypes.SET_SETTINGS,
        payload: { settings: settings },
      }),
    set_governates: (governates) =>
      dispatch({
        type: actionTypes.SET_GOVERNATES,
        payload: { governates: governates },
      }),
    set_countries: (countries) =>
      dispatch({
        type: actionTypes.SET_COUNTRIES,
        payload: { countries: countries },
      }),
    set_currencies: (currencies) =>
      dispatch({
        type: actionTypes.SET_CURRENCIES,
        payload: { currencies: currencies },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
