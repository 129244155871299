// import logo from './logo.svg';
// import './App.css';
import React, { Component } from "react";
// import Banner from './Banner/Banner';
import Banner from "./Banner/Banner1";
import Cart from "./Banner/Cart/Cart";
import Features from "./Features/Features";
import HomeProducts from "./HomeProducts/HomeProducts";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import { Modal, Button } from "react-bootstrap";

import { Link } from "react-router-dom";
import ProductModal from "./ProductModal/ProductModal";
import PopupSkeleton from "./PopupSkeleton/PopupSkeleton";
class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductModal: true,
    };
  }

  componentDidMount() {
    // this.props.history.push(`/order-success/21`);
  }

  handleModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showProductModal: !prevState.showProductModal,
      };
    });
  };

  render() {
    let mobileTitle = this.props.settings.title.split(" | ")[0];
    let mobileDesc = this.props.settings.title.split(" | ")[1];

    let mobileTitleAr = this.props.settings.title_ar.split(" | ")[0];
    let mobileDescAr = this.props.settings.title_ar.split(" | ")[1];

    return (
      <>
        <main>
          <Banner></Banner>

          <Features></Features>

          <HomeProducts></HomeProducts>
        </main>

        {/* {this.props.selectedProduct.showProductPopUp ? (
          <ProductModal></ProductModal>
        ) : null} */}

        {this.props.selectedProduct.showProductPopUp ? (
          <ProductModal></ProductModal>
        ) : null}
        <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
          <div className="modal_header">
            <h3>Sign In</h3>
          </div>
          <form>
            <div className="sign-in-wrapper">
              <a href="#0" className="social_bt facebook">
                Login with Facebook
              </a>
              <a href="#0" className="social_bt google">
                Login with Google
              </a>
              <div className="divider">
                <span>Or</span>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                />
                <i className="icon_mail_alt"></i>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  value=""
                />
                <i className="icon_lock_alt"></i>
              </div>
              <div className="clearfix add_bottom_15">
                <div className="checkboxes float-left">
                  <label className="container_check">
                    Remember me
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="float-right">
                  <a id="forgot" href="javascript:void(0);">
                    Forgot Password?
                  </a>
                </div>
              </div>
              <div className="text-center">
                <input
                  type="submit"
                  value="Log In"
                  className="btn_1 full-width mb_5"
                />
                Don’t have an account? <a href="account.html">Sign up</a>
              </div>
              <div id="forgot_pw">
                <div className="form-group">
                  <label>Please confirm login email below</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email_forgot"
                    id="email_forgot"
                  />
                  <i className="icon_mail_alt"></i>
                </div>
                <p>
                  You will receive an email containing a link allowing you to
                  reset your password to a new preferred one.
                </p>
                <div className="text-center">
                  <input
                    type="submit"
                    value="Reset Password"
                    className="btn_1"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    showSignInModal: state.handleSignInModal.show,
    selectedProduct: state.selectedProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    // setCurrency: (currency) => dispatch({type: actionTypes.SET_CURRENCY, payload: { base_currency: currency}}),
    // setMultiplier: (multiplier) => dispatch({type: actionTypes.SET_MULTIPLIER, payload: { multiplier: multiplier}})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);

// export default Header;
// export default IndexPage;
