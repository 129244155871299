import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { BASE_API_URL, SID } from '../../config';

import {connect} from 'react-redux';
class OrderSuccessPage extends Component {
    

    constructor(props) {
        super(props);
        console.log(this.props);
        console.log(this.props.match.params.order_id);
        this.state = {
            orderData: {
                invoice: null,
                products: []
            },
            loading: true
        }
    }

    componentDidMount() {
        let order_id = this.props.match.params.order_id;
        fetch(`${BASE_API_URL}/getorderdata/${order_id}`, {
            method: 'POST',
            body: JSON.stringify({
                shop_id: SID
            })
        })
        .then(resp => {
            return resp.json();
        })
        .then(resJson => {
            console.log(resJson);
            this.setState(prevState => {
                return {
                    ...prevState,
                    orderData: resJson,
                    loading: false
                }
            });
            
            
           
        })
        .catch(err => {
            console.log(err);
        })
    }

    
    render() {
        let delivery_details = null;
        let delivery_address = null;
        let delivery_address_str = null;
        let products = [];
        if(!this.state.loading) {
            delivery_details = JSON.parse(this.state.orderData.invoice.delivery_details);
            delivery_address = JSON.parse(this.state.orderData.invoice.delivery_address);
            if(delivery_details.order_type == "international" || delivery_details.order_type == "International") {
                delivery_address_str = <p> Name : {delivery_address.delivery.first_name} {delivery_address.delivery.last_name} <br />
                    {delivery_address.delivery.country_based.address_line1} <br />
                    {delivery_address.delivery.country_based.address_line2} <br />
                    City : {delivery_address.delivery.country_based.city} <br />
                    State : {delivery_address.delivery.country_based.State} <br />
                    Country : {delivery_address.delivery.country_based.country.label} <br />
                    Email : {delivery_address.delivery.email} <br />
                    Phone : {delivery_address.delivery.phone} <br />
                    Zipcode : {delivery_address.delivery.country_based.zipcode} <br />
                    Additional Information : {delivery_address.delivery.additional_information} <br />
                </p>
            } else {
                delivery_address_str = <p> Name : {delivery_address.delivery.first_name} {delivery_address.delivery.last_name} <br />
                    {delivery_address.delivery.governate_based.block}, {delivery_address.delivery.country_based.State}, {delivery_address.delivery.country_based.State} <br />
                    housetype: {delivery_address.delivery.governate_based.housetype} <br />
                    {delivery_address.delivery.governate_based.house_number} {delivery_address.delivery.governate_based.office_name} {delivery_address.delivery.governate_based.office_number} {delivery_address.delivery.governate_based.housetype}
                    Country : {delivery_address.delivery.governate_based.country.label} <br />
                    Email : {delivery_address.delivery.email} <br />
                    Phone : {delivery_address.delivery.phone} <br />
                    Zipcode : {delivery_address.delivery.country_based.zipcode} <br />
                    Additional Information : {delivery_address.delivery.additional_information} <br />
                </p>
            }
            // let delivery_address = this.state.orderData.invoice.delivery_address;


            products = JSON.parse(this.state.orderData.invoice.products).map((product, pi) => {
                let addons_length = product.addons.length;
                let addons = null;
                addons = product.addons.map((addon, ai) => {
                    if(pi == addons_length - 1) {
                        return this.props.lang == 'en' ? addon.translations.en.title : addon.translations.ar.title;
                    } else {
                        return this.props.lang == 'en' ? `${addon.translations.en.title}, ` : `${addon.translations.ar.title}, `;
                    }   
                });


                return (
                    <tr key={pi}>
                        <td className="text-left">{`${product.qty}`} X {this.props.lang == 'en' ? `${product.translations.en.title} (${addons})` : `${product.translations.ar.title} (${addons})`}</td>
                        <td className="text-right">{`${product.calculated_total} KD`}</td>
                    </tr>
                );
            })
        }
        return this.state.loading == false ? <div className="container">
            <div className="row">
                <div className="col-3">

                </div>
                <div className="col-6">

                
                    <div className="successTop">
                        <img src="/assets/img/success.png" width="50" />
                        <h3>Order Complete!</h3>
                        <p>Thank you for placing your order!<br />Your order is now complete and we already started processing it.</p>
                    </div>


                    <div className="checkoutHeader">
                                <h3>Order Details</h3>
                            </div>
                            <div className="checkoutTotals">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="text-left">Order Time</td>
                                            <td id="order-time" className="text-right">{this.state.orderData.invoice.now}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">{this.props.lang == "en" ? this.props.translations.en["Order ID"] : this.props.translations.ar["Order ID"]}Order ID</td>
                                            <td id="order-id" className="text-right">{this.state.orderData.invoice.id}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">{this.props.lang == "en" ? this.props.translations.en["Paid"] : this.props.translations.ar["Paid"]}Paid</td>
                                            <td id="paid" className="text-right">{this.state.orderData.invoice.payment_status_code} ({delivery_details.payment_type})</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">{this.props.lang == "en" ? this.props.translations.en["Delivery"] : this.props.translations.ar["Delivery"]}Delivery</td>
                                            <td id="delivery" className="text-right">{this.state.orderData.invoice.delivery_status_code}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className="checkoutHeader">
                                <h3>{this.props.lang == "en" ? this.props.translations.en["Order Summary"] : this.props.translations.ar["Order Summary"]}Order Summary</h3>
                            </div>
                            <div className="checkoutTotals">
                                <table className="table">
                                    <tbody id="table-body">
                                        {/* <tr>
                                            <td className="text-left">1x Baklava Cheesecake Peacan 8 inch (Size good for 10 to 12 people)</td>
                                            <td className="text-right">30.000 KD</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">1x Baklava Cheesecake Peacan 8 inch (Size good for 10 to 12 people)</td>
                                            <td className="text-right">30.000 KD</td>
                                        </tr> */}
                                        {products}
                                        
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className="checkoutHeader">
                                <h3>Delivery Address</h3>
                            </div>
                            <div className="checkoutTotals" style={{display: "inline-flex"}}>
                                <div  id="address" className="col-12">
                                    {/* <p>Husain Najmuddin<br />Kuwait, Salmiya, Block 10 Street 10, Building 10 Flat 10, Floor 10 <br /> */}
            {/* +965 65463399</p> */}
                                    {delivery_address_str}
                                </div>
                                {/* <div className="col-6">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d546030.3804509705!2d47.59073969797439!3d29.134328286943774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fc5363fbeea51a1%3A0x74726bcd92d8edd2!2sKuwait!5e0!3m2!1sen!2sin!4v1606719262608!5m2!1sen!2sin" width="100%" height="150" frameBorder="0" style={{border:"0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                                </div> */}
                            </div>
                            
                            <div className="checkoutTotals successTotals">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="text-left">{this.props.lang == "en" ? this.props.translations.en["Subtotal"] : this.props.translations.ar["Subtotal"]}Sub - Total</td>
                                            <td id="sub-total" className="text-right">{this.state.orderData.invoice.cart_value} KWD</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">{this.props.lang == "en" ? this.props.translations.en["Delivery Fee"] : this.props.translations.ar["Delivery Fee"]}Delivery Fee</td>
                                            <td id="delivery-fee" className="text-right">{this.state.orderData.invoice.delivery_charges} KWD</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left" style={{color: "red"}}>{this.props.lang == "en" ? this.props.translations.en["Discount"] : this.props.translations.ar["Discount"]}Discount</td>
                                            <td id="discount" className="text-right" style={{color: "red"}}>-{this.state.orderData.invoice.discount} KWD</td>
                                        </tr>
                                        <tr>
                                            <td className="text-left"><strong>{this.props.lang == "en" ? this.props.translations.en["Total"] : this.props.translations.ar["Total"]}</strong></td>
                                            <td id="total" className="text-right"><strong>{this.state.orderData.invoice.order_total} KWD</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to="/">Continue Shopping</Link>
                            </div>
                        


                </div>
                <div className="col-3">

                </div>
            </div>
        </div>
        : null
    }
}


const mapStateToProps = (state) => {
    return {
        translations: state.translations,
        lang: state.lang.lang,
        qtyIndicator: state.qtyIndicator,
        cart : state.cart,
        selectedCategory: state.selectedCategory.data,
        governates: state.governates.data,
        delivery_location: state.deliveryLocation.data.area

    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        // setQtyIndicator : (productId) => dispatch({type: actionTypes.SET_QUANTITY_INDICATOR_ID, payload: {productId: productId}}),
        // addItemToCart : (product) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product}}),
        // updateCart : (product) => dispatch({type: actionTypes.ADD_CART_ITEM, payload: {item: product}}),
        // removeItemFromCart : (product) => dispatch({type: actionTypes.REMOVE_CART_ITEM, payload: {item: product}})
        // resetCart : () => dispatch({type: actionTypes.RESET_CART, payload: null}),
        // set_deliveryLocation: (location) => dispatch({type: actionTypes.SET_DELIVERY_LOCATION, payload: {location: location}})

    }
}




export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccessPage);