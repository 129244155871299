import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as actionTypes from "../../store/actions/actions";
import { connect } from "react-redux";

import SideNav from "react-simple-sidenav";

import { Modal, Button } from "react-bootstrap";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      showSearch: false,
    };
  }

  setRTL = (lang) => {
    let mainBody = document.getElementById("root");
    if (lang == "ar") {
      this.props.setLang("ar");
    } else if (lang == "en") {
      this.props.setLang("en");
      mainBody.classList.remove("rtl");
      mainBody.classList.add("ltr");
    }
  };

  handleSideBar = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showNav: !prevState.showNav,
      };
    });
  };

  handleSearchBar = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showSearch: !prevState.showSearch,
      };
    });
  };

  setShowNav = (val) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showNav: val,
      };
    });
  };

  handleSearch = (keyword) => {
    if (keyword != "") {
      // this.setState((prevState) => {
      //     return {
      //         ...prevState,
      //         search: keyWord,
      //         products: []
      //     }
      this.props.set_search(keyword);
    } else {
      this.props.set_search(null);
    }
  };

  handleCurrency = (e) => {
    console.log("calling handle currecy");
    console.log(e.target.value);

    let current_currency = null;
    this.props.currencies.map((cur, ind) => {
      if (cur.currency == e.target.value) {
        current_currency = cur;
      }
    });

    if (current_currency !== null && current_currency !== undefined) {
      this.props.setCurrency(current_currency.currency);
      this.props.setMultiplier(current_currency.price);
    }

    // this.props.setCurrency(cur.currency);
    // this.props.setMultiplier(cur.price);
  };

  render() {
    let mobile = false;
    if (window.matchMedia("screen and (max-width: 990px)").matches) {
      mobile = true;
    }

    // let titleNode = <div><Link to="/" onClick={() => {this.setShowNav(false); this.props.displaySearch(true)}}><img className="img-responsive" src={this.props.settings.image} /></Link></div>
    // let titleNode = <img className="img-responsive" src={this.props.settings.image} />

    let currencies_list = this.props.currencies.map((cur, ind) => {
      if (cur.currency == this.props.currency.base_currency) {
        return (
          <option key={ind} value={cur.currency} selected>
            {this.props.lang == "en" ? cur.title : cur.title_ar}
          </option>
        );
      } else {
        return (
          <option key={ind} value={cur.currency}>
            {this.props.lang == "en" ? cur.title : cur.title_ar}
          </option>
        );
      }
    });

    let currecies =
      // <select onChange={(e) => {this.handleCurrency(e)}}>
      {
        /* <option value="US Dollars" selected>US Dollars</option>
                <option value="Euro">Euro</option> */
      };
    // {currencies_list}
    // </select>

    let menuItems = [
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/`}
      >
        {this.props.lang == "en"
          ? this.props.translations.en["Home"]
          : this.props.translations.ar["Home"]}
      </Link>,
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/order-status`}
      >
        {this.props.lang == "en"
          ? this.props.translations.en["Order Status"]
          : this.props.translations.ar["Order Status"]}
      </Link>,
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/about-us`}
      >
        {this.props.lang == "en"
          ? this.props.translations.en["About Us"]
          : this.props.translations.ar["About Us"]}
      </Link>,
      <Link
        onClick={() => {
          this.setShowNav(false);
        }}
        style={{ padding: "10px 20px", color: "#000000" }}
        to={`/contact-us`}
      >
        {this.props.lang == "en"
          ? this.props.translations.en["Contact Us"]
          : this.props.translations.ar["Contact Us"]}
      </Link>,
      // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/`}>ar</Link>
    ];

    if (this.props.lang == "en") {
      // menuItems.push(<Link onClick={() =>{this.setShowNav(false); this.setRTL("ar")}} style={{ padding: "10px 20px", color: "#000000"}}>ar</Link>);
      menuItems.push(
        <Link
          onClick={() => {
            this.setShowNav(false);
            this.setRTL("ar");
          }}
          style={{ padding: "10px 20px", color: "#000000" }}
        >
          <img src="/img/bar.png" style={{ width: "25px" }} />
        </Link>
      );
    } else {
      // menuItems.push(<Link onClick={() =>{this.setShowNav(false); this.setRTL("en")}} style={{ padding: "10px 20px", color: "#000000"}}>en</Link>);
      menuItems.push(
        <Link
          onClick={() => {
            this.setShowNav(false);
            this.setRTL("en");
          }}
          //   style={{ padding: "10px 20px", color: "#000000" }}
        >
          <img src="/img/ben.png" style={{ width: "25px" }} />
        </Link>
      );
    }
    menuItems.push(
      <Link>
        <div className="styled-select currency-selector">
          <select onChange={(e) => this.handleCurrency(e)}>
            {/* <option value="US Dollars" selected>US Dollars</option>
                    <option value="Euro">Euro</option> */}
            {currencies_list}
          </select>
        </div>
      </Link>
    );

    let sideBar = (
      <SideNav
        className="hello"
        showNav={this.state.showNav}
        onHideNav={() => this.setShowNav(false)}
        // title={titleNode}
        // items={['Order Status', 'About Us', 'Contact Us']}
        // items={[
        // <Link onClick={() => {this.setShowNav(false); this.props.displaySearch(false)}} style={{ padding: "10px 20px", color: "#000000"}} to={`/order-status`}>{this.props.lang == "en" ? this.props.translations.en["ORDER STATUS"] : this.props.translations.ar["ORDER STATUS"]}</Link>,
        // <Link onClick={() => {this.setShowNav(false); this.props.displaySearch(false)}} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>{this.props.lang == "en" && this.props.translations.en["About Us"] ? this.props.translations.en["About Us"] : this.props.lang == "en" && this.props.translations.ar["About Us"] ? this.props.translations.ar["About Us"] : "About Us"}</Link>,
        // <Link onClick={() => {this.setShowNav(false); this.props.displaySearch(false)}} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>{this.props.lang == "en" ? this.props.translations.en["Contact US"] : this.props.translations.ar["Contact US"]}</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/`}>Home</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/order-status`}>order status</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>about us</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>contact us</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/`}>ar</Link>,

        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>about us</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>contact us</Link>,
        // <Link onClick={() => {this.setShowNav(false);}} style={{ padding: "10px 20px", color: "#000000"}} to={`/order-status`}>order status</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/about-us`}>about us</Link>,
        // <Link onClick={() => {this.setShowNav(false); }} style={{ padding: "10px 20px", color: "#000000"}} to={`/contact-us`}>contact us</Link>

        // ]}
        items={menuItems}
        titleStyle={{ backgroundColor: "white" }}
        itemStyle={{
          backgroundColor: "#fff",
          color: "black",
          padding: "0px",
          marginTop: "10px",
          listStyle: "none",
        }}
        itemHoverStyle={{ backgroundColor: "#eee" }}
        openFromRight={this.props.lang == "ar" ? true : false}
        style={{ overflow: "hidden", overflowY: "scroll" }}
        // navStyle={{backgroundColor: "green",display: "inline"}}
      />
    );

    return (
      <>
        {mobile ? sideBar : null}

        <header className="header_in clearfix">
          {/* {sideBar} */}

          <div className="container">
            <div id="logo">
              <Link to={"/"}>
                <img src={this.props.shop.image} height="65" alt="" />
              </Link>
            </div>
            <div className="layer"></div>
            <ul id="top_menu">
              {/* <li><a id="sign-in" onClick={() => {this.props.handleSignInModal()}} className="login">Sign In</a></li> */}
              {/* <li><a href="wishlist.html" className="wishlist_bt_top" title="Your wishlist">Your wishlist</a></li> */}
            </ul>

            <a className="open_close">
              <i
                className="icon_menu"
                onClick={() => this.setShowNav(!this.state.showNav)}
              ></i>
              <span>Menu</span>
            </a>

            <nav className="main-menu">
              <div id="header_menu">
                <a href="#0" className="open_close">
                  <i className="icon_close"></i>
                  <span>Menu</span>
                </a>
                <a href="index.html">
                  <img src="img/logo.svg" height="65" alt="" />
                </a>
              </div>
              <ul>
                {/* <li>
                                    <a href="#0">Home</a>
                                    <ul>
                                        <li><a href="index.html">Address Autocomplete</a></li>
                                        <li><a href="index-2.html">Search by Keyword</a></li>
                                        <li><a href="index-3.html">Home Version 2</a></li>
                                        <li><a href="index-4.html">Video Background</a></li>
                                        <li><a href="index-6.html">Home with Slider</a></li>
                                        <li><a href="index-5.html">GDPR Cookie Bar EU Law</a></li>
                                        <li><a href="header-user-logged.html">Header User Logged</a></li>
                                        <li><a href="header-cart-top.html">Header Cart Top</a></li>
                                        <li><a href="modal-advertise.html">Modal 1 Cookie Session</a></li>
                                        <li><a href="modal-newsletter.html">Modal 2 Cookie Session</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#0">Listing</a>
                                    <ul>
                                        <li className="third-level"><a href="#0">List pages</a>
                                            <ul>
                                                <li><a href="grid-listing-filterscol.html">List default</a></li>
                                                <li><a href="grid-listing-filterscol-map.html">List with map</a></li>
                                                <li><a href="listing-map.html">List side map</a></li>
                                                <li><a href="grid-listing-masonry.html">List Masonry Filter</a></li>
                                            </ul>
                                        </li>
                                        <li className="third-level"><a href="#0">Detail pages</a>
                                            <ul>
                                                <li><a href="detail-restaurant.html">Detail page 1</a></li>
                                                <li><a href="detail-restaurant-2.html">Detail page 2</a></li>
                                                <li><a href="detail-restaurant-3.html">Detail page 3</a></li>
                                                <li><a href="detail-restaurant-4.html">Detail page 4</a></li>
                                            </ul>
                                        </li>
                                        <li className="third-level"><a href="#0">OpenStreetMap</a>
                                            <ul>
                                                <li><a href="grid-listing-filterscol-openstreetmap.html">List with map</a></li>
                                                <li><a href="listing-map-openstreetmap.html">List side map</a></li>
                                                <li><a href="grid-listing-masonry-openstreetmap.html">List Masonry Filter</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="submit-restaurant.html">Submit Restaurant</a></li>
                                        <li><a href="submit-rider.html">Submit Rider</a></li>
                                        <li><a href="order.html">Order</a></li>
                                        <li><a href="confirm.html">Confirm Order</a></li>
                                    </ul>
                                 
                                </li>
                                <li className="submenu">
                                    <a href="#0" className="show-submenu">Other Pages</a>
                                     <ul>
                                        <li><a href="admin_section/index.html" target="_blank">Admin Section</a></li>
                                        <li><a href="404.html">404 Error</a></li>
                                        <li><a href="help.html">Help and Faq</a></li>
                                        <li><a href="blog.html">Blog</a></li>
                                        <li><a href="leave-review.html">Leave a review</a></li>
                                        <li><a href="contacts.html">Contacts</a></li>
                                        <li><a href="coming_soon/index.html">Coming Soon</a></li>
                                        <li><a href="login.html">Sign In</a></li>
                                        <li><a href="register.html">Sign Up</a></li>
                                        <li><a href="icon-pack-1.html">Icon Pack 1</a></li>
                                        <li><a href="icon-pack-2.html">Icon Pack 2</a></li>
                                        <li><a href="shortcodes.html">Shortcodes</a></li>
                                    </ul>
                                </li> */}
                <li>
                  <Link to={"/"}>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Home"]
                      : this.props.translations.ar["Home"]}
                  </Link>
                </li>
                <li>
                  <Link to={`/order-status`}>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Order Status"]
                      : this.props.translations.ar["Order Status"]}
                  </Link>
                </li>
                <li>
                  <Link to={`/about-us`}>
                    {this.props.lang == "en"
                      ? this.props.translations.en["About Us"]
                      : this.props.translations.ar["About Us"]}
                  </Link>
                </li>
                <li>
                  <Link to={`/contact-us`}>
                    {this.props.lang == "en"
                      ? this.props.translations.en["Contact Us"]
                      : this.props.translations.ar["Contact Us"]}
                  </Link>
                </li>
                {this.props.lang == "en" ? (
                  <li>
                    <a onClick={() => this.setRTL("ar")}>
                      <img
                        style={{ cursor: "pointer" }}
                        className="img-responsive"
                        src="/img/bar.png"
                        height="32"
                      />
                    </a>
                  </li>
                ) : null}
                {this.props.lang == "ar" ? (
                  <li>
                    <a onClick={() => this.setRTL("en")}>
                      <img
                        style={{ cursor: "pointer" }}
                        className="img-responsive"
                        src="/img/ben.png"
                        height="32"
                      />
                    </a>
                  </li>
                ) : null}
                {/* <li><a href="#0">Buy this template</a></li> */}
                <li>
                  <div className="styled-select currency-selector">
                    <select
                      onChange={(e) => {
                        this.handleCurrency(e);
                      }}
                    >
                      {/* <option value="US Dollars" selected>US Dollars</option>
                                        <option value="Euro">Euro</option> */}
                      {currencies_list}
                    </select>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        {/* {mobile ? sideBar: null}           */}
        {/* {sideBar} */}

        <Modal
          show={this.props.showSignInModal}
          onHide={this.props.handleSignInModal}
          // backdrop="static"
          keyboard={false}
          centered
          style={{ zIndex: "999999999" }}
        >
          {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header> */}
          <Modal.Body>
            <form>
              <div className="sign-in-wrapper">
                {/* <a href="#0" className="social_bt facebook">Login with Facebook</a>
              <a href="#0" className="social_bt google">Login with Google</a> */}
                {/* <div className="divider"><span>Or</span></div> */}
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                  />
                  <i className="icon_mail_alt"></i>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    value=""
                  />
                  <i className="icon_lock_alt"></i>
                </div>
                <div className="clearfix add_bottom_15">
                  <div className="checkboxes float-left">
                    <label className="container_check">
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="float-right">
                    <a id="forgot" href="javascript:void(0);">
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <input
                    type="submit"
                    value="Log In"
                    className="btn_1 full-width mb_5"
                  />
                  Don’t have an account? <a href="account.html">Sign up</a>
                </div>
                <div id="forgot_pw">
                  <div className="form-group">
                    <label>Please confirm login email below</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email_forgot"
                      id="email_forgot"
                    />
                    <i className="icon_mail_alt"></i>
                  </div>
                  <p>
                    You will receive an email containing a link allowing you to
                    reset your password to a new preferred one.
                  </p>
                  <div className="text-center">
                    <input
                      type="submit"
                      value="Reset Password"
                      className="btn_1"
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    translations: state.translations,
    lang: state.lang.lang,
    settings: state.settings.data,
    showSignInModal: state.handleSignInModal.show,
    shop: state.shop,
    currencies: state.currencies.data,
    currency: state.currency,
    // cart: state.cart,
    multiplier: state.multiplier,
    translations: state.translations,
    search: state.searchInput.keyword,
    // categories: state.categories.items
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateShop: () => dispatch(updateShopDetails()),
    // getCategories: () => dispatch(getCategories()),
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    set_search: (keyword) => {
      dispatch({
        type: actionTypes.SET_SEARCH_KEYWORD,
        payload: { keyword: keyword },
      });
      return Promise.resolve();
    },
    handleSignInModal: (show) =>
      dispatch({
        type: actionTypes.HANDLE_SIGNIN_MODAL,
        payload: { show: show },
      }),
    setCurrency: (currency) =>
      dispatch({
        type: actionTypes.SET_CURRENCY,
        payload: { base_currency: currency },
      }),
    setMultiplier: (multiplier) =>
      dispatch({
        type: actionTypes.SET_MULTIPLIER,
        payload: { multiplier: multiplier },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

// export default Header;
