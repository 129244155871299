import React, { Component } from "react";
import { Link } from "react-router-dom";
import CategoryProduct from "../CategoryProduct/CategoryProduct";
import { BASE_API_URL } from "../../../../config";

import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { getSelectedProduct } from "../../../../store/actions/popProduct";

import * as actionTypes from "../../../../store/actions/actions";
import { clone, cloneDeep } from "lodash";
import Image, { Shimmer } from "react-shimmer";

// import { filter } from 'lodash';
class CategoryProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductModal: false,
      // activeCategory: null,
      selectedProduct: null,
    };
  }

  handleModal = (prod_id) => {
    this.props.setShowProductPopUp(true);

    // fetch(`${BASE_API_URL}/product/${prod.id}`, {
    //     method: "GET",
    //     headers: {

    //     }
    // })
    // .then(res => {
    //     return res.json();
    // })
    // .then(resJson => {
    //     console.log(resJson);
    this.props.setSelectedProduct(prod_id);
    // })
    // .catch(err => {
    //     console.log(err);
    // })
  };

  updateSelectedCategory = (catId) => {
    console.log("hit the update function ", catId);
    if (catId != 0) {
      let filtered = this.props.categories.filter((category, index) => {
        return category.id == catId;
      });
      console.log(filtered);
      if (filtered.length > 0) {
        console.log("found the cat");
        this.props.set_selectedCategory(filtered[0]);

        // this.props.set_selectedCategory(filtered[0]);
      }
    } else {
      let dummy_cat = cloneDeep(this.props.categories[0]);

      dummy_cat.id = 0;
      this.props.set_selectedCategory(dummy_cat);
    }
  };

  render() {
    let categoryProducts = this.props.categories.map((category, index) => {
      let showAll = false;
      console.log(category.id);
      if (category.id == this.props.selectedCategory.data.id) {
        showAll = true;
      } else {
        showAll = false;
      }

      console.log("showAll Id - ", this.props.selectedCategory.data.id);
      let prods = [];

      if (showAll) {
        console.log("executing showAll");

        prods = category.products.map((prod, i) => {
          return (
            <div
              className="col-md-6 animate__animated animate__fadeInUp"
              key={prod.id}
            >
              <a
                className="menu_item modal_dialog"
                onClick={() => {
                  this.handleModal(prod.id);
                }}
              >
                {/* <figure><img src={prod.images.length > 0 ? prod.images[0].image: 'https://via.placeholder.com/150'} data-src={prod.image !== "" ? prod.image: 'https://via.placeholder.com/150'} alt="thumb" className="lazy" /></figure> */}
                <figure>
                  <Image
                    src={
                      prod.images.length > 0
                        ? prod.images[0].image
                        : "https://via.placeholder.com/150"
                    }
                    data-src={
                      prod.image !== ""
                        ? prod.image
                        : "https://via.placeholder.com/150"
                    }
                    fallback={<Shimmer width={50} height={50} />}
                  />
                </figure>
                <h3 className="formatter">
                  {this.props.lang == "en"
                    ? prod.title.substring(0, 50)
                    : prod.title_ar.substring(0, 50)}
                </h3>
                <p className="formatter">
                  {this.props.lang == "en"
                    ? prod.description.substring(0, 99)
                    : prod.description_ar.substring(0, 99)}
                </p>
                <strong>
                  {parseFloat(prod.discount) > 0
                    ? parseFloat(prod.discount) < parseFloat(prod.price)
                      ? `${this.props.currency.base_currency} ${(
                          prod.discount * this.props.multiplier.multiplier
                        ).toFixed(3)}`
                      : `${this.props.currency.base_currency} ${(
                          prod.price * this.props.multiplier.multiplier
                        ).toFixed(2)}`
                    : `${this.props.currency.base_currency} ${(
                        prod.price * this.props.multiplier.multiplier
                      ).toFixed(3)}`}
                </strong>
              </a>
            </div>
          );
        });
      } else {
        console.log("executing else");
        prods = category.products.map((prod, i) => {
          console.log("category -", index, "prod -", i);
          if (i < 4) {
            return (
              <div
                className="col-md-6 animate__animated animate__fadeInUp"
                key={prod.id}
              >
                <a
                  className="menu_item modal_dialog"
                  onClick={() => {
                    this.handleModal(prod.id);
                  }}
                >
                  <figure>
                    {/* <img
                      src={
                        prod.images.length > 0
                          ? prod.images[0].image
                          : "https://via.placeholder.com/150"
                      }
                      data-src={
                        prod.image !== ""
                          ? prod.image
                          : "https://via.placeholder.com/150"
                      }
                      alt="thumb"
                      className="lazy"
                    /> */}
                    <Image
                      src={
                        prod.images.length > 0
                          ? prod.images[0].image
                          : "https://via.placeholder.com/150"
                      }
                      data-src={
                        prod.image !== ""
                          ? prod.image
                          : "https://via.placeholder.com/150"
                      }
                      alt="thumb"
                      className="lazy"
                      fallback={<Shimmer width={50} height={50} />}
                    />
                  </figure>
                  <h3 className="formatter">
                    {this.props.lang == "en"
                      ? prod.title.substring(0, 50)
                      : prod.title_ar.substring(0, 50)}
                  </h3>
                  <p className="formatter">
                    {this.props.lang == "en"
                      ? prod.description.substring(0, 99)
                      : prod.description_ar.substring(0, 99)}
                  </p>
                  <strong>
                    {parseFloat(prod.discount) > 0
                      ? parseFloat(prod.discount) < parseFloat(prod.price)
                        ? `${this.props.currency.base_currency} ${(
                            prod.discount * this.props.multiplier.multiplier
                          ).toFixed(3)}`
                        : `${this.props.currency.base_currency} ${(
                            prod.price * this.props.multiplier.multiplier
                          ).toFixed(3)}`
                      : `${this.props.currency.base_currency} ${(
                          prod.price * this.props.multiplier.multiplier
                        ).toFixed(3)}`}
                  </strong>
                </a>
              </div>
            );
          }
        });
      }
      return (
        <section id={`section-${index}`} key={category.id}>
          <h4>
            {this.props.lang == "en" ? category.title : category.title_ar}
          </h4>
          <div className="row prodAnim">{prods}</div>

          {category.products.length > 4 ? (
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                {category.id != this.props.selectedCategory.data.id ? (
                  <button
                    className="btn_1 gradient full-width mb_5"
                    onClick={() => this.updateSelectedCategory(category.id)}
                  >
                    {this.props.lang == "en"
                      ? this.props.translations.en["View More"]
                      : this.props.translations.ar["View More"]}
                  </button>
                ) : null}
                {category.id == this.props.selectedCategory.data.id ? (
                  <button
                    className="btn_1 gradient full-width mb_5"
                    onClick={() => this.updateSelectedCategory(0)}
                  >
                    {this.props.lang == "en"
                      ? this.props.translations.en["View Less"]
                      : this.props.translations.ar["View Less"]}
                  </button>
                ) : null}
              </div>
              <div className="col-4"></div>
            </div>
          ) : null}
        </section>
      );
    });

    return <>{categoryProducts}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    // shop: state.shop,
    lang: state.lang.lang,
    settings: state.settings.data,
    cart: state.cart,
    lang: state.lang.lang,
    translations: state.translations,
    categories: state.categories.items,
    selectedProduct: state.selectedProduct,
    selectedCategory: state.selectedCategory,
    currency: state.currency,
    multiplier: state.multiplier,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (lang) =>
      dispatch({ type: actionTypes.SET_LANGUAGE, payload: { lang: lang } }),
    setSelectedProduct: (prod_id) => dispatch(getSelectedProduct(prod_id)),
    setShowProductPopUp: (show_product_popup) =>
      dispatch({
        type: actionTypes.HANDLE_PRODUCT_POPUP,
        payload: { show_product_popup: show_product_popup },
      }),
    set_selectedCategory: (category) =>
      dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        payload: { category: category },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProducts);

// export default CategoryProducts;
