import React, { Component } from 'react';

class MyAccount extends Component {
    

    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <>
            <p>This is MyAccount</p>
            </>
        );
    }
}


export default MyAccount;