// export const BASE_API_URL = 'http://localhost:4000/customers';
// export const BASE_API_URL = 'https://projects.yellowsoft.in/testrk/app';
export const BASE_API_URL = 'https://goonline.webykw.com/root';
// export const BASE_API_URL = 'https://weby-ram.herokuapp.com/customers';  ///eg6992cw9456
// export const SID = '5f0c64672ff6a25988b6e4de';
// export const SID = '5f0c64-672ff6-a25988-b6e4de';
// export const SID = '1';
export const SID = '13';
// export const SID = '16';
// export const SID = '13';


export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
