import * as actionTypes from '../actions/actions';



const initialState = {
    data: []
}

const governatesReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_GOVERNATES:
            let governates = action.payload.governates;
            return {
                data: governates
            }

    }
    return state;
}

export default governatesReducer;