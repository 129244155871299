import logo from "./logo.svg";
import "./App.css";
import React, { Component } from "react";
import RootPage from "./containers/RootPage/RootPage";
import { BrowserRouter, Route, Link } from "react-router-dom";
import "animate.css";
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <RootPage></RootPage>
      </BrowserRouter>
    );
  }
}

export default App;
